import React from 'react';
import FadeUp from '../Animations/FadeUp';
import { Link } from 'react-router-dom';
import BlockHeader from '../UI/texts/BlockHeader';
import './ourServices.scss';
import { BlueUppercase } from '../UI/texts/BlueUppercase';
import ResponsiveBox from '../UI/ResponsiveBox';

const OurServices = ({ data, cdnUrl }) => {
  return (
    <section className="our-services-block">
      <ResponsiveBox>
        <BlueUppercase>{data.blue_title}</BlueUppercase>
        <h2 className="our-services-subtitle">{data.subtitle}</h2>
        <div className="our-services-wrapper">
          {data.items.map((content, index) => (
            <div className="our-service-item" key={index}>
              <FadeUp>
                <div className="item-img">
                  <img src={`${cdnUrl}${content.img}`} alt="" />
                </div>
                <Link to={content.link}>
                  <BlockHeader
                    variant="h3"
                    dangerouslySetInnerHTML={{ __html: content.title }}
                  />
                </Link>
                <p>{content.text}</p>
              </FadeUp>
            </div>
          ))}
        </div>
      </ResponsiveBox>
    </section>
  );
};

export default OurServices;
