export const remoteStorageUrl = 'https://perpetiostorage1.b-cdn.net';
export const blogPathHome =
  'https://perpet.io/blog/ghost/api/v3/content/posts/?key=f24926d2c2755e443987f64bd2&order=published_at%20DESC&limit=3&include=tags';

// production:
export const recaptchaSiteKeyV2 = '6LcMz08mAAAAAD2N6Q0w5fm_4atqVIQ1K-Dpju0Z';

// localhost:
export const recaptchaSiteKeyV2dev = '6LesSxonAAAAABkicEAUmPN5QZDoIxFi0aTA5P2l';

const checkIsProd = () =>
  process.env.NODE_ENV === 'production' ||
  (typeof window !== 'undefined' && window?.location?.hostname === 'perpet.io');

export const getCaptchaKey = () => {
  if (checkIsProd()) {
    return recaptchaSiteKeyV2;
  }
  return recaptchaSiteKeyV2dev;
};

const mixpanelTokenDev = '59350f4e7f0a43d1e8793d1423c4cba4';
const mixpanelTokenProd = 'b7683abca803eb201b086ffb4fbf3033';

export const getMixpanelToken = () => {
  if (checkIsProd()) {
    return mixpanelTokenProd;
  }
  return mixpanelTokenDev;
};
