import React from 'react';
import LazyLoad from 'react-lazyload';

import './clientsLove.scss';
import FadeUp from '../../../Animations/FadeUp';
import ResponsiveBox from '../../../UI/ResponsiveBox';
import Medium16px from '../../../UI/texts/16pxMedium';
import BlockHeader from '../../../UI/texts/BlockHeader';

const ClientsLove = ({ title, items, cdnUrl }) => {
  return (
    <ResponsiveBox>
      <div className="clients-love-container">
        <FadeUp>
          <BlockHeader
            dangerouslySetInnerHTML={{
              __html: title,
            }}
          />
        </FadeUp>

        <div className="clients-love-wrapper">
          {items.map((item, index) => (
            <FadeUp key={index} className="clients-love-item">
              <LazyLoad once offset={100}>
                <img src={`${cdnUrl}${item.img}`} alt={item.img} />
              </LazyLoad>
              <div>
                <BlockHeader
                  dangerouslySetInnerHTML={{
                    __html: item.title,
                  }}
                  variant="h3"
                />
                {item.text && (
                  <Medium16px
                    style={{ color: 'var(--p-text-color)' }}
                    dangerouslySetInnerHTML={{
                      __html: item.text,
                    }}
                  />
                )}
              </div>
            </FadeUp>
          ))}
        </div>
      </div>
    </ResponsiveBox>
  );
};

export default ClientsLove;
