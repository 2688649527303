import React from 'react';
import { ActiveProvider } from '../../../helpers/activeContext';
import { Helmet } from 'react-helmet-async';
import text from '../../../translation.json';
import { useLocation } from 'react-router-dom';
import ProjectsCompleted from '../../../components/Services/Shared/ProjectCompleted';
import { remoteStorageUrl } from '../../../config';
import Clover from '../../../components/Services/Shared/Clover/Clover';
import PinkishGradient from '../../../components/Services/Shared/PinkishGradient/PinkishGradient';
import Segments from '../../../components/Services/FullCycle/Segments/Segments';
import CarryingServices from '../../../components/Services/CrossPlatform/CarryingServices';
import TechStack from '../../../components/Services/Shared/TechStack/TechStack';
import TypesOfFitnessApp from '../../../components/Services/Shared/ImageSlider';
import './startupsMobile.scss';
import Form from '../../../components/Shared/Form';
import FAQ from '../../../components/Home/FAQ';
import ServiceHead from '../../../components/Services/Head';
import Domains from '../../../components/Home/Domains';
import Cases from '../../../components/Services/Shared/Cases';
import Team from '../../../components/Services/Shared/Team';

const MobileDevelopmentStartups = ({ data }) => {
  const location = useLocation();

  const metaTags = {
    ...text.metaTags.startups_mobile,
  };
  const cdnUrl = `${remoteStorageUrl}/resources/images/services_page/startups_mobile/`;

  return (
    <div className="mobile-startups">
      <ActiveProvider value="startups_mobile">
        <Helmet>
          <title>{metaTags.title}</title>
          <meta name="description" content={metaTags.description} />
          <link
            rel="canonical"
            href={`${process.env.REACT_APP_SITE_URL}${location.pathname}`}
          />
        </Helmet>

        <ServiceHead data={data.head} />

        <ProjectsCompleted data={data.projects_completed} cdnUrl={cdnUrl} />

        <Clover
          title={data.clover.title}
          subtitle={data.clover.subtitle}
          items={data.clover.items}
          cdnUrl={cdnUrl}
          Icon={<img src={`${cdnUrl}/clover.svg`} alt="clover" />}
        />

        <PinkishGradient
          title={data.performance.title}
          subtitle={data.performance.subtitle}
          items={data.performance.items}
          cdnUrl={cdnUrl}
          circles
        />

        <div className="sliders-wrapper">
          <TypesOfFitnessApp
            data={data.types_of_fitness_app1}
            topGap={false}
            cdnUrl={`${cdnUrl}/imageSlider/`}
            path={'resources/images/services_page/startups_mobile/imageSlider/'}
            style={{
              transform: 'rotate(180deg)',
            }}
            activeItemBgColor="linear-gradient(110deg, #F3F2FF 12.17%, #E8FFFE 62.49%, #F5F6FF 103.31%)"
            activeItemIconColor="white"
            bottomGap
          />
        </div>

        <Segments data={data.segments} />

        <CarryingServices
          title={data.services.title}
          items={data.services.items}
          topGap={true}
          bottomGap={false}
        />

        <TechStack
          title={data.tech_stack.title}
          images={data.tech_stack.images}
        />

        <PinkishGradient
          title={data.devices.title}
          subtitle={data.devices.subtitle}
          items={data.devices.items}
        />

        <Domains text={data.domains} hasWhiteBg />

        <Cases text={data.cases} threeBlockLayout={true} />

        <Team
          title={data.team.title}
          subtitle={data.team.subtitle}
          items={data.team.items}
          topGap={false}
          cdnUrl={`${remoteStorageUrl}/resources/images/services_page/startups_mobile/`}
        />

        <Form text={data.form} />

        <FAQ text={data.faq} />
      </ActiveProvider>
    </div>
  );
};

export default MobileDevelopmentStartups;
