import React, { useState, useEffect } from "react";
import CommitMain from "../../components/Projects/Commit/CommitMain/CommitMain";
import CommitDescription from "../../components/Projects/Commit/CommitDescription/CommitDescription";
import ScreensColumns from "../../components/Projects/Commit/ScreensColumns/ScreensColumns";
import CommitFlow from "../../components/Projects/Commit/CommitFlow/CommitFlow";
import CommitSlider from "../../components/Projects/Commit/CommitSlider/CommitSlider";
import CommitFeatures from "../../components/Projects/Commit/CommitFeatures/CommitFeatures";
import ProductCollaboration from "../../components/Projects/Commit/ProductCollaboration/ProductCollaboration";
import "./commit.scss";
import { Helmet } from "react-helmet-async";
import { useLocation } from "react-router-dom";

// eslint-disable-next-line import/no-anonymous-default-export
export default ({ data }) => {
  const location = useLocation();
  const mediaMatch =
    typeof window === "undefined"
      ? null
      : window.matchMedia("(max-width: 575px)");
  const [matches, setMatches] = useState(mediaMatch && mediaMatch.matches);

  useEffect(() => {
    const handler = (e) => setMatches(e.matches);
    mediaMatch && mediaMatch.addListener(handler);
  }, [mediaMatch]);

  return (
    <div className="commit-wrapper">
      <Helmet>
        <title>Commit | Perpetio</title>
        <meta
          name="description"
          content="We are the reliable mobile app development company you were looking for! Boost your business with a slick mobile app from Perpetio."
        />
        <link
          rel="canonical"
          href={`${process.env.REACT_APP_SITE_URL}${location.pathname}`}
        />
      </Helmet>
      <CommitMain text={data} />
      <CommitDescription text={data} />
      {matches ? (
        <CommitSlider text={data.onboarding} />
      ) : (
        <ScreensColumns text={data.onboarding} />
      )}
      <div className="commit-wrapper-bg-1">
        <ScreensColumns text={data.registration} />
        <ScreensColumns text={data.community} />
      </div>
      <div className="commit-wrapper-bg-2">
        {matches ? null : <CommitFlow text={data.user_flow} />}
        <CommitSlider text={data.product} />
        <CommitFeatures text={data.features} />
      </div>
      <ProductCollaboration text={data} />
    </div>
  );
};
