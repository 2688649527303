import React from "react";
import "./text.scss";

const Heavy20px = ({ children, ...rest }) => {
  return (
    <h3 {...rest} className="heavy20px">
      {children}
    </h3>
  );
};

export default Heavy20px;
