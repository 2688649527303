import React from 'react';

import ResponsiveBox from '../../UI/ResponsiveBox';

import './principles.scss';
import FadeUp from '../../Animations/FadeUp';
import BlockHeader from '../../UI/texts/BlockHeader';

const Principles = ({ text }) => {
  return (
    <div className="principles_container">
      <ResponsiveBox>
        <div className="principles_content_box">
          <FadeUp>
            <BlockHeader
              style={{ textAlign: 'center' }}
              dangerouslySetInnerHTML={{
                __html: text.title,
              }}
            />
          </FadeUp>
          <div className="principles_items_box">
            {text.items.map((item, index) => (
              <FadeUp key={index} className="principles_item">
                <div className="principles_item_img">
                  <img src={item.img_link} alt="Icon" />
                </div>
                <div className="principles_item_box">
                  <BlockHeader
                    variant="h3"
                    style={{ textAlign: 'center' }}
                    dangerouslySetInnerHTML={{ __html: item.text }}
                  />
                </div>
              </FadeUp>
            ))}
          </div>
        </div>
      </ResponsiveBox>
    </div>
  );
};

export default Principles;
