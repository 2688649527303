import * as React from 'react';

const SvgEargym = (props) => {
  return (
    <svg
      width={props.width ? props.width : '287'}
      height={props.height ? props.height : '90'}
      viewBox="0 0 287 90"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_86_15770)">
        <path
          d="M23.5763 67.7682C28.9948 67.7682 35.0563 65.8418 39.7401 62.6312L35.2399 54.4669C31.9337 56.485 28.8111 57.4941 25.4131 57.4941C19.3516 57.4941 14.7596 54.4669 13.5657 47.8621H40.8422C41.1177 46.7613 41.3932 44.2845 41.3932 41.8994C41.3932 29.5154 34.9644 20.0669 22.015 20.0669C11.086 20.0669 0.524414 29.1485 0.524414 43.9176C0.524414 58.9618 10.535 67.7682 23.5763 67.7682ZM13.4739 38.964C14.3923 33.1848 18.0659 30.341 22.2905 30.341C27.7091 30.341 29.9132 33.9186 29.9132 38.964H13.4739Z"
          fill="#FF4B39"
        />
        <path
          d="M61.2156 67.7682C66.3586 67.7682 70.5833 65.3832 74.4405 61.989H74.8079L75.7263 66.6674H86.7471V40.6152C86.7471 26.6717 80.3183 20.0669 68.6546 20.0669C61.4911 20.0669 54.9705 22.5437 49.1845 26.0296L54.0521 34.9277C58.4604 32.3592 62.2258 30.7997 66.0831 30.7997C71.0425 30.7997 72.9711 33.3682 73.2466 37.4045C55.246 39.2392 47.6233 44.468 47.6233 54.0082C47.6233 61.7138 52.8581 67.7682 61.2156 67.7682ZM65.8076 57.3106C62.5932 57.3106 60.5727 55.9346 60.5727 52.9992C60.5727 49.5133 63.6953 46.7613 73.2466 45.477V53.4578C70.8588 55.8429 68.8383 57.3106 65.8076 57.3106Z"
          fill="#FF4B39"
        />
        <path
          d="M98.1668 66.6674H111.667V40.2482C114.055 34.1021 118.28 31.9005 121.678 31.9005C123.607 31.9005 124.984 32.1757 126.637 32.6344L128.841 20.9842C127.556 20.4338 125.994 20.0669 123.423 20.0669C118.739 20.0669 113.78 23.0024 110.473 29.1485H110.106L109.188 21.1677H98.1668V66.6674Z"
          fill="#FF4B39"
        />
        <path
          d="M144.219 87.2157C155.423 87.2157 162.771 80.6109 165.158 68.7773L174.526 22.085H168.74L166.995 27.6808H166.72C164.607 23.6445 160.658 20.9842 155.974 20.9842C143.943 20.9842 132.371 34.1938 132.371 50.5224C132.371 60.8882 137.79 66.2088 145.321 66.2088C151.015 66.2088 156.158 63.365 160.842 58.6866L158.546 68.2269C156.617 76.2994 152.117 81.5282 144.494 81.5282C139.535 81.5282 135.31 79.1432 131.912 76.2077L128.606 81.0696C132.923 84.6472 138.249 87.2157 144.219 87.2157ZM147.617 60.3378C142.658 60.3378 139.627 56.7602 139.627 49.5133C139.627 37.8632 147.617 26.8552 156.25 26.8552C159.648 26.8552 162.587 28.7816 165.342 33.46L161.393 52.5405C157.168 57.6776 151.842 60.3378 147.617 60.3378Z"
          fill="#FF4B39"
        />
        <path
          d="M177.029 85.8397C185.57 85.8397 190.988 79.1432 195.672 70.4285L221.938 22.085H214.959L202.101 47.1282C199.897 51.348 197.601 56.2098 195.489 60.4296H195.121C194.754 56.2098 194.295 51.1645 193.652 47.1282L190.162 22.085H183.182L190.805 66.7592L189.152 69.9698C186.121 76.116 181.988 80.1522 177.488 80.1522C176.294 80.1522 175.284 79.877 174.182 79.3266L171.886 84.5554C173.631 85.4728 175.743 85.8397 177.029 85.8397Z"
          fill="#FF4B39"
        />
        <path
          d="M221.593 66.6674H228.573L234.91 35.1112C240.145 29.5154 244.829 27.0386 247.492 27.0386C251.901 27.0386 253.921 29.4237 253.921 33.9186C253.921 35.4781 253.645 36.7624 253.186 39.1474L247.676 66.6674H254.656L260.993 35.1112C266.319 29.5154 271.003 27.0386 273.575 27.0386C277.983 27.0386 280.095 29.4237 280.095 33.9186C280.095 35.4781 279.82 36.7624 279.361 39.1474L273.758 66.6674H280.83L286.524 38.2301C286.983 35.7533 287.259 34.1938 287.259 32.084C287.259 25.204 284.136 20.9842 276.697 20.9842C272.105 20.9842 266.595 24.2866 260.901 29.6989C260.258 24.837 257.686 20.9842 250.615 20.9842C245.931 20.9842 240.604 24.3784 235.92 28.7816H235.553L236.196 22.085H230.41L221.593 66.6674Z"
          fill="#FF4B39"
        />
      </g>
      <defs>
        <clipPath id="clip0_86_15770">
          <rect width="287" height="90" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default SvgEargym;
