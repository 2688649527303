import React from 'react';
import { Button } from 'reactstrap';
import { Link } from 'react-router-dom';

import ResponsiveBoxHead from '../../UI/ResponsiveBoxHead';
import NewMain from '../../NewMain/NewMain';
import './headWithImage.scss';

const ServiceHeadWithImage = ({
  data,
  imageUrl,
  hideBreadcrumbs = false,
  buttonHref = '/contact',
  onButtonClick,
  ...rest
}) => {
  return (
    <ResponsiveBoxHead {...rest}>
      <div className="service-head-image-container">
        <div>
          <NewMain
            title={data.title}
            text={data.text}
            hideBreadcrumbs={hideBreadcrumbs}
          />
          {buttonHref ? (
            <Link className="getInTouchBtn" to={buttonHref}>
              <Button
                color="primary"
                onClick={() => (onButtonClick ? onButtonClick() : {})}
              >
                {data.button}
              </Button>
            </Link>
          ) : (
            <div className="getInTouchBtn">
              <Button
                color="primary"
                onClick={() => (onButtonClick ? onButtonClick() : {})}
              >
                {data.button}
              </Button>
            </div>
          )}
        </div>
        {imageUrl && (
          <img src={imageUrl} alt="" className="service-head-image" />
        )}
      </div>
    </ResponsiveBoxHead>
  );
};

export default ServiceHeadWithImage;
