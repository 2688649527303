import React from 'react';
import { Row, Col } from 'reactstrap';
import { remoteStorageUrl } from '../../../../config';
import FadeUp from '../../../Animations/FadeUp';
import './main.scss';

const CareerMain = ({ text }) => {
  return (
    <FadeUp className="career-main-wrapper">
      <Row className="m-0 mt-5 mt-sm-5">
        <h1
          className="career-main-title mb-5"
          dangerouslySetInnerHTML={{ __html: text.title }}
        ></h1>
        <Col xs={12} className="d-flex flex-wrap p-0">
          {text.description.map((item, index) => (
            <div key={index} className="position-description mr-4">
              <img
                className="mr-2"
                src={`${remoteStorageUrl}/resources/icons/${item.icon}`}
                alt="icon"
              />
              <p className="m-0">{item.text}</p>
            </div>
          ))}
        </Col>
      </Row>
    </FadeUp>
  );
};

export default CareerMain;
