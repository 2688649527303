import React from 'react';
import {
  Modal,
  ModalHeader,
  ModalBody,
  Col
} from 'reactstrap';
import Form from '../Form/Form';
import data from '../../../translation.json';
import './modal.scss';
// import 'bootstrap/dist/css/bootstrap.min.css';
import { remoteStorageUrl } from '../../../config';
const successIcon = `${remoteStorageUrl}/resources/icons/success.svg`;
const dismissIcon = `${remoteStorageUrl}/resources/icons/bad.svg`;

const ModalDialog = ({ show, toggle, position, handleResponse, response }) => {
  const formData = data.careers.form;
  return (
    <Modal isOpen={show} toggle={toggle} centered size="md" className="modal-wrapper">
      <ModalHeader toggle={toggle} charCode="close"></ModalHeader>
      {
        response ? (
          <ModalBody className="response row justify-content-center">
            <Col align="center" xs={12}>
              <img className="mb-4" src={response.indexOf('!') !== -1 ? successIcon : dismissIcon} alt="success-icon" />
            </Col>
            <Col align="center" xs={12}>
              <p dangerouslySetInnerHTML={{__html: response}} />
            </Col>
          </ModalBody>
        ) : (
          <ModalBody className="row justify-content-center">
            <Form
              text={formData}
              position={position}
              handleResponse={handleResponse} />
          </ModalBody>
        )
      }
    </Modal>
  );
};

export default ModalDialog;