import React, { useState, useEffect } from "react";
import { Container, Row, Col } from "reactstrap";
import LazyLoad from "react-lazyload";
import { Image } from "../../../Image/Image";
import "./productPackages.scss";

export default ({ text }) => {
  const mediaMatch =
    typeof window === "undefined"
      ? null
      : window.matchMedia("(max-width: 575px)");
  const [matches, setMatches] = useState(mediaMatch && mediaMatch.matches);
  useEffect(() => {
    const handler = (e) => setMatches(e.matches);
    mediaMatch && mediaMatch.addListener(handler);
  });

  return (
    <Container className="meest-packages-wrapper" fluid>
      <Row className="meest-packages-row-wrapper">
        <Col xs={12} className="mb-5">
          <h2 className="meest-packages-title">{text.title}</h2>
          <p
            className="meest-packages-text"
            dangerouslySetInnerHTML={{
              __html: matches ? text.raw_text : text.text,
            }}
          />
        </Col>
        <Col className="meest-packages-col">
          <Row className="meest-packages-row">
            <LazyLoad once offset={1500}>
              <Image src={`meest/packages/${text.first_package}`} />
            </LazyLoad>
          </Row>
        </Col>
        <Col className="meest-packages-col">
          <Row className="meest-packages-row">
            <LazyLoad once offset={1500}>
              <Image
                className="meest-product-packages-img mb-3 mb-sm-5"
                src={`meest/packages/${text.second_package}`}
              />
            </LazyLoad>
            <LazyLoad once offset={1500}>
              <Image src={`meest/packages/${text.third_package}`} />
            </LazyLoad>
          </Row>
        </Col>
        <Col className="meest-packages-col">
          <Row className="meest-packages-row">
            <LazyLoad once offset={1500}>
              <Image src={`meest/packages/${text.fourth_package}`} />
            </LazyLoad>
          </Row>
        </Col>
      </Row>
    </Container>
  );
};
