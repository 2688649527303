import React, { useState, useEffect } from "react";
import LazyLoad from "react-lazyload";
import { Container, Row, Col } from "reactstrap";
import { Image } from "../../../Image/Image";
import "./productDescription.scss";

const ProductDescription = ({ text }) => {
  const mediaMatch =
    typeof window === "undefined"
      ? null
      : window.matchMedia("(max-width: 575px)");
  const [matches, setMatches] = useState(mediaMatch && mediaMatch.matches);
  useEffect(() => {
    const handler = (e) => setMatches(e.matches);
    mediaMatch && mediaMatch.addListener(handler);
  });

  const content = matches ? (
    <Row>
      <Col className="big-ticket-product-col" align="center">
        <LazyLoad once offset={1000}>
          <Image
            className="product-description-img-big-ticket"
            src={`big-ticket/screens/${text.product_description.column4.screens[0]}`}
            alt="product-wireframe-1"
          />
        </LazyLoad>
        <LazyLoad once offset={1000}>
          <Image
            className="product-description-img-big-ticket"
            src={`big-ticket/screens/${text.product_description.column4.screens[2]}`}
            alt="product-wireframe-1"
          />
        </LazyLoad>
      </Col>
      <Col className="big-ticket-product-col" align="center">
        <LazyLoad once offset={1000}>
          <Image
            className="product-description-img-big-ticket"
            src={`big-ticket/screens/${text.product_description.column4.screens[1]}`}
            alt="product-wireframe-1"
          />
        </LazyLoad>
        <LazyLoad once offset={1000}>
          <Image
            className="product-description-img-big-ticket"
            src={`big-ticket/screens/${text.product_description.column4.screens[3]}`}
            alt="product-wireframe-1"
          />
        </LazyLoad>
      </Col>
    </Row>
  ) : (
    <Row>
      <Col className="big-ticket-product-col" align="center">
        <LazyLoad once offset={1000}>
          <Image
            className="product-description-img-big-ticket mt-5 mt-sm-0"
            src={`big-ticket/screens/${text.product_description.column4.screens[0]}`}
            alt="product-wireframe-1"
          />
        </LazyLoad>
      </Col>
      <Col className="big-ticket-product-col" align="center">
        <LazyLoad once offset={1000}>
          <Image
            className="product-description-img-big-ticket mt-5 mt-sm-0"
            src={`big-ticket/screens/${text.product_description.column4.screens[1]}`}
            alt="product-wireframe-1"
          />
        </LazyLoad>
        <LazyLoad once offset={1000}>
          <Image
            className="product-description-img-big-ticket mt-5 mt-sm-0"
            src={`big-ticket/screens/${text.product_description.column4.screens[2]}`}
            alt="product-wireframe-1"
          />
        </LazyLoad>
      </Col>
      <Col className="big-ticket-product-col" align="center">
        <LazyLoad once offset={1000}>
          <Image
            className="product-description-img-big-ticket mt-5 mt-sm-0"
            src={`big-ticket/screens/${text.product_description.column4.screens[3]}`}
            alt="product-wireframe-1"
          />
        </LazyLoad>
      </Col>
    </Row>
  );

  return (
    <Container className="product-description" fluid>
      <Row className="margin justify-content-center">
        <Col className="product-description-row1 p-0" md={10} xl={7}>
          <h3 className="title mb-2 mb-md-4">
            {text.product_description.column1.title}
          </h3>
          <p
            className="description"
            dangerouslySetInnerHTML={{
              __html: text.product_description.column1.text,
            }}
          />
        </Col>
      </Row>
      <Row className="wireframes">
        <div className="product-description-content">
          <h3 className="title mb-2 mb-md-4">
            {text.product_description.column4.title}
          </h3>
          <p
            className="description"
            dangerouslySetInnerHTML={{
              __html: text.product_description.column4.text,
            }}
          />
        </div>
        <Col className="big-ticket-screens" xs={12} sm={9}>
          {content}
        </Col>
      </Row>
    </Container>
  );
};

export default ProductDescription;
