import React from 'react';

import './segments.scss';
import Medium16px from '../../../UI/texts/16pxMedium';
import FadeUp from '../../../Animations/FadeUp';
import BlockHeader from '../../../UI/texts/BlockHeader';
import useABGetVariant from '../../../../helpers/useABGetVariant';
import { Link } from 'react-router-dom';

const Segments = ({ data, textCentered }) => {
  return (
    <div
      className={`segments-block${
        textCentered ? ' segments-block-centered' : ''
      }`}
    >
      <BlockHeader
        style={{
          color: '#fff',
        }}
        dangerouslySetInnerHTML={{ __html: data.title }}
      />
      <FadeUp className="segments-block-container">
        {data.items.map((item) => (
          <div className="segment" key={item.title}>
            {item.link ? (
              <Link to={item.link}>
                <BlockHeader
                  variant="h3"
                  dangerouslySetInnerHTML={{
                    __html:
                      (item.abKey
                        ? useABGetVariant(item.abKey, 'segments') || item.title
                        : item.title) +
                      '<span class="segments-arrow">&#8250;</span>',
                  }}
                />
              </Link>
            ) : (
              <BlockHeader
                variant="h3"
                dangerouslySetInnerHTML={{
                  __html: item.abKey
                    ? useABGetVariant(item.abKey, 'segments') || item.title
                    : item.title,
                }}
              />
            )}
            <Medium16px
              dangerouslySetInnerHTML={{
                __html: item.text,
              }}
            />
          </div>
        ))}
      </FadeUp>
    </div>
  );
};

export default Segments;
