/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import './privacyPolicyMain.scss';
import ResponsiveBoxHead from '../UI/ResponsiveBoxHead';
import NewMain from '../NewMain/NewMain';
import ResponsiveBox from '../UI/ResponsiveBox';

const AntiSlaveryPolicy = () => (
  <div className="privacy-policy">
    <ResponsiveBoxHead style={{ paddingBottom: '2.5rem' }}>
      <div className="privacy-policy_header">
        <NewMain
          title="Anti-Slavery and Human Trafficking <span>Policy</span>"
          text="Last updated: October 2023"
        />
      </div>
    </ResponsiveBoxHead>
    <ResponsiveBox topGap={false}>
      <div className="description">
        <h3>PREAMBLE</h3>
        <p>
          This Anti-Slavery and Human Trafficking Policy (the "Policy") applies
          to all team members who work for or on behalf of Perpetio OÜ
          (hereinafter "the Company", "we" or "our") in any capacity, including
          but not limited to vendors, contractors, subcontractors, external
          consultants, third party representatives, clients and business
          partners. It is important to note that this Policy is not considered
          to be part of an employment contract, and we reserve the right to
          amend or cancel it at any time.
        </p>
        <p>
          Although we are not subject to Section 54 of the Modern Slavery Act
          2015 requiring a commercial organisation to prepare a slavery and
          human trafficking statement for each financial year of the
          organisation if its total turnover is not less than an amount
          prescribed by the Secretary of State, we anticipate it as it is
          possible that our clients may be United Kingdom residents, so we have
          decided to adopt the Policy as part of our ethical approach to doing
          business.
        </p>
        <h3>POLICY PURPOSE</h3>
        <p>
          Human slavery is a crime and a breach of fundamental human rights in
          all its forms, which share the same purpose of depriving one person of
          his or her liberty by another for the purpose of exploitation for
          personal or commercial gain. We have a zero-tolerance approach to any
          form of slavery. We are committed to acting ethically and with
          integrity in all our business dealings and to implementing and
          enforcing effective systems and controls to ensure that any form of
          slavery does not occur in our own business or with any of our vendors,
          partners, customers or potential customers. We expect the same high
          standards from all of our contractors, subcontractors, vendors,
          customers, and other business partners and expect them to maintain the
          same high standards with respect to their vendors.
        </p>
        <h3>RESPONSIBILITY</h3>
        <p>
          Our senior management has overall responsibility for ensuring
          compliance with this Policy across the Company. Operational managers
          have primary and day-to-day responsibility for implementing this
          Policy, monitoring its application and effectiveness, responding to
          any queries regarding it, and auditing internal control systems and
          procedures to ensure they are effective in combating modern slavery.
          Managers at all levels are responsible for ensuring that those
          reporting to them understand and comply with this policy and receive
          appropriate and regular training on it and on human slavery in supply
          chains. The overall responsibility for breaches of this Policy is
          disciplinary action up to and including dismissal or termination of
          any business relationship, with appropriate reporting of the offence
          to the relevant government agency with appropriate reporting lines.
        </p>
        <h3>COMPLIANCE WITH THIS POLICY</h3>
        <p>
          Preventing, detecting and reporting incidents of human slavery in any
          part of our business is the responsibility of everyone who works for
          us or is under our control. Our team members, contractors,
          subcontractors, suppliers, customers, partners, and others are
          required to avoid any activity that may lead to a violation of this
          Policy. In the event that you believe or suspect that a violation of
          this Policy has occurred or may occur, you should report it to the CEO
          as soon as possible. If you are unsure whether a particular action,
          the treatment of team members in general, or their working conditions
          at any level of our supply chain constitutes a form of human slavery,
          raise the issue with the CEO of Perpetio OÜ. We are committed to
          encouraging openness and will support anyone who raises a genuine
          concern in good faith under this Policy, even if it turns out to be
          wrong.
        </p>
        <h3>OTHER</h3>
        <p>
          This Policy has been approved by the CEO of Perpetio OÜ, if you would
          like to receive further information regarding this Policy, please
          contact the CEO of Perpetio OÜ.
        </p>
      </div>
    </ResponsiveBox>
  </div>
);

export default AntiSlaveryPolicy;
