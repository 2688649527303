import React, { useRef } from 'react';
import { useInViewport } from 'react-in-viewport';
import { useWindowDimensions } from '../../helpers/resizeHandler';

const AnimateIn = ({ from, to, children, threshold, ...rest }) => {
  const { width } = useWindowDimensions();
  const ref = useRef(null);
  const { inViewport, enterCount } = useInViewport(ref, {
    threshold: threshold ?? (width > 710 ? 0.5 : 0.35),
  });
  const defaultStyles = {
    transition: '700ms ease-in-out',
  };
  return (
    <div
      {...rest}
      ref={ref}
      style={
        inViewport
          ? {
              ...defaultStyles,
              ...to,
            }
          : enterCount > 0
          ? { ...defaultStyles, ...to }
          : {
              ...defaultStyles,
              ...from,
            }
      }
    >
      {children}
    </div>
  );
};

export default AnimateIn;
