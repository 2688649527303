import React from 'react';

const ResponsiveBox = ({
  children,
  topGap = true,
  bottomGap = true,
  ...rest
}) => {
  return (
    <div className="max-width-container" {...rest}>
      <div
        className="responsive-container responsive-page-top_bottom-gap"
        style={{
          paddingTop: topGap ? '' : 0,
          paddingBottom: bottomGap ? '' : 0,
        }}
      >
        {children}
      </div>
    </div>
  );
};

export default ResponsiveBox;
