import React, { useState, useEffect } from "react";
import LazyLoad from "react-lazyload";
import { Container, Row, Col } from "reactstrap";
import "./productDescription.scss";
import { remoteStorageUrl } from "../../../../config";

const ProductDescription = ({ text }) => {
  const mediaMatch =
    typeof window === "undefined"
      ? null
      : window.matchMedia("(max-width: 575px)");
  const [matches, setMatches] = useState(mediaMatch && mediaMatch.matches);
  useEffect(() => {
    const handler = (e) => setMatches(e.matches);
    mediaMatch && mediaMatch.addListener(handler);
  });

  return (
    <Container className="product-description" fluid>
      <div className="max-width-container">
        <Row className="my-5 margin justify-content-center">
          <Col className="product-description-row1 p-0" md={10} xl={7}>
            <h3 className="title mb-2 mb-md-4">
              {text.product_description.column1.title}
            </h3>
            <p className="description">
              {text.product_description.column1.text}
            </p>
          </Col>
        </Row>
        <Row className="my-5 margin justify-content-center">
          <Col className="product-description-row2 p-0 mt-md-5" md={10} xl={7}>
            <h3 className="title mb-2 mb-md-4">
              {text.product_description.column2.title}
            </h3>
            <p className="description">
              {text.product_description.column2.text}
            </p>
          </Col>
        </Row>
      </div>
      {!matches ? (
        <Container className="wireframes" fluid>
          <div className="max-width-container">
            <Row className="mb-5 margin justify-content-center">
              <Col className="product-description-row2 p-0" md={10} xl={7}>
                <h3 className="title mb-2 mb-md-4">
                  {text.product_description.column4.title}
                </h3>
                <p
                  className="description"
                  dangerouslySetInnerHTML={{
                    __html: text.product_description.column4.text,
                  }}
                />
              </Col>
            </Row>
            <Row className="margin screens justify-content-center">
              {text.product_description.column4.screens.map((screen, index) => (
                <Col
                  key={index}
                  className="product-screens-col"
                  align="center"
                  xs={10}
                  md={4}
                >
                  <LazyLoad once offset={1000}>
                    <img
                      src={`${remoteStorageUrl}/resources/images/burn/wireframes/${screen.wireframe2x}`}
                      srcSet={
                        `${remoteStorageUrl}/resources/images/burn/wireframes/${screen.wireframe2x}` +
                        " 2x," +
                        `${remoteStorageUrl}/resources/images/burn/wireframes/${screen.wireframe4x}` +
                        " 3x"
                      }
                      alt="product-wireframe-1"
                    />
                  </LazyLoad>
                </Col>
              ))}
            </Row>
          </div>
        </Container>
      ) : null}
    </Container>
    // </div>
  );
};

export default ProductDescription;
