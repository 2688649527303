import React, { Fragment, useEffect, useState } from 'react';
import ProductMain from '../../components/Projects/Aob/ProductMain/ProductMain';
import ProductOverview from '../../components/Projects/Aob/ProductOverview/ProductOverview';
import ProductCollaboration from '../../components/Projects/Aob/ProductCollaboration/ProductCollaboration';
import ProductFeatureBlock from '../../components/Projects/Aob/ProductFeatureBlock/ProductFeatureBlock';
import ProductDescription from '../../components/Projects/Aob/ProductDescription/ProductDescription';
import ProductScreens from '../../components/Projects/Aob/ProductScreens/ProductScreens';
import AobSlider from '../../components/Projects/Aob/AobSlider/AobSlider';
import ProductResult from '../../components/Projects/Aob/ProductResult/ProductResult';
import { Helmet } from 'react-helmet-async';
import { useLocation } from 'react-router-dom';

export default ({ data }) => {
  const location = useLocation();
  const mediaMatch =
    typeof window === 'undefined'
      ? null
      : window.matchMedia('(max-width:1000px)');
  const [matches, setMatches] = useState(mediaMatch && mediaMatch.matches);

  useEffect(() => {
    const handler = (e) => setMatches(e.matches);
    mediaMatch && mediaMatch.addListener(handler);
  }, [mediaMatch]);
  return (
    <Fragment>
      <Helmet>
        <title>AOB | Perpetio</title>
        <meta
          name="description"
          content="We are the reliable mobile app development company you were looking for! Boost your business with a slick mobile app from Perpetio."
        />
        <link
          rel="canonical"
          href={`${process.env.REACT_APP_SITE_URL}${location.pathname}`}
        />
      </Helmet>
      <div className="aob-wrapper">
        <ProductMain text={data} />
        <ProductOverview text={data} />
        <ProductFeatureBlock text={data} />
        <ProductDescription text={data} />
        {matches && false ? (
          <AobSlider text={data.product.slides} />
        ) : (
          <ProductScreens text={data.product.screens} />
        )}
        <ProductResult text={data} />
        <ProductCollaboration text={data} />
      </div>
    </Fragment>
  );
};
