import React from 'react';
import LazyLoad from 'react-lazyload';
import { remoteStorageUrl } from '../../../config';

const LazyImage = ({ imageLink, path, alt, ...rest }) => {
  const imageName = imageLink.split('.')[0];
  const imageExt = imageLink.split('.')[1];
  const image2x = `${remoteStorageUrl}/${path}/${imageName}@2x.${imageExt}`;
  const image3x = `${remoteStorageUrl}/${path}/${imageName}@3x.${imageExt}`;
  const image4x = `${remoteStorageUrl}/${path}/${imageName}@4x.${imageExt}`;
  return (
    <LazyLoad once offset={100}>
      <img
        srcSet={`${image2x} 1x, ${image2x} 2x, ${image3x} 3x, ${image4x} 4x,`}
        alt={alt || 'screen'}
        {...rest}
      />
    </LazyLoad>
  );
};

export default LazyImage;
