import React, { useState, useEffect } from 'react';
import withRouter from '../../utils/withRouter';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import text from '../../translation.json';
import './works.scss';
import { Helmet } from 'react-helmet-async';
import NewMain from '../../components/NewMain/NewMain';
import { useLocation } from 'react-router-dom';
import FadeUp from '../../components/Animations/FadeUp';
import WorkProduct from './WorkProduct/WorkProduct';
import { remoteStorageUrl } from '../../config';
import ResponsiveBoxHead from '../../components/UI/ResponsiveBoxHead';
import ResponsiveBox from '../../components/UI/ResponsiveBox';

const startLimit = 9;
const projects = text.works.items.filter((item) => !item.disabled);

const NewWorks = (props) => {
  const location = useLocation();
  const [showList, setShowList] = useState(true);
  const [type, setType] = useState('All');
  const [photoIndex, setPhotoIndex] = useState(0);
  const [isOpen, setIsOpen] = useState(false);
  const [showMoreBtn, setShowMoreBtn] = useState(true);
  const [limit, setLimit] = useState(startLimit);
  const [filteredArray, setFilteredArray] = useState(projects.slice(0, limit));
  useEffect(() => {
    isOpen
      ? (document.body.style.overflow = 'hidden')
      : (document.body.style.overflow = 'unset');
  }, [isOpen]);

  // if change type that effect will update filtered array
  useEffect(() => {
    setLimit(startLimit);
    setShowMoreBtn(true);
    if (type === 'All') {
      const array = projects
        .filter((work) => work.filter_tags.map((item) => item))
        .slice(0, startLimit);
      setFilteredArray(array);
    } else {
      const array = projects
        .filter((work) => work.filter_tags.indexOf(type) >= 0)
        .slice(0, startLimit);
      setFilteredArray(array);
    }
  }, [type]);

  useEffect(() => {
    setShowList(false);
    setTimeout(() => {
      setShowList(true);
    }, 0);
  }, [type]);

  //hide show more button
  useEffect(() => {
    if (type === 'All') {
      if (projects.length === filteredArray.length) {
        setShowMoreBtn(false);
      }
    } else {
      const array = projects.filter(
        (work) => work.filter_tags.indexOf(type) >= 0,
      );
      if (array.length === filteredArray.length) {
        setShowMoreBtn(false);
      }
    }
  }, [filteredArray, type]);

  const image = projects.find((img) => img.id === photoIndex);
  if (image && image.link === 'burn') {
    props.history.navigate(`/use-cases/${image.link}`);
  }

  const handleTypeChange = (type) => {
    setType(type);
  };
  const handleMoreBtn = () => {
    setLimit(limit + startLimit);
    if (type === 'All') {
      const array = projects
        .filter((work) => work.filter_tags.map((item) => item))
        .slice(0, limit + startLimit);
      setFilteredArray(array);
    } else {
      const array = projects
        .filter((work) => work.filter_tags.indexOf(type) >= 0)
        .slice(0, limit + startLimit);
      setFilteredArray(array);
    }
  };

  return (
    <div className=" portfolioContainer">
      <div className="portfolio">
        <Helmet>
          <title>
            {text.works.helmetTitle
              ? text.works.helmetTitle
              : 'Mobile App Development Company | Perpetio'}
          </title>
          <meta
            name="description"
            content={
              text.works.helmetDescription
                ? text.works.helmetDescription
                : 'We are the reliable mobile app development company you were looking for! Boost your business with a slick mobile app from Perpetio.'
            }
          />
          <link
            rel="canonical"
            href={`${process.env.REACT_APP_SITE_URL}${location.pathname}`}
          />
        </Helmet>

        <ResponsiveBoxHead>
          <div className="portfolio_header">
            <NewMain title={text.works.title} text={text.works.subtitle} />
          </div>
        </ResponsiveBoxHead>

        <ResponsiveBox>
          <div className="tagsContainer">
            <FadeUp className="p-0">
              <ul className={'ks-cboxtags'}>
                {text.works.work_types.map((workType, index) => (
                  <li key={index}>
                    <span
                      onClick={() => handleTypeChange(workType)}
                      className={type === workType ? 'checked' : 'unchecked'}
                    >
                      {workType}
                    </span>
                  </li>
                ))}
              </ul>
            </FadeUp>
          </div>
          {showList && (
            <div className="work_projects">
              {filteredArray.map((work, index) => {
                return (
                  <WorkProduct
                    key={index}
                    work={work}
                    setIsOpen={setIsOpen}
                    setPhotoIndex={setPhotoIndex}
                    history={props.history}
                  />
                );
              })}
            </div>
          )}
          {showMoreBtn && (
            <div className="loadMoreBox">
              <div onClick={handleMoreBtn}>Load More</div>
            </div>
          )}
          {image && isOpen && (
            <Lightbox
              enableZoom={true}
              mainSrc={`${remoteStorageUrl}/resources/images/portfolio/${image.details}`}
              onCloseRequest={() => setIsOpen(false)}
            />
          )}
        </ResponsiveBox>
      </div>
    </div>
  );
};

export default withRouter(NewWorks);
