import React from 'react';

const ResponsiveBoxHead = ({ children, ...rest }) => {
  return (
    <div
      {...rest}
      className={`responsive-page-head-top_bottom-gap${
        rest.className ? ` ${rest.className}` : ''
      }`}
    >
      <div className="max-width-container ">
        <div className="responsive-container">{children}</div>
      </div>
    </div>
  );
};

export default ResponsiveBoxHead;
