import React from 'react';
import { Button } from 'reactstrap';
import { Link } from 'react-router-dom';

import ResponsiveBoxHead from '../../UI/ResponsiveBoxHead';
import NewMain from '../../NewMain/NewMain';
import './head.scss';

const ServiceHeadDark = ({ data, ...rest }) => {
  return (
    <ResponsiveBoxHead {...rest}>
      <div className="service-head-container-dark">
        <NewMain title={data.title} text={data.text} hideBreadcrumbs />
        <Link className="getInTouchBtn" to={'/contact'}>
          <Button color="primary">{data.button}</Button>
        </Link>
      </div>
    </ResponsiveBoxHead>
  );
};

export default ServiceHeadDark;
