import React from 'react';
import './text.scss';

const Heavy24px = ({ children, ...rest }) => {
  return (
    <h3 {...rest} className="heavy24px">
      {children}
    </h3>
  );
};

export default Heavy24px;
