import sbjs from "sourcebuster";

export const crmData = () => {
  sbjs.init({ domain: "perpet.io" });
  const clientIdGa = () => {
    let match = document.cookie.match("(?:^|;)\\s*_ga=([^;]*)");
    const raw = match ? decodeURIComponent(match[1]) : null;
    if (raw) {
      match = raw.match(/(\d+\.\d+)$/);
    }
    const gacid = match ? match[1] : null;
    if (gacid) {
      return gacid;
    }
  };
  const trafficType = sbjs.get.current.typ;
  const source = sbjs.get.current.src;
  const utm_medium = sbjs.get.current.mdm;
  const utm_campaign = sbjs.get.current.cmp;
  const utm_term = sbjs.get.current.trm;
  const entrance_point = sbjs.get.current_add.ep;
  const firstTypeTraffic = sbjs.get.first.typ;
  const first_utm_source = sbjs.get.first.src;
  const first_utm_medium = sbjs.get.first.mdm;
  const first_utm_campaign = sbjs.get.first.cmp;
  const first_utm_term = sbjs.get.first.trm;
  const first_visit_time = sbjs.get.first_add.fd;
  const first_entrance_point = sbjs.get.first_add.ep;

  return {
    clientIdGA: clientIdGa(),
    trafficType,
    source,
    utm_medium,
    utm_campaign,
    utm_term,
    entrance_point,
    firstTypeTraffic,
    first_utm_source,
    first_utm_medium,
    first_utm_campaign,
    first_utm_term,
    first_visit_time,
    first_entrance_point,
  };
};
