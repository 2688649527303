import React from "react";
import InputMask from "react-input-mask";

import "./input.scss";

const CustomInput = (props) => {
  const { field, form, meta, placeholder, required, type } = props;
  return (
    <div className="input_container">
      <div
        className={`form-group ${field.value ? "hidePlaceholder" : ""}`}
        style={{
          border: !!meta.error && !!meta.touched ? "1px solid red" : "",
        }}
      >
        <InputMask mask="(999) 999-9999" maskChar={null} {...field} placeholder={placeholder}>
          {(inputProps) => <input {...inputProps} type={type} />}
        </InputMask>
        {/* <input
          onChange={(event) => {
            return (
              form.setFieldValue(field.name, event.target.value),
              form.setFieldTouched(field.name, false)
            );
          }}
          placeholder={!required ? placeholder : ""}
          type={type}
        /> */}
        {required ? <label>{placeholder}</label> : null}
      </div>
      {!!meta.error && !!meta.touched ? (
        <div className="error_box">{meta.error}</div>
      ) : null}
    </div>
  );
};

export default CustomInput;
