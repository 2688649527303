import React from 'react';
import text from '../../translation.json';
import withRouter from '../../utils/withRouter';
import './development.scss';
import { Item } from './Item/Item';

const Development = ({ history }) => {
  return (
    <div className="development max-width-container">
      <div className="development-wrapper responsive-container responsive-page-top_bottom-gap">
        {text.development_new.columns.map((item, index) => (
          <Item key={index} content={item} path="images" />
        ))}
      </div>
    </div>
  );
};

export default withRouter(Development);
