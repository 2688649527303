import React from 'react';
import { Helmet } from 'react-helmet-async';
import { useLocation } from 'react-router-dom';
import PrivacyPolicyMain from '../../components/PrivacyPolicyMain/PrivacyPolicyMain';
import EqualityDiversityInclusionPolicy from '../../components/PrivacyPolicyMain/EqualityDiversityInclusionPolicy';
import AntiSlaveryPolicy from '../../components/PrivacyPolicyMain/Anti-SlaveryPolicy';
import EnvironmentalPolicy from '../../components/PrivacyPolicyMain/EnvironmentalPolicy';
import CorporatePolicy from '../../components/PrivacyPolicyMain/CorporatePolicy';
import AntiBriberyPolicy from '../../components/PrivacyPolicyMain/AntiBriberyPolicy';

export const POLICY_TYPES = {
  privacy: 'Privacy Policy',
  equality: 'Equality, Diversity & Inclusion Policy',
  environmental: 'Environmental & Sustainability Policy',
  antislavery: 'Anti-Slavery and Human Trafficking Policy',
  corporate: 'CORPORATE SOCIAL RESPONSIBILITY POLICY',
  anti_bribery: 'Anti-Bribery Policy',
};

const PrivacyPolicy = ({ policy }) => {
  const location = useLocation();
  return (
    <div className="careers">
      <Helmet>
        <title>{policy} | Perpetio</title>
        <meta
          name="description"
          content="We are the reliable mobile app development company you were looking for! Boost your business with a slick mobile app from Perpetio."
        />
        <link
          rel="canonical"
          href={`${process.env.REACT_APP_SITE_URL}${location.pathname}`}
        />
      </Helmet>
      {policy === POLICY_TYPES.privacy && <PrivacyPolicyMain />}
      {policy === POLICY_TYPES.anti_bribery && <AntiBriberyPolicy />}
      {policy === POLICY_TYPES.corporate && <CorporatePolicy />}
      {policy === POLICY_TYPES.antislavery && <AntiSlaveryPolicy />}
      {policy === POLICY_TYPES.environmental && <EnvironmentalPolicy />}
      {policy === POLICY_TYPES.equality && <EqualityDiversityInclusionPolicy />}
    </div>
  );
};

export default PrivacyPolicy;
