import React from "react";
import text from "../../translation.json";
import Title from "../../components/PerpetioTitle";
import FadeUp from "../Animations/FadeUp";
import "./multidisciplinarity.scss";
import { remoteStorageUrl } from "../../config";
import ResponsiveBox from "../../components/UI/ResponsiveBox";

const Multidisciplinarity = () => {
  const multidisc_image = text.about_us.sections.multidisciplinarity.image;
  return (
    <ResponsiveBox topGap={false}>
      <Title
        title={text.about_us.sections.multidisciplinarity.title}
        subtitle={text.about_us.sections.multidisciplinarity.subtitle}
      />
      <FadeUp className="multidisc-box">
        <div className="article-box">
          <p
            className="multidisc-box-article"
            dangerouslySetInnerHTML={{
              __html: text.about_us.sections.multidisciplinarity.article,
            }}
          />
          <p
            className="multidisc-box-article"
            dangerouslySetInnerHTML={{
              __html: text.about_us.sections.multidisciplinarity.article2,
            }}
          />
          <div className="article-margin-top">
            {text.about_us.sections.multidisciplinarity.mottosArray.map(
              (motto) => (
                <p
                  key={motto}
                  className="multidisc-box-article"
                  dangerouslySetInnerHTML={{
                    __html: motto,
                  }}
                />
              )
            )}
          </div>

          <img
            className="multidisc-image"
            src={`${remoteStorageUrl}/resources/images/about/${multidisc_image}`}
            alt={text.about_us.sections.multidisciplinarity.image}
          />
        </div>
      </FadeUp>
    </ResponsiveBox>
  );
};
export default Multidisciplinarity;
