import * as React from "react";

function SvgBlueArrowsRight() {
  return (
    <svg
      width="7"
      height="12"
      viewBox="0 0 7 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.5621 6L0 1.26512L1.21895 0L7 6L1.21895 12L0 10.7349L4.5621 6Z"
        fill="#3095F2"
      />
    </svg>
  );
}

export default SvgBlueArrowsRight;
