import React from 'react';
import LazyLoad from 'react-lazyload';

import './team.scss';
import FadeUp from '../../../Animations/FadeUp';
import ResponsiveBox from '../../../UI/ResponsiveBox';
import Medium16px from '../../../UI/texts/16pxMedium';
import Medium24px from '../../../UI/texts/24pxMedium';
import BlockHeader from '../../../UI/texts/BlockHeader';

const Team = ({ title, subtitle, items, cdnUrl, topGap = true, ...rest }) => {
  return (
    <ResponsiveBox topGap={topGap} {...rest}>
      <div className="team-wrapper">
        <FadeUp>
          <BlockHeader
            dangerouslySetInnerHTML={{
              __html: title,
            }}
            style={{
              marginBottom: '1.5rem',
            }}
          />
        </FadeUp>
        {subtitle ? (
          <FadeUp>
            <Medium24px
              style={{
                color: '#4B4B4B',
                textAlign: 'center',
                marginBottom: '3.75rem',
              }}
              dangerouslySetInnerHTML={{
                __html: subtitle,
              }}
            />
          </FadeUp>
        ) : null}
        <div className="team-container">
          {items.map((item) => {
            return (
              <FadeUp key={item.title} className="team-item">
                <LazyLoad once offset={100}>
                  <img
                    src={`${cdnUrl}${item.img}`}
                    alt={item.title}
                    className="team-img"
                  />
                </LazyLoad>
                <div className="team-item__text-box">
                  <BlockHeader variant="h3">{item.title}</BlockHeader>
                  <Medium16px
                    style={{ color: 'var(--p-text-color)' }}
                    dangerouslySetInnerHTML={{ __html: item.text }}
                  ></Medium16px>
                </div>
              </FadeUp>
            );
          })}
        </div>
      </div>
    </ResponsiveBox>
  );
};

export default Team;
