import React from 'react';

import ResponsiveBox from '../../UI/ResponsiveBox';
import Medium24px from '../../UI/texts/24pxMedium';
import './domain.scss';
import FadeUp from '../../Animations/FadeUp';
import Medium18px from '../../UI/texts/18pxMedium';
import BlockHeader from '../../UI/texts/BlockHeader';

const Domains = ({
  text,
  topGap = true,
  bgColor,
  hasWhiteBg = false,
  ...rest
}) => {
  return (
    <div
      className={
        hasWhiteBg ? 'domainContainer domainContainerWhite' : 'domainContainer'
      }
      {...rest}
    >
      <ResponsiveBox topGap={topGap}>
        <div className="domain_content_box">
          <FadeUp className="domain_content_title_box">
            <BlockHeader
              dangerouslySetInnerHTML={{
                __html: text.title,
              }}
            />
            {text.subtitle && (
              <Medium24px
                style={{ textAlign: 'center', color: '#4B4B4B' }}
                dangerouslySetInnerHTML={{
                  __html: text.subtitle,
                }}
              />
            )}
          </FadeUp>
          <div className="domains_list_box">
            {text.domainItems.map((row) => {
              return (
                <FadeUp key={row.title} className="row_box">
                  <div
                    className="row_title"
                    style={{ background: bgColor ? bgColor : '' }}
                  >
                    <BlockHeader
                      style={{ color: '#3095F2', textAlign: 'center' }}
                      variant="h3"
                    >
                      {row.title}
                    </BlockHeader>
                  </div>

                  <div
                    className="row_content"
                    style={{ background: bgColor ? bgColor : '' }}
                  >
                    {row.items.map((item) => (
                      <div key={item} className="row_content_item">
                        <div className="row_dot" />
                        <Medium18px
                          style={{
                            color: '#333333',
                            whiteSpace: 'nowrap',
                            fontSize: '1.25rem',
                          }}
                        >
                          {item}
                        </Medium18px>
                      </div>
                    ))}
                  </div>
                </FadeUp>
              );
            })}
          </div>
        </div>
      </ResponsiveBox>
    </div>
  );
};

export default Domains;
