import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import './productFeatureBlock.scss';
import { Image } from '../../../Image/Image';

const ProductFeatureBlock = ({text}) => {

  return (
    <Container className="product-features-block my-3" fluid>
      <div className="max-width-container">
      {
        text.product_features_block.map((item, i) => (
            <Row key={i} className={`my-5 margin justify-content-space-between align-items-center product-features-block-row flex-sm-row flex-column ${ i%2 ? ' flex-sm-row-reverse' : null }`}>
                <Col className={`product-features-block-col col-sm-5 product-features-block-image text-center ${ i%2 ? ' text-sm-right' : ' text-sm-left' }`}>
                  <Image className="product-features-img" src={`burn/features/${item.image}`} />
                </Col>
                <Col className="product-features-block-col col-7-sm">
                    <h3 className="product-features-title">{item.title}</h3>
                    <p className="product-features-text">{item.text}</p>
                </Col>
            </Row>
        ))
      }
      </div>
    </Container>
  );
};

export default ProductFeatureBlock;