import React, { useState, useEffect } from 'react';
import { Helmet } from "react-helmet-async";
import { useLocation } from 'react-router-dom';

import PageHead from '../../components/Projects/Eargym/PageHead';
import ReverseBox from '../../components/Projects/Eargym/ReverseBox';
import TextImageBox from '../../components/Projects/Eargym/TextImageBox';
import TechnicalDetails from '../../components/Projects/Eargym/TechnicalDetails';
import './eargym.scss';

// eslint-disable-next-line import/no-anonymous-default-export
export default ({ data }) => {
  const location = useLocation();
  const mediaMatch =
    typeof window === 'undefined'
      ? null
      : window.matchMedia('(max-width: 575px)');
  const [, setMatches] = useState(mediaMatch && mediaMatch.matches);

  useEffect(() => {
    const handler = e => setMatches(e.matches);
    mediaMatch && mediaMatch.addListener(handler);
  }, [mediaMatch]);

  return (
    <div className="eargymContainer">
      <div className="max-width-container">
        <Helmet>
          <title>Eargym | Perpetio</title>
          <meta
            name="description"
            content="Helping companies tackle hearing loss: the biggest problem you've never heard of."
          />
          <link
            rel="canonical"
            href={`${process.env.REACT_APP_SITE_URL}${location.pathname}`}
          />
        </Helmet>
        <PageHead text={data.head} />
        <ReverseBox data={data.challenge} reverse={false} />
        <ReverseBox data={data.solution} reverse={true} />
        <TextImageBox data={data.result} />
        <TextImageBox data={data.hearing} />
        <TextImageBox data={data.hearingTraining} />
        <TextImageBox data={data.dailyActivities} />
        <TextImageBox data={data.discoverTab} />
        <TextImageBox data={data.companyLog} />
        <TextImageBox data={data.monetizationModel} />
        <TechnicalDetails data={data.technicalDetails} />
      </div>
    </div>
  );
};
