import React from 'react';
import LazyLoad from 'react-lazyload';
import { Row } from 'reactstrap';
import { Image } from '../../../Image/Image';
import './productScreens.scss';

export default ({text}) => (
  <Row className="aob-product-screens">
    {
      text.map((item, index) => (
          <LazyLoad key={index} once offset={800}>
            <Image
              className={`${item.includes("phone") ? "phone" : ""}`}
              src={`aob/screens/${item}`} alt="screen" />
          </LazyLoad>
      ))
    }
  </Row>
);
