import React, { useState, useEffect } from 'react';
import { Container, Row, Col } from 'reactstrap';
import './productMain.scss';
import { remoteStorageUrl } from '../../../../config';
const GooglePlay = `${remoteStorageUrl}/resources/images/btn-download-android.svg`;
const mAppStore = `${remoteStorageUrl}/resources/images/btn-download-apple.svg`;
const mGooglePlay = `${remoteStorageUrl}/resources/images/btn-download-android.svg`;
const mainBg = `${remoteStorageUrl}/resources/images/privacy-bg-1.svg`;
const mMainBg = `${remoteStorageUrl}/resources/images/mobile-product-main-bg.svg`;
const AppStore = `${remoteStorageUrl}/resources/images/btn-download-apple.svg`;

const ProductMain = ({ text }) => {
  const mediaMatch =
    typeof window === 'undefined'
      ? null
      : window.matchMedia('(max-width: 575px)');
  const [matches, setMatches] = useState(mediaMatch && mediaMatch.matches);

  useEffect(() => {
    const handler = (e) => setMatches(e.matches);
    mediaMatch && mediaMatch.addListener(handler);
  }, [mediaMatch]);
  return (
    <Container className="product-main" fluid>
      <img
        className="product-main-bg"
        src={matches ? mMainBg : mainBg}
        alt="background-dots"
      />
      <Row className="product-main-row">
        <Col xs={9} md={7} xl={5} style={{ zIndex: 1 }}>
          <h1 className="title">{text.product.title}</h1>
          <p
            className="description"
            dangerouslySetInnerHTML={{ __html: text.product.text }}
          />
          <Row className="platforms">
            <a
              href={text.product.app_store}
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                className="product-result-download-btn"
                src={matches ? mAppStore : AppStore}
                alt="appstore"
              />
            </a>
            <a
              href={text.product.google_play}
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                className="product-result-download-btn"
                src={matches ? mGooglePlay : GooglePlay}
                alt="googleplay"
              />
            </a>
          </Row>
        </Col>
      </Row>
    </Container>
  );
};

export default ProductMain;
