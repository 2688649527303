import React from 'react';
import './breadcrumbs.scss';
import { NavLink as RRNavLink } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { NavLink } from 'reactstrap';
import ArrowsRight from '../../components/icons/arrow_right';
import useABGetVariant from '../../helpers/useABGetVariant';

const Breadcrumbs = () => {
  let location = useLocation();
  const parts = location.pathname.split('/');
  const path = (index) => {
    let path = '';
    for (let i = index === 0 ? 0 : 1; i <= index; i++) {
      path = `${path}/${parts[i]}`;
    }
    return path;
  };

  return (
    <div className={`breadcrumbs_box`}>
      {location.pathname !== '/' &&
        parts.map((part, index) => {
          if (part === 'full-cycle') {
            part = 'Full-Cycle Mobile App Development';
          } else if (part === 'flutter') {
            part = 'Flutter App Development';
          } else if (part === 'ios') {
            part = 'iOS Development';
          } else if (part === 'ui-ux') {
            part = 'UI/UX Design';
          } else if (part === 'android') {
            part = 'Android Development';
          } else if (part === 'ios-dev') {
            part = 'iOS Developer';
          } else if (part === 'android-dev') {
            part = 'Android Developer';
          } else if (part === 'project-manager') {
            part = 'Project Manager';
          } else if (part === 'flutter') {
            part = 'Flutter Developer';
          } else if (part === 'use-cases') {
            part = 'Case Studies';
          } else if (part === 'about') {
            part = 'About Us';
          } else if (part === 'contact') {
            part = 'Contact Us';
          } else if (part === 'wearable-app') {
            part = 'Wearable App';
          } else if (part === 'ar-vr') {
            part = 'AR/VR-based App';
          } else if (part === 'iot') {
            part = 'IoT App';
          } else if (part === 'healthcare') {
            part = 'Healthcare App';
          } else if (part === 'social-networks') {
            part = 'Social Networking';
          } else if (part === 'ecommerce') {
            part = 'E-Commerce App Development';
          } else if (part === 'wearable-app') {
            part = 'Wearable App';
          } else if (part === 'social-networks') {
            part = 'Social Networking';
          } else if (part === 'startups-mobile-development') {
            part = 'Mobile Development For Startups';
          } else if (part === 'privacy-policy') {
            part = 'Privacy Policy';
          } else if (part === 'equality-policy') {
            part = 'Equality, Diversity & Inclusion Policy';
          } else if (part === 'environmental-policy') {
            part = 'Environmental & Sustainability Policy';
          } else if (part === 'antislavery-policy') {
            part = 'Anti-Slavery and Human Trafficking Policy';
          } else if (part === 'anti-bribery-policy') {
            part = 'Anti Bribery Policy';
          } else if (part === 'corporate-policy') {
            part = 'Corporate Social Responsibility Policy';
          } else if (part === 'environmental-policy') {
            part = 'Environmental & Sustainability Policy';
          } else if (part === 'equality-policy') {
            part = 'Equality, Diversity & Inclusion Policy';
          } else if (part === 'it-sales-manager') {
            part = 'IT Sales Manager';
          } else if (part === 'wellness-fitness') {
            part = 'Fitness-Wellness';
          } else if (part === 'digitalization') {
            part =
              useABGetVariant('digitalization', 'linkTitle') ||
              'Business Digitalization';
          }

          return (
            <div key={part} className={'nav_box'}>
              <NavLink
                tag={RRNavLink}
                to={path(index)}
                className={'link'}
                style={{
                  color:
                    index === parts.length - 1
                      ? 'var(--title-text-color)'
                      : '#7C7C87',
                  textTransform: part.includes(' ') ? 'none' : 'capitalize',
                }}
              >
                {part ? part : 'Perpetio'}
              </NavLink>
              <div
                className={'separator_box'}
                style={{
                  display: index === parts.length - 1 ? 'none' : 'block',
                }}
              >
                <ArrowsRight />
              </div>
            </div>
          );
        })}
    </div>
  );
};

export default Breadcrumbs;
