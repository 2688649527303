import React from 'react';
import LazyLoad from 'react-lazyload';

import Medium18px from '../../../UI/texts/18pxMedium';
import './support.scss';
import ResponsiveBox from '../../../UI/ResponsiveBox';
import BlockHeader from '../../../UI/texts/BlockHeader';

const Support = ({ data, cdnUrl, darkTheme = false }) => {
  return (
    <div
      className={
        darkTheme
          ? 'support-container support-container-dark'
          : 'support-container'
      }
    >
      <ResponsiveBox>
        <div className="support-block">
          <div className="support-block-text">
            <BlockHeader
              dangerouslySetInnerHTML={{
                __html: data.title,
              }}
            />
            <Medium18px
              style={{ color: 'var(--p-text-color)' }}
              dangerouslySetInnerHTML={{ __html: data.text }}
            />
          </div>
          {data.img ? (
            <LazyLoad once offset={100}>
              <img src={`${cdnUrl}${data.img}`} alt={data.title} />
            </LazyLoad>
          ) : null}
        </div>
      </ResponsiveBox>
    </div>
  );
};

export default Support;
