import React, { useCallback, useEffect, useState } from 'react';

import './showMoreText.scss';

const ShowMoreText = ({
  id = 'show-more-text',
  text,
  enabled,
  wordsCount = 29,
}) => {
  const [showMore, setShowMore] = useState(false);
  const [displayedText, setDisplayedText] = useState(text);

  const cropText = useCallback(
    (text, wordsCount) => {
      if (text.split(' ').length > wordsCount) {
        return (
          text.split(' ').slice(0, wordsCount).join(' ') +
          `...<span class="show-more-btn" data-show-more-id="${id}">show more</span>`
        );
      }
      return text;
    },
    [id],
  );

  useEffect(() => {
    if (!enabled) {
      setShowMore(true);
    }
  }, [enabled]);

  useEffect(() => {
    if (!enabled) {
      setDisplayedText(text);
      return;
    }
    if (showMore) {
      setDisplayedText(
        text +
          ` <span class="show-more-btn" data-show-more-id="${id}">show less</span>`,
      );
    } else {
      setDisplayedText(cropText(text, wordsCount));
    }
  }, [id, showMore, text, cropText, wordsCount, enabled]);

  useEffect(() => {
    const showMoreBtn = document.querySelector(
      `.show-more-btn[data-show-more-id="${id}"]`,
    );
    const handler = () => {
      setShowMore(prev => !prev);
    };
    if (showMoreBtn) {
      showMoreBtn.addEventListener('click', handler);
    }
    return () => {
      if (showMoreBtn) {
        showMoreBtn.removeEventListener('click', handler);
      }
    };
  }, [id, displayedText]);

  return (
    <div className="show-more">
      <div
        className="show-more-text"
        dangerouslySetInnerHTML={{ __html: displayedText }}
      />
    </div>
  );
};

export default ShowMoreText;
