import React, { useState, useEffect } from "react";
import { remoteStorageUrl } from "../../../../config";
import "./productLayout.scss";

export default ({ text }) => {
  const mediaMatch = window.matchMedia("(max-width: 575px)");
  const [matches, setMatches] = useState(mediaMatch.matches);

  useEffect(() => {
    const handler = (e) => setMatches(e.matches);
    mediaMatch.addListener(handler);
  }, [mediaMatch]);
  return matches ? (
    <div
      style={{
        backgroundImage: `url(${
          remoteStorageUrl + ("/resources/images/" + text.product_layout)
        })`,
      }}
      className="product-layout"
    />
  ) : null;
};
