import React, { useState, useEffect } from 'react';
import { Button } from 'reactstrap';
import FadeUp from '../../Animations/FadeUp';
import Modal from '../Modal/Modal';
import './careersInfo.scss';
import ResponsiveBox from '../../../components/UI/ResponsiveBox';

const CareersInfo = ({ text }) => {
  const [modal, setModal] = useState(false);
  const [position, setPosition] = useState('');
  const [response, setResponse] = useState('');
  const handleModal = (position) => {
    setModal(!modal);
    setPosition(position || '');
  };
  useEffect(() => {
    modal
      ? (document.body.style.overflow = 'hidden')
      : (document.body.style.overflow = 'unset');
  }, [modal]);

  return (
    <ResponsiveBox topGap={false}>
      <div className="careers-info">
        <div className="careers-info_Box">
          <FadeUp className={'title-box'}>
            <div className="title">{text.title}</div>
            <div
              className="text"
              dangerouslySetInnerHTML={{ __html: text.text }}
            />
          </FadeUp>
          <FadeUp className="careers-info__cv">
            <Button
              onClick={() => {
                setResponse('');
                handleModal('CV');
              }}
              className="careers-info-btn"
            >
              {text.btn}
            </Button>
            <p className="email-text">
              {text.label}
              <a href={`mailto:${text.email}`}>{text.email}</a>
            </p>
          </FadeUp>
        </div>
        <Modal
          show={modal}
          toggle={handleModal}
          position={position}
          response={response}
          handleResponse={setResponse}
        />
      </div>
    </ResponsiveBox>
  );
};

export default CareersInfo;
