import React from 'react';
import FadeUp from '../../Animations/FadeUp';
import Medium24px from '../../UI/texts/24pxMedium';
import Heavy16px from '../../UI/texts/16pxHeavy';
import ResponsiveBox from '../../UI/ResponsiveBox';
import './highPerformance.scss';
import Medium16px from '../../UI/texts/16pxMedium';
import BlockHeader from '../../UI/texts/BlockHeader';
import useABGetVariant from '../../../helpers/useABGetVariant';

const HighPerformance = ({ data, topGap = true, ...rest }) => {
  const { title, subtitle, items1, items2, btn1, btn2 } = data;

  const abLabel = useABGetVariant('digitalization', 'highPerformance');

  return (
    <div className="highPerformance-wrapper" {...rest}>
      <ResponsiveBox topGap={topGap}>
        <FadeUp>
          <BlockHeader
            dangerouslySetInnerHTML={{
              __html: title,
            }}
          />
        </FadeUp>
        {subtitle && (
          <FadeUp className={'highPerformance__subtitle'}>
            <Medium24px
              dangerouslySetInnerHTML={{
                __html: subtitle,
              }}
              style={{ textAlign: 'center', color: 'var(--p-text-color)' }}
            />
          </FadeUp>
        )}
        <div className="highPerformance__block-tiles">
          <div className="highPerformance__block-tiles__box">
            {btn1.href ? (
              <FadeUp className="button-box">
                <a href={btn1.href}>
                  <Heavy16px
                    style={{
                      color: 'white',
                      textAlign: 'center',
                      textTransform: 'uppercase',
                    }}
                    dangerouslySetInnerHTML={{
                      __html: btn1.title,
                    }}
                  />
                </a>
              </FadeUp>
            ) : (
              <FadeUp className="button-box">
                <Heavy16px
                  style={{
                    color: 'white',
                    textAlign: 'center',
                    textTransform: 'uppercase',
                  }}
                  dangerouslySetInnerHTML={{
                    __html: btn1.title,
                  }}
                />
              </FadeUp>
            )}
            {items1.map((item, index) => {
              return (
                <FadeUp key={index} className="content-box">
                  <BlockHeader
                    dangerouslySetInnerHTML={{
                      __html: item.title,
                    }}
                    variant="h3"
                  />
                  <Medium16px
                    dangerouslySetInnerHTML={{
                      __html: item.subtitle,
                    }}
                    style={{ color: 'var(--p-text-color)' }}
                  />
                </FadeUp>
              );
            })}
          </div>
          <div className="highPerformance__block-tiles__box">
            {btn2.href ? (
              <FadeUp className="button-box">
                <a href={btn2.href}>
                  <Heavy16px
                    style={{
                      color: 'white',
                      textAlign: 'center',
                      textTransform: 'uppercase',
                    }}
                    dangerouslySetInnerHTML={{
                      __html: abLabel || btn2.title,
                    }}
                  />
                </a>
              </FadeUp>
            ) : (
              <FadeUp className="button-box">
                <Heavy16px
                  style={{
                    color: 'white',
                    textAlign: 'center',
                    textTransform: 'uppercase',
                  }}
                  dangerouslySetInnerHTML={{
                    __html: abLabel || btn2.title,
                  }}
                />
              </FadeUp>
            )}
            {items2.map((item, index) => {
              return (
                <FadeUp key={index} className="content-box">
                  <BlockHeader
                    dangerouslySetInnerHTML={{
                      __html: item.title,
                    }}
                    variant="h3"
                  />
                  <Medium16px
                    dangerouslySetInnerHTML={{
                      __html: item.subtitle,
                    }}
                  />
                </FadeUp>
              );
            })}
          </div>
        </div>
      </ResponsiveBox>
    </div>
  );
};

export default HighPerformance;
