import React, { useEffect, useState } from 'react';
import { NavLink as RRNavLink } from 'react-router-dom';
import withRouter from '../../utils/withRouter';
import Logo from './Logo';
import text from '../../translation.json';
import {
  Container,
  Navbar,
  NavbarToggler,
  Collapse,
  Nav,
  NavbarBrand,
  NavLink,
  NavItem,
  Button,
} from 'reactstrap';
import './header.scss';
import DropDownBlog from './DropDownMenus/Blogs';
import CustomDropDown from '../CustomDropDown/index';
import NavBarDropdownMenu from './DropDownMenus/NavBarDropdownMenu';
import { remoteStorageUrl } from '../../config';
import { FooterSocialLinks } from '../Footer/Footer';

export const whiteNav = [
  '/use-cases/burn',
  '/use-cases/aob',
  '/use-cases/big-ticket',
];
const darkThemePages = ['/services/ui-ux', '/use-cases/aob'];

const Header = (props) => {
  const {
    open,
    setOpen,
    history: {
      location: { pathname },
    },
    notFound,
  } = props;
  const mediaMatch =
    typeof window === 'undefined'
      ? null
      : window.matchMedia('(max-width: 1024px)');

  const [matches] = useState(mediaMatch ? mediaMatch.matches : null);

  const [collapseClassNames, setCollapseClassNames] =
    useState('basic-navbar-nav');

  const [hamburgerActiveElement, setHamburgerActiveElement] = useState(null);

  const isDarkTheme = darkThemePages.includes(pathname);

  const getStyleFromPath = (style1, style2) => {
    return whiteNav.includes(pathname) && !open && (!scrollY || scrollY < 0)
      ? style1
      : style2;
  };

  const [scrollY, setScrollY] = useState(0);

  const safariFixedNavStyles = {
    zIndex: 9999,
    position: 'absolute',
    top: 0,
    left: 0,
  };
  useEffect(() => {
    const handleScroll = () => setScrollY(window.scrollY);
    window.addEventListener('scroll', handleScroll);
  }, [scrollY]);

  useEffect(() => {
    open
      ? (document.body.style.overflow = 'hidden')
      : (document.body.style.overflow = 'unset');
    open &&
      document.getElementsByClassName('huww-widget')[0] &&
      (document.getElementsByClassName('huww-widget')[0].style.display =
        'none');
    !open &&
      document.getElementsByClassName('huww-widget')[0] &&
      (document.getElementsByClassName('huww-widget')[0].style.display =
        'block');
  }, [open]);

  useEffect(() => {
    setOpen(false);
  }, [pathname]);

  return (
    <Container
      fluid
      className={isDarkTheme ? 'header dark-header' : 'header'}
      style={{ display: notFound ? 'none' : 'block' }}
    >
      <Navbar
        className={`main-navbar${scrollY > 0 ? ' scrolled' : ''}`}
        expand={true}
        light={!isDarkTheme}
        fixed={scrollY > -1 ? 'top' : undefined}
        style={scrollY < 0 ? safariFixedNavStyles : null}
      >
        <div className="nav-wrapper">
          <NavbarBrand href="/">
            <picture className="d-inline-block align-top">
              <Logo
                color={
                  isDarkTheme
                    ? 'white-char'
                    : getStyleFromPath('white-char', 'black-char')
                }
                colorB={getStyleFromPath('white-char', 'blue-char')}
              />
            </picture>
          </NavbarBrand>
          <NavbarToggler onClick={() => setOpen(!open)} />
          <Collapse
            id="basic-navbar-nav"
            className={collapseClassNames}
            isOpen={open}
            navbar
            timeout={300}
            onEntering={() =>
              setCollapseClassNames('basic-navbar-nav entering')
            }
            onEntered={() => setCollapseClassNames('basic-navbar-nav entered')}
            onExiting={() => setCollapseClassNames('basic-navbar-nav exiting')}
            onExited={() => setCollapseClassNames('basic-navbar-nav exited')}
          >
            <Button
              className="navbar-close-button"
              onClick={() => setOpen(false)}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="26"
                height="26"
                viewBox="0 0 26 26"
                fill="none"
              >
                <path
                  d="M13.3688 14.3913L13.0152 14.0378L12.6617 14.3913L5.61986 21.4331L5.61981 21.4331L5.61377 21.4393C5.54506 21.5105 5.46288 21.5672 5.37201 21.6062C5.28114 21.6453 5.18341 21.6658 5.08452 21.6667C4.98563 21.6675 4.88756 21.6487 4.79602 21.6112C4.70448 21.5738 4.62132 21.5185 4.5514 21.4486C4.48147 21.3786 4.42616 21.2955 4.38871 21.2039C4.35127 21.1124 4.33242 21.0143 4.33328 20.9154C4.33414 20.8165 4.35469 20.7188 4.39372 20.6279C4.43275 20.5371 4.48949 20.4549 4.56063 20.3862L4.56068 20.3862L4.56683 20.3801L11.6086 13.3383L11.9622 12.9848L11.6087 12.6312L4.57434 5.59566C4.44489 5.45525 4.37426 5.27039 4.37723 5.07925C4.38023 4.88584 4.45836 4.70118 4.5951 4.56436C4.73183 4.42753 4.91643 4.34926 5.10984 4.34612C5.30091 4.34302 5.48577 4.41348 5.62626 4.54276L12.6617 11.5782L13.0152 11.9317L13.3688 11.5782L20.4071 4.53983C20.5473 4.40581 20.7342 4.33172 20.9283 4.3334C21.1235 4.3351 21.3104 4.41343 21.4484 4.55152C21.5865 4.68961 21.6649 4.87641 21.6666 5.0717C21.6682 5.26571 21.5942 5.45267 21.4601 5.59284L14.4218 12.6312L14.0682 12.9847L14.4218 13.3383L21.4602 20.3767C21.5942 20.5168 21.6682 20.7038 21.6666 20.8978C21.6649 21.0931 21.5865 21.2799 21.4484 21.418C21.3104 21.5561 21.1235 21.6344 20.9283 21.6361C20.7342 21.6378 20.5473 21.5637 20.4071 21.4297L13.3688 14.3913Z"
                  fill="#333333"
                />
                <path
                  d="M13.3688 14.3913L13.0152 14.0378L12.6617 14.3913L5.61986 21.4331L5.61981 21.4331L5.61377 21.4393C5.54506 21.5105 5.46288 21.5672 5.37201 21.6062C5.28114 21.6453 5.18341 21.6658 5.08452 21.6667C4.98563 21.6675 4.88756 21.6487 4.79602 21.6112C4.70448 21.5738 4.62132 21.5185 4.5514 21.4486C4.48147 21.3786 4.42616 21.2955 4.38871 21.2039C4.35127 21.1124 4.33242 21.0143 4.33328 20.9154C4.33414 20.8165 4.35469 20.7188 4.39372 20.6279C4.43275 20.5371 4.48949 20.4549 4.56063 20.3862L4.56068 20.3862L4.56683 20.3801L11.6086 13.3383L11.9622 12.9848L11.6087 12.6312L4.57434 5.59566C4.44489 5.45525 4.37426 5.27039 4.37723 5.07925C4.38023 4.88584 4.45836 4.70118 4.5951 4.56436C4.73183 4.42753 4.91643 4.34926 5.10984 4.34612C5.30091 4.34302 5.48577 4.41348 5.62626 4.54276L12.6617 11.5782L13.0152 11.9317L13.3688 11.5782L20.4071 4.53983C20.5473 4.40581 20.7342 4.33172 20.9283 4.3334C21.1235 4.3351 21.3104 4.41343 21.4484 4.55152C21.5865 4.68961 21.6649 4.87641 21.6666 5.0717C21.6682 5.26571 21.5942 5.45267 21.4601 5.59284L14.4218 12.6312L14.0682 12.9847L14.4218 13.3383L21.4602 20.3767C21.5942 20.5168 21.6682 20.7038 21.6666 20.8978C21.6649 21.0931 21.5865 21.2799 21.4484 21.418C21.3104 21.5561 21.1235 21.6344 20.9283 21.6361C20.7342 21.6378 20.5473 21.5637 20.4071 21.4297L13.3688 14.3913Z"
                  stroke="black"
                />
                <path
                  d="M13.3688 14.3913L13.0152 14.0378L12.6617 14.3913L5.61986 21.4331L5.61981 21.4331L5.61377 21.4393C5.54506 21.5105 5.46288 21.5672 5.37201 21.6062C5.28114 21.6453 5.18341 21.6658 5.08452 21.6667C4.98563 21.6675 4.88756 21.6487 4.79602 21.6112C4.70448 21.5738 4.62132 21.5185 4.5514 21.4486C4.48147 21.3786 4.42616 21.2955 4.38871 21.2039C4.35127 21.1124 4.33242 21.0143 4.33328 20.9154C4.33414 20.8165 4.35469 20.7188 4.39372 20.6279C4.43275 20.5371 4.48949 20.4549 4.56063 20.3862L4.56068 20.3862L4.56683 20.3801L11.6086 13.3383L11.9622 12.9848L11.6087 12.6312L4.57434 5.59566C4.44489 5.45525 4.37426 5.27039 4.37723 5.07925C4.38023 4.88584 4.45836 4.70118 4.5951 4.56436C4.73183 4.42753 4.91643 4.34926 5.10984 4.34612C5.30091 4.34302 5.48577 4.41348 5.62626 4.54276L12.6617 11.5782L13.0152 11.9317L13.3688 11.5782L20.4071 4.53983C20.5473 4.40581 20.7342 4.33172 20.9283 4.3334C21.1235 4.3351 21.3104 4.41343 21.4484 4.55152C21.5865 4.68961 21.6649 4.87641 21.6666 5.0717C21.6682 5.26571 21.5942 5.45267 21.4601 5.59284L14.4218 12.6312L14.0682 12.9847L14.4218 13.3383L21.4602 20.3767C21.5942 20.5168 21.6682 20.7038 21.6666 20.8978C21.6649 21.0931 21.5865 21.2799 21.4484 21.418C21.3104 21.5561 21.1235 21.6344 20.9283 21.6361C20.7342 21.6378 20.5473 21.5637 20.4071 21.4297L13.3688 14.3913Z"
                  stroke="black"
                  strokeOpacity="0.2"
                />
              </svg>
            </Button>
            <div className="hamburger-menu-container">
              <div className="hamburger-menu-container-tablet">
                <NavbarBrand href="/" className="navbar-hamburger-brand">
                  <picture className="d-inline-block align-top">
                    <Logo
                      color={getStyleFromPath('white-char', 'black-char')}
                      colorB={getStyleFromPath('white-char', 'blue-char')}
                    />
                  </picture>
                </NavbarBrand>

                <Nav className="custom-nav" navbar>
                  <div className="hamburger-menu-padding" />
                  <NavItem onClick={open ? () => setOpen(!open) : null}>
                    <NavLink
                      className={getStyleFromPath('white', 'black')}
                      tag={RRNavLink}
                      to="/"
                    >
                      {text.menu.item1}
                    </NavLink>
                  </NavItem>

                  <CustomDropDown
                    openHamburger={open}
                    setOpenHamburger={setOpen}
                    link={null}
                    linkTitle={text.menu.item2}
                    pathname={pathname}
                    isDarkTheme={isDarkTheme}
                    setActiveHamburgerElement={(shouldSwitch) =>
                      setHamburgerActiveElement(
                        shouldSwitch ? 'services' : null,
                      )
                    }
                    isActiveInHamburger={hamburgerActiveElement === 'services'}
                    isActiveForTablet={
                      hamburgerActiveElement === 'services' ||
                      hamburgerActiveElement === null
                    }
                    name="services"
                  >
                    <NavBarDropdownMenu
                      rows={text.services.rows}
                      cdnUrl={`${remoteStorageUrl}/public/services`}
                      asDropdown={!matches}
                      closeMenu={() => setOpen(false)}
                      className="navbar-services-dropdown"
                    />
                  </CustomDropDown>

                  <CustomDropDown
                    openHamburger={open}
                    setOpenHamburger={setOpen}
                    link={null}
                    linkTitle={text.menu.solutions}
                    pathname={pathname}
                    isDarkTheme={isDarkTheme}
                    setActiveHamburgerElement={(shouldSwitch) =>
                      setHamburgerActiveElement(
                        shouldSwitch ? 'solutions' : null,
                      )
                    }
                    isActiveInHamburger={hamburgerActiveElement === 'solutions'}
                    name="solutions"
                  >
                    <NavBarDropdownMenu
                      blocks={text.services.solutions.blocks}
                      cdnUrl={`${remoteStorageUrl}/public/solutions`}
                      asDropdown={!matches}
                      closeMenu={() => setOpen(false)}
                    />
                  </CustomDropDown>

                  <CustomDropDown
                    openHamburger={open}
                    setOpenHamburger={setOpen}
                    link={null}
                    linkTitle={text.menu.industries}
                    pathname={pathname}
                    isDarkTheme={isDarkTheme}
                    setActiveHamburgerElement={(shouldSwitch) =>
                      setHamburgerActiveElement(
                        shouldSwitch ? 'industries' : null,
                      )
                    }
                    isActiveInHamburger={
                      hamburgerActiveElement === 'industries'
                    }
                    name="industries"
                  >
                    <NavBarDropdownMenu
                      blocks={text.services.industries.blocks}
                      cdnUrl={`${remoteStorageUrl}/public/industries`}
                      asDropdown={!matches}
                      closeMenu={() => setOpen(false)}
                    />
                  </CustomDropDown>

                  <NavItem onClick={open ? () => setOpen(!open) : null}>
                    <NavLink
                      className={getStyleFromPath('white', 'black')}
                      tag={RRNavLink}
                      to="/use-cases"
                      dangerouslySetInnerHTML={{
                        __html: text.menu.cases,
                      }}
                    />
                  </NavItem>

                  <CustomDropDown
                    openHamburger={open}
                    setOpenHamburger={setOpen}
                    link={null}
                    linkTitle={text.menu.company}
                    pathname={pathname}
                    isDarkTheme={isDarkTheme}
                    setActiveHamburgerElement={(shouldSwitch) =>
                      setHamburgerActiveElement(shouldSwitch ? 'company' : null)
                    }
                    isActiveInHamburger={hamburgerActiveElement === 'company'}
                    name="company"
                  >
                    <NavBarDropdownMenu
                      blocks={text.services.company.blocks}
                      cdnUrl={`${remoteStorageUrl}/public/company`}
                      asDropdown={!matches}
                      closeMenu={() => setOpen(false)}
                      className="navbar-company-dropdown"
                    />
                  </CustomDropDown>

                  <CustomDropDown
                    openHamburger={open}
                    setOpenHamburger={setOpen}
                    link={'https://perpet.io/blog/'}
                    linkTitle={text.menu.item5}
                    pathname={pathname}
                  >
                    <DropDownBlog />
                  </CustomDropDown>

                  <NavItem onClick={open ? () => setOpen(!open) : null}>
                    <NavLink
                      className={getStyleFromPath('white', 'black')}
                      tag={RRNavLink}
                      to="/referral"
                      dangerouslySetInnerHTML={{
                        __html: text.menu.referral,
                      }}
                    />
                  </NavItem>

                  <NavItem
                    className="nav-item-special"
                    onClick={open ? () => setOpen(!open) : null}
                  >
                    <NavLink
                      tag={RRNavLink}
                      className={`special`}
                      to="/contact"
                    >
                      {text.menu.item6}
                    </NavLink>
                  </NavItem>

                  <div className="hamburger-menu-padding" />
                </Nav>
                <div className="hamburger-submenu-tablet">
                  <div className="hamburger-menu-padding" />
                  {(hamburgerActiveElement === 'services' ||
                    hamburgerActiveElement === null) && (
                    <NavBarDropdownMenu
                      rows={text.services.rows}
                      cdnUrl={`${remoteStorageUrl}/public/services`}
                      asDropdown={false}
                    />
                  )}
                  {hamburgerActiveElement === 'solutions' && (
                    <NavBarDropdownMenu
                      blocks={text.services.solutions.blocks}
                      cdnUrl={`${remoteStorageUrl}/public/solutions`}
                      asDropdown={false}
                    />
                  )}
                  {hamburgerActiveElement === 'industries' && (
                    <NavBarDropdownMenu
                      blocks={text.services.industries.blocks}
                      cdnUrl={`${remoteStorageUrl}/public/industries`}
                      asDropdown={false}
                    />
                  )}
                  {hamburgerActiveElement === 'company' && (
                    <NavBarDropdownMenu
                      blocks={text.services.company.blocks}
                      cdnUrl={`${remoteStorageUrl}/public/company`}
                      asDropdown={false}
                    />
                  )}
                </div>
              </div>

              <FooterSocialLinks />
            </div>
          </Collapse>
          <Button color="primary" className="contactUsBtn">
            <NavLink tag={RRNavLink} to="/contact">
              {text.menu.item6}
            </NavLink>
          </Button>
        </div>
      </Navbar>
    </Container>
  );
};

export default withRouter(Header);
