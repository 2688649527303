import React from "react";
import Title from "../../components/PerpetioTitle";
import text from "../../translation.json";
import "./factsAboutUs.scss";
import FadeUp from "../Animations/FadeUp";
import ResponsiveBox from "../../components/UI/ResponsiveBox";

const FactsAboutUs = (props) => {
  return (
    <ResponsiveBox >
      {" "}
      <div className="facts">
        <Title
          title={text.about_us.sections.facts.title}
          subtitle={text.about_us.sections.facts.subtitle}
        ></Title>
        <FadeUp className="article-box" >
          <p
            className="multidisc-box-article"
            dangerouslySetInnerHTML={{
              __html: text.about_us.sections.facts.article,
            }}
          />
        </FadeUp>
        <FadeUp className="facts-block-container" >
          {text.about_us.sections.facts.blocks.map((block, index) => {
            return (
              <div key={index} className="fact-block">
                <span dangerouslySetInnerHTML={{ __html: block.qtt }}></span>
                <p dangerouslySetInnerHTML={{ __html: block.text }}></p>
              </div>
            );
          })}
        </FadeUp>
      </div>
    </ResponsiveBox>
  );
};
export default FactsAboutUs;
