import React from 'react';
import ReactDOM from 'react-dom/client';
import { App } from './components/App';
import * as serviceWorker from './serviceWorker';
import { BrowserRouter as Router } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.css';
import '../public/robots.txt';
import { HelmetProvider } from 'react-helmet-async';

ReactDOM.hydrateRoot(
  document.getElementById('root'),
  <Router>
    <HelmetProvider>
      <App />
    </HelmetProvider>
  </Router>,
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
