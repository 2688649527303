import React from 'react';
import './steps.scss';
import ResponsiveBox from '../../components/UI/ResponsiveBox';
import FadeUp from '../../components/Animations/FadeUp';
import { BlueUppercase } from '../../components/UI/texts/BlueUppercase';

const Steps = ({ data }) => {
  const { blue_title, subtitle, items } = data;
  return (
    <section className="steps-container">
      <ResponsiveBox>
        <FadeUp>
          <BlueUppercase>{blue_title}</BlueUppercase>
          <h2>{subtitle}</h2>
          {items.map(({ text }, index) => (
            <div key={index} className="steps-item">
              <span>{index + 1}</span>
              <p>{text}</p>
            </div>
          ))}
        </FadeUp>
      </ResponsiveBox>
    </section>
  );
};

export default Steps;
