import React, { useState } from 'react';
import FadeUp from '../Animations/FadeUp';
import './FAQ.scss';
import Title from '../../components/PerpetioTitle';
import text from '../../translation.json';
import { Row } from 'reactstrap';
import ResponsiveBox from '../../components/UI/ResponsiveBox';
import BlockHeader from '../UI/texts/BlockHeader';

const startLimit = 4;

const AboutFAQ = () => {
  const [activeTab, setActiveTab] = useState(0);
  const [showMoreBtn, setShowMoreBtn] = useState(true);
  const [limit] = useState(startLimit);
  const [loadedFAQArray, setLoadedFAQArray] = useState(
    text.about_us.sections.FAQ.list.slice(0, limit),
  );

  const handleActiveTab = (id) => {
    setActiveTab(id);
  };

  const handleMoreBtn = () => {
    setLoadedFAQArray(text.about_us.sections.FAQ.list);
    setShowMoreBtn(false);
  };
  const handleLoadLessBtn = () => {
    setLoadedFAQArray(text.about_us.sections.FAQ.list.slice(0, limit));
    setShowMoreBtn(true);
  };

  return (
    <div className="about-faq-container">
      <ResponsiveBox>
        <div className="about-faq">
          <div className="title-box">
            <Title
              title={text.about_us.sections.FAQ.title}
              subtitle={text.about_us.sections.FAQ.subtitle}
            />
          </div>
          <FadeUp className="list-box">
            {loadedFAQArray.map((item) => {
              return (
                <div key={item.id} className="list-item-box">
                  <div
                    key={item.id}
                    className="item-head"
                    onClick={() => handleActiveTab(item.id)}
                  >
                    <span className="list-item-number">
                      {item.id + 1 >= 10 ? `${item.id + 1}` : `0${item.id + 1}`}
                    </span>
                    <div className="item-text-box">
                      <BlockHeader
                        variant="h3"
                        className={
                          activeTab === item.id
                            ? 'list-item-title active'
                            : 'list-item-title'
                        }
                        dangerouslySetInnerHTML={{ __html: item.title }}
                      />
                      {item.subtitle && item.list ? (
                        <div
                          className={
                            activeTab === item.id ? 'item-list-box' : 'hidden'
                          }
                        >
                          <span
                            className="item-list-box-title"
                            dangerouslySetInnerHTML={{ __html: item.subtitle }}
                          />
                          <ul>
                            {item.list.map((elem) => {
                              return (
                                <li key={elem} className="item-list-elem">
                                  {elem}
                                </li>
                              );
                            })}
                          </ul>
                        </div>
                      ) : (
                        <p
                          className={activeTab === item.id ? 'slide' : 'hidden'}
                          dangerouslySetInnerHTML={{ __html: item.text }}
                        />
                      )}
                    </div>
                    <span className="list-item-mark">
                      {activeTab === item.id ? '-' : '+'}
                    </span>
                  </div>
                </div>
              );
            })}
            {showMoreBtn ? (
              <Row className="margin loadMoreBox">
                <div onClick={handleMoreBtn}>Load More</div>
              </Row>
            ) : (
              <Row className="margin loadMoreBox">
                <div onClick={handleLoadLessBtn}>Load Less</div>
              </Row>
            )}
          </FadeUp>
        </div>
      </ResponsiveBox>
    </div>
  );
};
export default AboutFAQ;
