import React, { useState, useEffect } from 'react';
import { Button, Col, InputGroup, Input } from 'reactstrap';
import ReCAPTCHA from 'react-google-recaptcha';

import {
  checkEmailPattern,
  checkNamePattern,
  checkLength,
} from '../../../helpers/validationHelpers';
import Upload from '../uploadButton/uploadButton';
import './form.scss';
import FormError from '../../FormError/FormError';
import { getCaptchaKey, remoteStorageUrl } from '../../../config';

import { lessThanOneHourAgo, saveDateTime } from '../../../utils/localStorage';

const loadingIcon = `${remoteStorageUrl}/resources/icons/loading.svg`;

const Form = (props) => {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [file, setFile] = useState(null);
  const [invalid, setInvalid] = useState(false);
  const [loading, setLoading] = useState(false);
  const [recaptchaToken, setRecaptchaToken] = useState('');
  const [sent, setSent] = useState(false);
  const { position, handleResponse, text, handleModal, handleSuccessRes } =
    props;

  useEffect(() => {
    const sentDate = localStorage.getItem('sentCVDate');
    if (sentDate) {
      const compareTime = lessThanOneHourAgo(sentDate);
      setSent(compareTime);
    }
  }, []);
  const getErrorClass = (condition) => (condition ? ' error' : '');

  const handleOnClick = (e) => {
    e.preventDefault();
    setLoading(true);
    if (recaptchaToken) {
      submitData(recaptchaToken);
    } else {
      setLoading(false);
    }
  };
  const handleRecaptchaChange = (value) => {
    setRecaptchaToken(value);
  };
  const submitData = (token) => {
    if (
      checkNamePattern(firstName) &&
      checkNamePattern(lastName) &&
      message &&
      checkEmailPattern(email)
    ) {
      if (sent) {
        handleResponse(text.hourBan);
        handleModal && handleModal();
        setLoading(false);
        handleSuccessRes(false);
      } else {
        setLoading(true);
        setSent(true);
        const date = new Date().toISOString();
        saveDateTime(date, 'sentCVDate');
        const data = new FormData();
        data.append('subject', position);
        data.append('email', email);
        data.append('firstName', firstName);
        data.append('lastName', lastName);
        data.append('message', message.trim());
        data.append('formType', 'CAREERS');
        data.append('g-recaptcha-response', token);
        data.append('files', file);

        fetch(
          `${window && window.location.protocol}//${
            window && window.location.host
          }/api/v1/send`,
          {
            method: 'POST',
            body: data,
          },
        )
          .then((res) => res.json())
          .then((result) => {
            setLoading(false);

            const { success } = result;
            if (success) {
              handleResponse(text.success_response);
              handleSuccessRes(true);
            } else {
              handleResponse(text.bad_response);
              handleSuccessRes(false);
            }
            handleModal && handleModal();
          })
          .catch(() => {
            setLoading(false);
            handleResponse(text.bad_response);
            handleSuccessRes(false);
            handleModal && handleModal();
          });
      }
    } else {
      setInvalid(true);
      setLoading(false);
    }
  };
  return (
    <form className="contact-form_careers row" onSubmit={handleOnClick}>
      <label
        htmlFor="cc-num"
        style={{ visibility: 'hidden', position: 'absolute' }}
      >
        credit card HubspotCollectedFormsWorkaround
        https://community.hubspot.com/t5/APIs-Integrations/How-to-stop-collected-forms-from-collecting-a-form/m-p/299172#M28102
      </label>
      <input
        name="cc-num"
        style={{ visibility: 'hidden', position: 'absolute' }}
        required=""
        value="HubspotCollectedFormsWorkaround"
        id="cc-num"
      />
      <Col className="mb-4 p-0" sm={12}>
        <InputGroup
          className={`contact-form-group${getErrorClass(
            invalid && !checkNamePattern(firstName),
          )}`}
        >
          <Input
            invalid={invalid && !checkNamePattern(firstName)}
            onChange={({ target: { value } }) => setFirstName(value)}
            className="contact-form-input"
            type="name"
            name="firstName"
            id="firstName"
            placeholder={text.placeholders.firstName}
            required
          />
        </InputGroup>
        {invalid && !checkNamePattern(firstName) ? (
          <FormError content="first name" />
        ) : null}
      </Col>
      <Col className="mb-4 p-0" sm={12}>
        <InputGroup
          className={`contact-form-group${getErrorClass(
            invalid && !checkNamePattern(lastName),
          )}`}
        >
          <Input
            invalid={invalid && !checkNamePattern(lastName)}
            onChange={({ target: { value } }) => setLastName(value)}
            className="contact-form-input"
            type="name"
            name="lastName"
            id="lastName"
            placeholder={text.placeholders.lastName}
            required
          />
        </InputGroup>
        {invalid && !checkNamePattern(lastName) ? (
          <FormError content="last name" />
        ) : null}
      </Col>
      <Col className="mb-4 p-0" sm={12}>
        <InputGroup
          className={`contact-form-group${getErrorClass(
            invalid && !checkEmailPattern(email),
          )}`}
        >
          <Input
            invalid={invalid && !checkEmailPattern(email)}
            onChange={({ target: { value } }) => setEmail(value)}
            className="contact-form-input"
            type="email"
            name="email"
            placeholder={text.placeholders.email}
            required
          />
        </InputGroup>
        {invalid && !checkEmailPattern(email) ? (
          <FormError content="email" />
        ) : null}
      </Col>
      <Col className="p-0" sm={12}>
        <InputGroup
          className={`contact-form-group message${getErrorClass(
            invalid && !checkLength(message),
          )}`}
        >
          <Input
            invalid={invalid && !checkLength(message)}
            rows={4}
            onChange={({ target: { value } }) => setMessage(value)}
            className="contact-form-textarea message"
            type="textarea"
            name="text"
            placeholder={text.placeholders.area}
          />
        </InputGroup>
        {invalid && !checkLength(message) ? (
          <FormError content="message" />
        ) : null}
      </Col>
      <Col className="p-0 pt-4 pb-4" sm={12}>
        <Upload setFile={setFile} />
        {/* {invalid && !file ? <div className="form-error file-invalid">File is not uploaded</div> : null} */}
      </Col>
      <Col className="p-0 pt-2 pb-3 col-sm-12">
        <ReCAPTCHA
          sitekey={getCaptchaKey()}
          size="normal"
          onChange={handleRecaptchaChange}
        />
      </Col>
      {loading ? (
        <Button
          className="submit-btn"
          color="primary"
          disabled={sent || loading || !recaptchaToken}
        >
          {loading ? (
            <img
              className="loading-icon"
              src={loadingIcon}
              alt="loading-icon"
            />
          ) : (
            text.btn
          )}
        </Button>
      ) : (
        <input
          type="submit"
          disabled={sent || loading || !recaptchaToken}
          className="submit-btn"
          value={text.btn}
        />
      )}
    </form>
  );
};

export default Form;
