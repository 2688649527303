import React from "react";
import Positions from "../../components/Careers/Positions/Positions";
import CareersInfo from "../../components/Careers/CareersInfo/CareersInfo";
import data from "../../translation.json";
import { Helmet } from "react-helmet-async";
import { useLocation } from "react-router-dom";
import NewMain from "../../components/NewMain/NewMain";
import ResponsiveBoxHead from "../../components/UI/ResponsiveBoxHead";

const Careers = () => {
  const location = useLocation();
  return (
    <div className="careers">
      <Helmet>
        <title>Careers | Perpetio</title>
        <meta
          name="description"
          content="Perpetio is hiring! We are looking for a skilled professionals to join our team in Lviv. Sounds interesting? Check out the requirements and send your CV!"
        />
        <link
          rel="canonical"
          href={`${process.env.REACT_APP_SITE_URL}${location.pathname}`}
        />
      </Helmet>
      <div>
        <ResponsiveBoxHead
          // style={{
          //   background:
          //     "linear-gradient(134.07deg, #F3F2FF 9.9%, #E8FFFE 68.31%, #FFFFFF 100.53%)",
          // }}
        >
          <div className="portfolio_header">
            <NewMain
              title={data.careers.main.title}
              text={data.careers.main.text}
            />
          </div>
        </ResponsiveBoxHead>
        <Positions text={data.careers.items} />
        <CareersInfo text={data.careers.info} />
        {/* <Serapator /> */}
      </div>
    </div>
  );
};

export default Careers;
