import React from 'react';

import './carryingServices.scss';
import FadeUp from '../../Animations/FadeUp';
import ResponsiveBox from '../../UI/ResponsiveBox';
import Medium16px from '../../UI/texts/16pxMedium';
import LazyLoad from 'react-lazyload';
import { remoteStorageUrl } from '../../../config';
import BlockHeader from '../../UI/texts/BlockHeader';

const CarryingServices = ({
  title,
  items,
  bottomGap = true,
  topGap = false,
  cdnUrl = `${remoteStorageUrl}/resources/images/services_page/cross-platform/`,
}) => {
  return (
    <ResponsiveBox topGap={topGap} bottomGap={bottomGap}>
      <div className="our-services">
        <FadeUp>
          <BlockHeader
            dangerouslySetInnerHTML={{
              __html: title,
            }}
          />
        </FadeUp>

        <div className="our-services-wrapper">
          {items.map((item, index) => {
            return (
              <FadeUp
                key={index}
                className="our-services-item"
                transition={`all 700ms ease-in-out 0.${index + 4}s`}
                style={{ flexBasis: '25%' }}
              >
                {item.img ? (
                  <LazyLoad
                    once
                    offset={100}
                    style={{
                      marginBottom: '1.5rem',
                    }}
                  >
                    <img src={`${cdnUrl}${item.img}`} alt={item.img} />
                  </LazyLoad>
                ) : null}
                <BlockHeader
                  dangerouslySetInnerHTML={{ __html: item.title }}
                  variant="h3"
                />
                <Medium16px>{item.text}</Medium16px>
              </FadeUp>
            );
          })}
        </div>
      </div>
    </ResponsiveBox>
  );
};

export default CarryingServices;
