import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import './productFeatureBlock.scss';
import { Image } from '../../../Image/Image';

const ProductFeatureBlock = ({ text }) => {
  return (
    <Container className="product-features-block-aob my-sm-3 my-5" fluid>
      {text.product_features_block.map((item, i) => (
        <Row
          key={i}
          className={`margin justify-content-space-between align-items-center product-features-block-row flex-sm-row flex-column ${
            i % 2 ? null : ' flex-sm-row-reverse'
          }`}
        >
          <Col
            className={`product-features-block-col product-features-block-image text-center mb-5 mt-sm-0 mt-3 ${
              i % 2 ? ' text-sm-left' : ' text-sm-right'
            }`}
            xs={12}
            sm={6}
          >
            <Image
              className="product-features-img-aob"
              src={`aob/features/${item.image}`}
            />
          </Col>
          <Col className="product-features-block-col" xs={12} sm={6}>
            <h3 className="product-features-title">{item.title}</h3>
            <p className="product-features-text">{item.text}</p>
          </Col>
        </Row>
      ))}
    </Container>
  );
};

export default ProductFeatureBlock;
