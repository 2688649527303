import React, { useState, useEffect } from 'react';
import { Container, Row, Col } from 'reactstrap';
import './productCollaboration.scss';

const ProductCollaboration = ({ text }) => {
  const mediaMatch =
    typeof window === 'undefined'
      ? null
      : window.matchMedia('(max-width: 575px)');
  const [matches, setMatches] = useState(mediaMatch && mediaMatch.matches);
  useEffect(() => {
    const handler = (e) => setMatches(e.matches);
    mediaMatch && mediaMatch.addListener(handler);
  });
  const lists = matches
    ? text.product_collaboration.mLists
    : text.product_collaboration.lists;
  return (
    <Container className="product-collaboration" fluid>
      <Row className="product-collaboration-row margin">
        <Col className="p-0" sm={12}>
          <h3 className="list-title mb-4">
            {text.product_collaboration.title}
          </h3>
        </Col>
        <Row className="product-collaboration-lists justify-content-between">
          {lists.map((list, index) => (
            <Col className="p-0 p-sm-3" key={index} xs={6} sm={3}>
              <ul className="list mb-5 mb-sm-0">
                {list.map((item, index) => (
                  <li key={index} dangerouslySetInnerHTML={{ __html: item }} />
                ))}
              </ul>
            </Col>
          ))}
        </Row>
      </Row>
    </Container>
  );
};

export default ProductCollaboration;
