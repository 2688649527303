import React, { Fragment } from 'react';
import { Helmet } from "react-helmet-async";
import { useLocation } from "react-router-dom";
import ProductMain from '../../components/Projects/Shrink/ProductMain/ProductMain';
import ProductOverview from '../../components/Projects/Shrink/ProductOverview/ProductOverview';
import ProductCollaboration from '../../components/Projects/Shrink/ProductCollaboration/ProductCollaboration';
import ProductFeatureBlock from '../../components/Projects/Shrink/ProductFeatureBlock/ProductFeatureBlock';
import ProductDescription from '../../components/Projects/Shrink/ProductDescription/ProductDescription';
import ProductSlider from '../../components/Projects/Shrink/ProductSlider/ProductSlider';
import ProductResult from '../../components/Projects/Shrink/ProductResult/ProductResult';


// eslint-disable-next-line import/no-anonymous-default-export
export default ({data}) => {
  const location = useLocation();
  return (
    <Fragment>
      <Helmet>
        <title>Shrink| Perpetio</title>
        <meta name="description" content="We are the reliable mobile app development company you were looking for! Boost your business with a slick mobile app from Perpetio." />
        <link rel="canonical" href={`${process.env.REACT_APP_SITE_URL}${location.pathname}`} />
      </Helmet>
      <div className="shrink-wrapper">
        <ProductMain text={data} />
        <ProductOverview text={data} />
        <ProductFeatureBlock text={data} />
        <ProductDescription text={data} />
        <ProductSlider text={data.product_slides} />
        <ProductResult text={data} />
        <ProductCollaboration text={data} />
      </div>
    </Fragment>
  );
};