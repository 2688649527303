import React  from 'react';
import { Container } from 'reactstrap';
import './productSlider.scss';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Image } from '../../../Image/Image';

const ProductSlider = ({text}) => {
    const settings = {
        className: "center",
        centerMode: true,
        infinite: true,
        autoplay: true,
        draggable: true,
        autoplaySpeed: 2000,
        centerPadding: "60px",
        slidesToShow: 4,
        initialSlide: 2,
        swipeToSlide: true,
        touchThreshold: 50,
        buttons: false,
        responsive: [
            {
              breakpoint: 575,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                initialSlide: 0,
                centerPadding: "60px"
              }
            }
        ]
    };
    return (
        <Container className="product-slider p-0" fluid>
            <Slider {...settings}>
                {text.images.map((slide,i) => (
                    <div key={i}>
                        {/* <img 
                            className="product-slider-img" 
                            src={require(`../../../../resources/images/shrink/slides/${slide}`)} 
                            alt="slide" 
                        /> */}
                        <Image className="product-slider-img" src={`shrink/slides/${slide}`} />
                    </div>
                ))}
            </Slider>
        </Container>
    );
};

export default ProductSlider;