/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import './privacyPolicyMain.scss';
import ResponsiveBoxHead from '../UI/ResponsiveBoxHead';
import NewMain from '../NewMain/NewMain';
import ResponsiveBox from '../UI/ResponsiveBox';

const EnvironmentalPolicy = () => (
  <div className="privacy-policy">
    <ResponsiveBoxHead style={{ paddingBottom: '2.5rem' }}>
      <div className="privacy-policy_header">
        <NewMain
          title="Environmental & Sustainability <span>Policy</span>"
          text="Last updated: October 2023"
        />
      </div>
    </ResponsiveBoxHead>
    <ResponsiveBox topGap={false}>
      <div className="description">
        <h3>PREAMBLE</h3>
        <p>
          Perpetio OÜ (hereinafter "the Company", "we" or "our") is committed to
          protecting the environment and promoting sustainable development
          through action. Our Environmental & Sustainability Policy (the
          "Policy") is to ensure, as far as possible, that all operations are
          conducted with a commitment to reduce waste and promote sustainable
          development, thereby protecting the environment and reducing our
          impact on it. All team members are obliged to perform their duties
          with care for the environment. In the event of any environmental
          incident related to our work, we require that details are promptly and
          appropriately reported to the CEO, who will investigate and take
          immediate action to remedy any damage and avoid recurrence. All
          vendors working on behalf of the Company are required to comply with
          environmental standards that are fully consistent with the Company's
          standards.
        </p>
        <h3>OUR PURPOSES</h3>
        <p>
          In accordance with the Policy, we have developed the following set of
          purposes as a sound basis for implementing practices that are designed
          to implement the Policy in compliance with all applicable laws,
          regulations, and codes of practice, including our moral and
          contractual obligations on the issues identified in the Policy. We set
          the following purposes: (1) to sort and transport as much paper,
          cardboard, plastic, and metal waste generated in the office for
          recycling; (2) to reduce paper usage by increasing reliance on email
          and electronic documentation; (3) to decrease energy consumption by
          monitoring energy usage and educating staff on basic household
          management practices; (4) to minimize vehicle emissions by planning
          routes to reduce mileage and encouraging our employees and colleagues
          to use public transportation; (5) swift response to accidents or
          incidents that have a potential to threaten the environment on
          projects involving our team; (6) to ensure that all staff are fully
          aware of our Policy and are committed to implementing and improving
          it; (7) to provide training for all staff, as appropriate, to enable
          them to carry out their job functions in a manner that shows care for
          the environment; (8) to carry out environmental audits when required;
          (9) to review, report on, and to continually strive to improve our
          sustainability performance; (10) in implementing this Policy, the
          Company will focus on action to conserve resources and energy, to
          minimise emissions into air or water and onto land and to increase
          recycling rates; (11) the Company will also seek to influence
          legislative developments and improve public understanding of
          environmental and sustainability matters concerning the business; (12)
          and others.
        </p>
        <h3>RESPONSIBILITY</h3>
        <p>
          The CEO is responsible for reviewing, approving and achieving the
          objectives of this policy, and for designating a responsible person to
          investigate any reported violations of this policy. Managers are
          responsible for administering, communicating, implementing and
          enforcing this Policy on behalf of the CEO, and for reporting
          incidents that they believe to be in violation of this Policy and for
          acting in accordance with this Policy. All team members are
          responsible for complying with this Policy by reporting any potential
          activity that violates this Policy to the CEO.
        </p>
        <h3>OTHER</h3>
        <p>
          Progress towards achieving the objectives of the Policy will be
          monitored through a number of means, including: (1) an annual
          management review of this Policy and any related
          environmental/sustainability procedures; (2) monitoring of the Policy
          objectives with their ongoing development. This Policy has been
          approved by the CEO of Perpetio OÜ, if you would like to receive
          further information regarding this Policy, please contact the CEO of
          Perpetio OÜ.
        </p>
      </div>
    </ResponsiveBox>
  </div>
);

export default EnvironmentalPolicy;
