import React from 'react';

import './specialists.scss';
import FadeUp from '../../../Animations/FadeUp';
import BlockHeader from '../../../UI/texts/BlockHeader';

const Specialists = ({ data, cdnUrl }) => {
  return (
    <div className="specialists-wrapper">
      {data.map((item) => (
        <FadeUp key={item.text}>
          <div className="specialists-item">
            <img src={`${cdnUrl}${item.img}`} alt={item.text} />
            <BlockHeader
              dangerouslySetInnerHTML={{ __html: item.text }}
              variant="h3"
            />
          </div>
        </FadeUp>
      ))}
    </div>
  );
};

export default Specialists;
