import React, { useState } from 'react';
import Flickity from 'react-flickity-component';

import BlockHeader from '../../UI/texts/BlockHeader';
import SvgCircleArrow from '../../icons/circle_arrow';
import FadeUp from '../../Animations/FadeUp';
import './headSlider.scss';

const HeadSlider = ({ data, cdnUrl }) => {
  const [swiperRef, setSwiperRef] = useState(null);

  const flickityOptions = {
    initialIndex: 2,
    cellAlign: 'center',
    contain: true,
    pageDots: false,
    wrapAround: true,
    autoPlay: 3000,
    freeScroll: true,
    accessibility: true,
    setGallerySize: true,
    resize: true,
    imagesLoaded: true,
    percentPosition: false,
    rightToLeft: false,
    draggable: true,
    lazyLoad: false,
    bgLazyLoad: false,
    pauseAutoPlayOnHover: false,
    adaptiveHeight: true,
    dragThreshold: 3,
    selectedAttraction: 0.025,
    friction: 0.28,
    freeScrollFriction: 0.075,
    prevNextButtons: false,
  };

  return (
    <FadeUp className="head-slider-block">
      <BlockHeader>{data.title}</BlockHeader>
      <Flickity options={flickityOptions} flickityRef={(c) => setSwiperRef(c)}>
        {data.items.map((item) => (
          <div key={item.img} className="head-slider-image">
            <img src={`${cdnUrl}/${item.img}`} alt={item.img} />
          </div>
        ))}
      </Flickity>
      <div className="reviews-block-buttons">
        <button
          className="reviews-block-button"
          onClick={() => {
            swiperRef?.previous();
          }}
        >
          <SvgCircleArrow />
        </button>
        <button
          className="reviews-block-button"
          style={{ transform: 'rotate(180deg)' }}
          onClick={() => {
            swiperRef?.next();
          }}
        >
          <SvgCircleArrow />
        </button>
      </div>
    </FadeUp>
  );
};

export default HeadSlider;
