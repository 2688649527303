import React from 'react';
import { Helmet } from 'react-helmet-async';
import { useLocation } from 'react-router-dom';

import { ActiveProvider } from '../../../helpers/activeContext';
import text from '../../../translation.json';
import './teamAugmentation.scss';
import ProjectsCompleted from '../../../components/Services/Shared/ProjectCompleted';
import { remoteStorageUrl } from '../../../config';
import Clover from '../../../components/Services/Shared/Clover/Clover';
import PinkishGradient from '../../../components/Services/Shared/PinkishGradient/PinkishGradient';
import ClientsLove from '../../../components/Services/Shared/ClientLoves';
import Domains from '../../../components/Home/Domains';
import Segments from '../../../components/Services/FullCycle/Segments/Segments';
import Support from '../../../components/Services/FullCycle/Support/Support';
import HomeForm from '../../../components/Shared/Form';
import FAQ from '../../../components/Home/FAQ';
import Reviews from '../../../components/Services/FullCycle/Reviews/Reviews';
import Cases from '../../../components/Services/Shared/Cases';
import Specialists from '../../../components/Services/Augmentation/Specialists/Specialists';
import ServiceHead from '../../../components/Services/Head';

const TeamAugmentation = ({ data }) => {
  const location = useLocation();
  return (
    <div className="team-augmentation">
      <ActiveProvider value="augmentation">
        <Helmet>
          <title>{text.metaTags.augmentation.title}</title>
          <meta
            name="description"
            content={text.metaTags.augmentation.description}
          />
          <link
            rel="canonical"
            href={`${process.env.REACT_APP_SITE_URL}${location.pathname}`}
          />
        </Helmet>

        <ServiceHead data={data.main} />

        <ProjectsCompleted
          data={data.projects_completed}
          cdnUrl={`${remoteStorageUrl}/resources/images/services_page/cross-platform/`}
        />

        <Specialists
          data={data.specialists}
          cdnUrl={`${remoteStorageUrl}/resources/images/services_page/augmentation/`}
        />

        <Clover
          title={data.clover.title}
          subtitle={data.clover.subtitle}
          items={data.clover.items}
          cdnUrl={`${remoteStorageUrl}/resources/images/services_page/augmentation/`}
          Icon={
            <img
              src={`${remoteStorageUrl}/resources/images/services_page/augmentation/augmentation-clover.svg`}
              alt="clover"
            />
          }
        />

        <PinkishGradient
          title={data.idea.title}
          subtitle={data.idea.subtitle}
          items={data.idea.items}
          circles
        />

        <ClientsLove
          title={data.clients_love.title}
          items={data.clients_love.items}
          cdnUrl={`${remoteStorageUrl}/resources/images/services_page/augmentation/`}
        />

        <Domains text={data.domains} />

        <Segments data={data.segments} textCentered />

        <Cases text={data.cases} threeBlockLayout />

        <Support
          data={data.support}
          cdnUrl={`${remoteStorageUrl}/resources/images/services_page/augmentation/`}
        />

        <ProjectsCompleted data={data.facts} />

        <Reviews data={data.reviews} />

        <HomeForm text={data.form} />

        <FAQ text={data.faq} />
      </ActiveProvider>
    </div>
  );
};

export default TeamAugmentation;
