import React, { Fragment } from 'react';
import { Helmet } from 'react-helmet-async';
import { useLocation } from 'react-router-dom';

import withRouter from '../../../utils/withRouter';
import './CrossPlatform.scss';
import text from '../../../translation.json';
import ProjectsCompleted from '../../../components/Services/Shared/ProjectCompleted';
import Clover from '../../../components/Services/Shared/Clover/Clover';
import { remoteStorageUrl } from '../../../config';
import PinkishGradient from '../../../components/Services/Shared/PinkishGradient/PinkishGradient';
import ClientsLove from '../../../components/Services/Shared/ClientLoves';
import FullCycleDevelopment from '../../../components/Services/Shared/CheckTilesBox';
import CarryingServices from '../../../components/Services/CrossPlatform/CarryingServices';
import TechStack from '../../../components/Services/Shared/TechStack/TechStack';
import Domains from '../../../components/Home/Domains';
import Team from '../../../components/Services/Shared/Team';
import WorkProcess from '../../../components/Services/Shared/WorkProcess/WorkProcess';
import HomeForm from '../../../components/Shared/Form';
import FAQ from '../../../components/Home/FAQ';
import OurScope from '../../../components/Services/Shared/Scope';
import ServiceHead from '../../../components/Services/Head';

const CrossPlatform = ({ data }) => {
  const location = useLocation();
  return (
    <Fragment>
      <Helmet>
        <title>{text.metaTags.flutter.title}</title>
        <meta name="description" content={text.metaTags.flutter.description} />
        <link
          rel="canonical"
          href={`${process.env.REACT_APP_SITE_URL}${location.pathname}`}
        />
      </Helmet>

      <ServiceHead data={data.main} />

      <ProjectsCompleted
        data={data.projects_completed}
        cdnUrl={`${remoteStorageUrl}/resources/images/services_page/cross-platform/`}
      />

      <Clover
        title={data.clover.title}
        items={data.clover.items}
        cdnUrl={`${remoteStorageUrl}/resources/images/services_page/cross-platform/`}
        Icon={
          <img
            src={`${remoteStorageUrl}/resources/images/services_page/cross-platform/flutter.svg`}
            alt="clover"
          />
        }
      />

      <PinkishGradient
        title={data.performance.title}
        subtitle={data.performance.subtitle}
        items={data.performance.items}
        cdnUrl={`${remoteStorageUrl}/resources/images/services_page/cross-platform/`}
      />

      <ClientsLove
        title={data.clients_love.title}
        items={data.clients_love.items}
        cdnUrl={`${remoteStorageUrl}/resources/images/services_page/cross-platform/`}
      />

      <FullCycleDevelopment
        title={data.full_cycle.title}
        items={data.full_cycle.items}
        checkSrc={`${remoteStorageUrl}/resources/icons/checked_traced.svg`}
      />

      <CarryingServices
        title={data.services.title}
        items={data.services.items}
      />

      <TechStack
        title={data.tech_stack.title}
        images={data.tech_stack.images}
        cdnUrl={`${remoteStorageUrl}/resources/images/services_page/cross-platform/`}
      />

      <Domains text={data.domains} />

      <OurScope
        title={data.our_scope.title}
        items={data.our_scope.items}
        linkText={data.our_scope.link}
        cdnUrl={`${remoteStorageUrl}/resources/images/services_page/cross-platform`}
      />

      <Team
        title={data.team.title}
        items={data.team.items}
        cdnUrl={`${remoteStorageUrl}/resources/images/services_page/cross-platform/`}
        topGap={false}
      />

      <WorkProcess
        title={data.work_process.title}
        items={data.work_process.items}
        cdnUrl={`${remoteStorageUrl}/resources/images/services_page/cross-platform/`}
      />

      <HomeForm text={data.form} />

      <FAQ text={data.faq} />
    </Fragment>
  );
};
export default withRouter(CrossPlatform);
