import React, { useState } from 'react';

import './input.scss';

const EMAIL_REGEX =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/i;

const CustomInput = (props) => {
  const { field, form, meta, placeholder, required, type } = props;
  const [fieldError, setFieldError] = useState('');

  const validate = (value) => {
    if (type === 'email') {
      const isValid = EMAIL_REGEX.test(value);
      if (!isValid) {
        form.setFieldError(field.name, 'Invalid email address');
        setFieldError('Invalid email address');
      } else {
        form.setFieldError(field.name, '');
        setFieldError('');
      }
    }
  };

  return (
    <div className="input_container">
      <div
        className={`form-group ${field.value ? 'hidePlaceholder' : ''} ${
          (form.errors[field.name] || fieldError) && field.value
            ? 'errored'
            : ''
        }`}
        style={{
          border:
            (!!meta.error || fieldError) && !!meta.touched
              ? '1px solid red'
              : '',
        }}
      >
        <input
          onChange={(event) => {
            form.setFieldValue(field.name, event.target.value);
            form.setFieldTouched(field.name, false);
            validate(event.target.value);
          }}
          placeholder={!required ? placeholder : ' '}
          type={type}
        />
        {required ? <label>{placeholder}</label> : null}
      </div>
      {!!meta.error && !!meta.touched ? (
        <div className="error_box">{meta.error}</div>
      ) : null}
    </div>
  );
};

export default CustomInput;
