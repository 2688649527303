import React from "react";
import "./text.scss";

const Heavy16px = ({ children, ...rest }) => {
  return (
    <p {...rest} className="heavy16px">
      {children}
    </p>
  );
};

export default Heavy16px;
