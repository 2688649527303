import * as React from "react";

function DeleteIcon(props) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 20C12.6522 20 15.1957 18.9464 17.0711 17.0711C18.9464 15.1957 20 12.6522 20 10C20 7.34784 18.9464 4.8043 17.0711 2.92893C15.1957 1.05357 12.6522 0 10 0C7.34784 0 4.8043 1.05357 2.92893 2.92893C1.05357 4.8043 0 7.34784 0 10C0 12.6522 1.05357 15.1957 2.92893 17.0711C4.8043 18.9464 7.34784 20 10 20ZM5.275 5.275C5.45078 5.09944 5.68906 5.00082 5.9375 5.00082C6.18594 5.00082 6.42422 5.09944 6.6 5.275L10 8.675L13.4 5.275C13.4858 5.18289 13.5893 5.10901 13.7043 5.05777C13.8193 5.00653 13.9435 4.97898 14.0693 4.97676C14.1952 4.97454 14.3203 4.9977 14.437 5.04485C14.5537 5.092 14.6598 5.16218 14.7488 5.2512C14.8378 5.34023 14.908 5.44627 14.9552 5.563C15.0023 5.67974 15.0255 5.80477 15.0232 5.93065C15.021 6.05653 14.9935 6.18067 14.9422 6.29567C14.891 6.41067 14.8171 6.51417 14.725 6.6L11.325 10L14.725 13.4C14.8171 13.4858 14.891 13.5893 14.9422 13.7043C14.9935 13.8193 15.021 13.9435 15.0232 14.0693C15.0255 14.1952 15.0023 14.3203 14.9552 14.437C14.908 14.5537 14.8378 14.6598 14.7488 14.7488C14.6598 14.8378 14.5537 14.908 14.437 14.9552C14.3203 15.0023 14.1952 15.0255 14.0693 15.0232C13.9435 15.021 13.8193 14.9935 13.7043 14.9422C13.5893 14.891 13.4858 14.8171 13.4 14.725L10 11.325L6.6 14.725C6.42228 14.8906 6.18722 14.9808 5.94435 14.9765C5.70147 14.9722 5.46974 14.8738 5.29797 14.702C5.12621 14.5303 5.02782 14.2985 5.02353 14.0557C5.01925 13.8128 5.1094 13.5777 5.275 13.4L8.675 10L5.275 6.6C5.09944 6.42422 5.00082 6.18594 5.00082 5.9375C5.00082 5.68906 5.09944 5.45078 5.275 5.275Z"
        fill="#7C7C87"
      />
    </svg>
  );
}

export default DeleteIcon;
