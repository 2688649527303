import React, { useEffect, useState } from 'react';
import LazyLoad from 'react-lazyload';
import { Link } from 'react-router-dom';

import './posts.scss';
import FadeUp from '../../Animations/FadeUp';
import SvgLongArrow from '../../icons/long_arrow';
import BlockHeader from '../../UI/texts/BlockHeader';

const formatPublishedDate = (date) => {
  const dateObj = new Date(date);
  const month = dateObj.toLocaleString('default', { month: 'long' });
  const day = dateObj.getDate();
  const year = dateObj.getFullYear();
  return `${month} ${day}, ${year}`;
};

const blogLink = 'https://perpet.io/blog/';
const blogPostsLink =
  'https://perpet.io/blog/ghost/api/v3/content/posts/?key=f24926d2c2755e443987f64bd2&order=published_at%20DESC&limit=2';

const RecentBlogPosts = ({ title = '' }) => {
  const [posts, setPosts] = useState();

  useEffect(() => {
    const getPosts = async () => {
      const res = await fetch(blogPostsLink);
      const { posts } = await res.json();
      setPosts(posts);
    };
    getPosts();
  }, []);

  return (
    <FadeUp className="recent-posts">
      {title && (
        <BlockHeader
          dangerouslySetInnerHTML={{
            __html: title,
          }}
        />
      )}
      <div className="posts-wrapper">
        {posts &&
          posts.map((post) => (
            <div key={post.id} className="post">
              <time className="published-date">
                {formatPublishedDate(post.published_at)}
              </time>
              <div className="post-content">
                <LazyLoad once offset={100}>
                  <img src={post.feature_image} alt="" />
                </LazyLoad>
              </div>
              <Link
                className="read-more-link"
                to={`${blogLink}${post.slug}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                Read More <SvgLongArrow />
              </Link>
            </div>
          ))}
      </div>
    </FadeUp>
  );
};

export default RecentBlogPosts;
