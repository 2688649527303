import React from 'react';

import { Helmet } from 'react-helmet-async';
import { useLocation } from 'react-router';

import translation from '../../../translation_higedi.json';
import Hero2023 from '../Shared/Hero2023';
import TheClient from '../Shared/TheClient';
import TheChallenge from '../Shared/TheChallenge';
import TheResult from '../Shared/TheResult';
import TechnicalDetails from '../Shared/TechnicalDetails';
import CaseWrapper2023 from '../Shared/CaseWrapper2023';

const HigediCase = () => {
  const location = useLocation();

  return (
    <>
      <Helmet>
        <title>{translation.title} | Perpetio</title>
        <meta name="description" content={translation.heroText} />
        <link
          rel="canonical"
          href={`${process.env.REACT_APP_SITE_URL}${location.pathname}`}
        />
      </Helmet>
      <CaseWrapper2023 className="higedi-case">
        <Hero2023 translation={translation} />
        <TheClient translation={translation} />
        <TheChallenge
          title={translation.challenge_title}
          text={translation.challenge_text}
          imageSrc={translation.challenge_img}
        />
        <TheChallenge
          title={translation.solution_title}
          text={translation.solution_text}
          imageSrc={translation.solution_img}
          reverseOrder
        />
        <TheResult
          title={translation.result_title}
          text={translation.result_text}
          imageSrc={translation.result_img}
        />
        <TheResult
          title={translation.result2_title}
          text={translation.result2_text}
          imageSrc={translation.result2_img}
        />
        <TheResult
          title={translation.result3_title}
          text={translation.result3_text}
          imageSrc={translation.result3_img}
        />
        <TechnicalDetails technical_details={translation.technical_details} />
      </CaseWrapper2023>
    </>
  );
};

export default HigediCase;
