import React, { useState, useEffect } from "react";
import { Container, Row, Col } from "reactstrap";
import LazyLoad from "react-lazyload";
import { Image } from "../../../Image/Image";
import "./productScreens.scss";

const ProductScreens = ({ text }) => {
  const mediaMatch =
    typeof window === "undefined"
      ? null
      : window.matchMedia("(max-width: 575px)");
  const [matches, setMatches] = useState(mediaMatch && mediaMatch.matches);

  useEffect(() => {
    const handler = (e) => setMatches(e.matches);
    mediaMatch && mediaMatch.addListener(handler);
  }, [mediaMatch]);
  return matches ? null : (
    <div className="max-width-container">
      <Container className="product-screens" fluid>
        <Row className="margin3 justify-content-center">
          {text.product_screens.screens.map((screen, index) => (
            <Col
              key={index}
              className="product-screens-row2"
              align="center"
              xs={10}
              md={4}
            >
              <LazyLoad once offset={1000}>
                <Image src={`burn/screens/${screen}`} />
              </LazyLoad>
            </Col>
          ))}
        </Row>
      </Container>
    </div>
  );
};

export default ProductScreens;
