import React, { useEffect, useState } from 'react';
import { DropdownMenu } from 'reactstrap';
import './dropDownMenu.scss';
import moment from 'moment';
import { useWindowDimensions } from '../../../helpers/resizeHandler';

const blogLink = 'https://perpet.io/blog/';
const blogTagsLink = 'https://perpet.io/blog/ghost/api/v3/content/tags/?key=f24926d2c2755e443987f64bd2';
const blogPostsLink = 'https://perpet.io/blog/ghost/api/v3/content/posts/?key=f24926d2c2755e443987f64bd2&order=published_at%20DESC&limit=2&include=tags';

const Blogs = () => {
    const [posts, setPosts] = useState();
    const [tags, setTags] = useState();
    useEffect(() => {
        const getPosts = async () => {
            const res = await fetch(blogPostsLink);
            const { posts } = await res.json();
            setPosts(posts);
        };
        const getTags = async () => {
            const res = await fetch(blogTagsLink);
            const { tags } = await res.json();
            setTags(tags);
        };
        getPosts();
        getTags();
    }, [])
    const postHandlePath = (link) => {
        window.location.href = link;
    };
    const { width } = useWindowDimensions();
    return (
        width > 767 ?
            <DropdownMenu className={'BlogDropDownMenu'}>
                <div className={'menuTagsBox'}>
                    {tags && tags.map(tag =>
                        <a key={tag.id} href={tag.url}>{tag.name}</a>
                    )}
                </div>
                <hr style={{ marginTop: 24, marginBottom: 24 }} />
                <div className='menuPostsBox'>
                    <div className='menuPostsBox__head'>
                        <span>Latest</span>
                        <a href={blogLink}>View all</a>
                    </div>
                    <div className='menuPostsBox__posts'>
                        {posts && posts.map(post =>
                            <div key={post.id} className='post' onClick={() => postHandlePath(post.url)}>
                                <div className='post__imageBox'>
                                    <img src={post.og_image} alt={''} />
                                </div>
                                <div className='post__infoBox'>
                                    {post.tags.length > 0 && <div className='post__infoBox__tags'>
                                        {post.tags.map(tag =>
                                            <span key={tag.id}>{tag.name}</span>)
                                        }
                                    </div>}
                                    <div className='post__infoBox_title'>{post.title}</div>
                                    <div className='post__infoBox_timeBox'>
                                        <span>{moment(post.published_at).fromNow()}</span>
                                        <span className="dot">•</span>
                                        <span>{post.reading_time} min read</span>
                                    </div>
                                </div>
                            </div>
                        )}

                    </div>
                </div>
            </DropdownMenu> : null
    )
};

export default Blogs