import React from "react";
import Medium52px from "../../UI/texts/52pxMedium";
import Medium18px from "../../UI/texts/18pxMedium";

import "./achivments.scss";
import FadeUp from "../../Animations/FadeUp";

const Achivments = ({ text }) => {
  return (
    <div className="achivments_container max-width-container">
      <div className="responsive-container">
        <div className="achivments_content_box">
          {text.map((item) => (
            <FadeUp className="item_box" key={item.title}>
              <Medium52px style={{ color: "white" }}>{item.title}</Medium52px>
              <Medium18px
                style={{ textAlign: "center", color: "#F1F1F1" }}
                dangerouslySetInnerHTML={{ __html: item.text }}
              />
              <div className="achivment_mobile_divider"/>
            </FadeUp>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Achivments;
