import React from 'react';
import LazyLoad from 'react-lazyload';

import './techStack.scss';
import ResponsiveBox from '../../../UI/ResponsiveBox';
import FadeUp from '../../../Animations/FadeUp';
import { remoteStorageUrl } from '../../../../config';
import BlockHeader from '../../../UI/texts/BlockHeader';

const TechStack = ({
  title,
  images,
  cdnUrl = `${remoteStorageUrl}/resources/images/tech_stack/`,
}) => {
  return (
    <ResponsiveBox topGap={false}>
      <div className="tech-stack">
        <FadeUp>
          <BlockHeader
            dangerouslySetInnerHTML={{
              __html: title,
            }}
          />
        </FadeUp>
        <FadeUp className="tech-stack-content">
          {images.map((data, index) => {
            return (
              <div key={index} className="tech-stack-item">
                <LazyLoad once offset={100}>
                  <img
                    alt={data.alt}
                    src={`${cdnUrl}${data.src}`}
                    title={data.alt}
                  />
                </LazyLoad>
              </div>
            );
          })}
        </FadeUp>
      </div>
    </ResponsiveBox>
  );
};

export default TechStack;
