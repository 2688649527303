import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import { remoteStorageUrl } from '../../../../config';
import ReactCompareImage from '../../../Compare/Compare';
import './compare.scss';
const compareIcon = `${remoteStorageUrl}/resources/images/compare_icon.svg`;

const compare = ({ text }) => (
  <Container className="product-compare" fluid>
    <div className="max-width-container">
      <Row className="product-compare-row">
        {text.compare.map((item, i) => (
          <Col
            key={i}
            className="product-compare-col px-sm-5"
            xs={12}
            sm={6}
            md={3}
          >
            <ReactCompareImage
              sliderLineWidth={0}
              leftImage={`${remoteStorageUrl}/public/burn/compare/${item.image_old}`}
              leftImageSrcSet={`${remoteStorageUrl}/public/burn/compare/${
                item.image_old.split('.')[0]
              }@2x.${
                item.image_old.split('.')[1]
              } 2x, ${remoteStorageUrl}/public/burn/compare/${
                item.image_old.split('.')[0]
              }@3x.${
                item.image_old.split('.')[1]
              } 3x,${remoteStorageUrl}/public/burn/compare/${
                item.image_old.split('.')[0]
              }@4x.${item.image_old.split('.')[1]} 4x`}
              rightImage={`${remoteStorageUrl}/public/burn/compare/${item.image_new}`}
              rightImageSrcSet={`${remoteStorageUrl}/public/burn/compare/${
                item.image_new.split('.')[0]
              }@2x.${
                item.image_new.split('.')[1]
              } 2x, ${remoteStorageUrl}/public/burn/compare/${
                item.image_new.split('.')[0]
              }@3x.${
                item.image_new.split('.')[1]
              } 3x, ${remoteStorageUrl}/public/burn/compare/${
                item.image_new.split('.')[0]
              }@4x.${item.image_new.split('.')[1]} 4x`}
              sliderPositionPercentage={0.5}
              handle={<img height="50" src={compareIcon} alt="compare" />}
              animate={true}
            />
          </Col>
        ))}
      </Row>
    </div>
  </Container>
);

export default compare;
