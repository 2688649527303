import React from 'react';
import LazyImage from '../../UI/LazyImage';
import { remoteStorageUrl } from '../../../config';

const TheResult = ({ title, text, imageSrc }) => {
  const imagePath = imageSrc.split('/');

  return (
    <div className="case-result">
      <div>
        <h4>{title}</h4>
        <p
          dangerouslySetInnerHTML={{
            __html: text,
          }}
        ></p>
      </div>
      {imageSrc.match('webp') ? (
        <div>
          <LazyImage
            imageLink={imagePath[imagePath.length - 1]}
            path={imagePath.slice(0, imagePath.length - 1).join('/')}
            alt={title}
          />
        </div>
      ) : (
        <img src={`${remoteStorageUrl}/${imageSrc}`} alt={title} />
      )}
    </div>
  );
};

export default TheResult;
