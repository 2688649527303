import React, { lazy, Suspense } from 'react';

import './projectsCompletedX3.scss';
import FadeUp from '../../../Animations/FadeUp';
import Medium18px from '../../../UI/texts/18pxMedium';
import Medium52px from '../../../UI/texts/52pxMedium';

let AnimatedNumbers = lazy(() => import('react-animated-numbers'));

const RenderStandardNumberView = ({ item }) => {
  return (
    <Medium52px style={{ whiteSpace: 'nowrap' }}>
      <div
        style={{ display: 'flex' }}
        className="number_with_after_before_world"
      >
        <span>{item.before || ''}</span>
        <Suspense>
          <AnimatedNumbers
            locale="en-US"
            includeComma
            animateToNumber={item.count}
            configs={[
              { mass: 1, tension: 150, friction: 50 },
              { mass: 1, tension: 200, friction: 80 },
              { mass: 1, tension: 280, friction: 90 },
            ]}
          />
        </Suspense>
        <span>{item.after || ''}</span>
      </div>
    </Medium52px>
  );
};

const RenderWithAfterWorldNumberView = ({ item }) => {
  return (
    <Medium52px style={{ whiteSpace: 'nowrap' }}>
      <div
        style={{ display: 'flex', gap: '1rem' }}
        className="number_with_after_world"
      >
        <Suspense>
          <AnimatedNumbers
            locale="en-US"
            includeComma
            animateToNumber={item.count}
            configs={[
              { mass: 1, tension: 150, friction: 50 },
              { mass: 1, tension: 200, friction: 80 },
              { mass: 1, tension: 280, friction: 90 },
            ]}
          />
        </Suspense>
        <div>{item.after_word}</div>
      </div>
    </Medium52px>
  );
};

const RenderWithSeparateWorldNumberView = ({ item }) => {
  return (
    <Medium52px style={{ whiteSpace: 'nowrap' }}>
      <div
        style={{ display: 'flex', gap: '1rem' }}
        className="number_with_separator_world"
      >
        <Suspense>
          <AnimatedNumbers
            locale="en-US"
            includeComma
            animateToNumber={item.count}
            configs={[
              { mass: 1, tension: 150, friction: 50 },
              { mass: 1, tension: 200, friction: 80 },
              { mass: 1, tension: 280, friction: 90 },
            ]}
          />
        </Suspense>
        <div>{item.middle_separator}</div>
        <Suspense>
          <AnimatedNumbers
            locale="en-US"
            includeComma
            animateToNumber={item.after_separator_count}
            configs={[
              { mass: 1, tension: 150, friction: 50 },
              { mass: 1, tension: 200, friction: 80 },
              { mass: 1, tension: 280, friction: 90 },
            ]}
          />
        </Suspense>
      </div>
    </Medium52px>
  );
};

const ProjectsCompletedX3 = ({
  data,
  cdnUrl,
  twoColumnMobileLayout = false,
}) => {
  return (
    <FadeUp threshold={0.35}>
      <div
        className={
          twoColumnMobileLayout
            ? 'projects-completed-x3 sm-2-columns'
            : 'projects-completed-x3'
        }
      >
        <div className="projects-completed-container">
          {data.items.map((item, index) => (
            <div key={index} className="projects-completed-item">
              {item.icon ? (
                <FadeUp className="mb-2">
                  <img src={`${cdnUrl}${item.icon}`} alt={item.text} />
                </FadeUp>
              ) : null}
              {item.count && !item.after_word && !item.middle_separator ? (
                <RenderStandardNumberView item={item} />
              ) : !item.middle_separator && item.count ? (
                <RenderWithAfterWorldNumberView item={item} />
              ) : item.middle_separator &&
                item.count &&
                item.after_separator_count ? (
                <RenderWithSeparateWorldNumberView item={item} />
              ) : null}
              {item.title && (
                <span
                  dangerouslySetInnerHTML={{ __html: item.title }}
                  className="projects-completed-item__title"
                />
              )}
              {item.text && (
                <div className="projects-completed-item__subtitle_box">
                  <Medium18px
                    style={{
                      color: '#f1f1f1',
                      fontSize: item.title ? '16px' : '18px',
                    }}
                    dangerouslySetInnerHTML={{ __html: item.text }}
                  />
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
    </FadeUp>
  );
};

export default ProjectsCompletedX3;
