import * as React from 'react';

function SvgCircleArrow() {
  return (
    <svg
      width="44"
      height="44"
      viewBox="0 0 44 44"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="22"
        cy="22"
        r="21"
        transform="matrix(-1 0 0 1 44 0)"
        stroke="#3095F2"
        strokeWidth="2"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.5413 26.9306C18.9633 26.7456 19.1614 26.2458 18.9694 25.8507C18.9195 25.7479 18.3925 25.2124 17.4313 24.2878C16.6271 23.5142 15.9692 22.868 15.9692 22.8517C15.9692 22.8355 19.4381 22.8222 23.678 22.8222L31.3868 22.8222L31.5653 22.7313C31.8398 22.5914 31.9813 22.3685 31.9982 22.0487C32.015 21.7328 31.9131 21.5074 31.6691 21.32L31.5135 21.2005L23.7413 21.1904C19.4666 21.1849 15.9692 21.1672 15.9692 21.1511C15.9692 21.1351 16.6345 20.4802 17.4478 19.6958C19.0438 18.1565 19.0784 18.1146 19.0375 17.7645C19.0102 17.5311 18.8363 17.2558 18.6352 17.1274C18.4089 16.9829 18.0349 16.9574 17.8086 17.0711C17.6072 17.1722 13.1687 21.4618 13.0708 21.6499C12.9712 21.8412 12.9775 22.1753 13.0846 22.3728C13.2084 22.6014 17.6462 26.8659 17.841 26.9435C18.0418 27.0235 18.3419 27.018 18.5413 26.9306Z"
        fill="#3095F2"
      />
    </svg>
  );
}

export default SvgCircleArrow;
