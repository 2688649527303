import React from 'react';

import FadeUp from '../../../Animations/FadeUp';

import './fullCycleDevelopment.scss';
import LazyLoad from 'react-lazyload';
import ResponsiveBox from '../../../UI/ResponsiveBox';
import Medium24px from '../../../UI/texts/24pxMedium';
import BlockHeader from '../../../UI/texts/BlockHeader';

const FullCycleDevelopment = ({
  title,
  items,
  checkSrc,
  topGap = true,
  subtitle,
}) => {
  return (
    <ResponsiveBox topGap={topGap}>
      <div className="full-cycle-development">
        <FadeUp>
          <BlockHeader
            dangerouslySetInnerHTML={{
              __html: title,
            }}
          />
        </FadeUp>
        {subtitle && (
          <FadeUp className={'full-cycle-development__subtitle'}>
            <Medium24px
              dangerouslySetInnerHTML={{
                __html: subtitle,
              }}
              style={{ textAlign: 'center', color: 'var(--p-text-color)' }}
            />
          </FadeUp>
        )}

        <div className="full-cycle-development-wrapper">
          {items.map((item, index) => {
            return (
              <FadeUp key={index} className="full-cycle-development-item">
                <LazyLoad once offset={100}>
                  <img src={checkSrc} alt="" />
                </LazyLoad>
                <BlockHeader variant="h3">{item.text}</BlockHeader>
              </FadeUp>
            );
          })}
        </div>
      </div>
    </ResponsiveBox>
  );
};

export default FullCycleDevelopment;
