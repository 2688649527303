import React from 'react';
import { NavLink } from 'reactstrap';
import { NavLink as RRNavLink } from 'react-router-dom';
import moment from 'moment';

import text from '../../translation.json';
import Logo from '../Header/Logo';
import './footer.scss';
import LazyImage from '../UI/LazyImage';
import FadeUp from '../Animations/FadeUp';
import { remoteStorageUrl } from '../../config';
import useABGetVariant from '../../helpers/useABGetVariant';

const facebookLogo = `${remoteStorageUrl}/resources/icons/facebook.svg`;
const instagramLogo = `${remoteStorageUrl}/resources/icons/instagram.svg`;
const ballLogo = `${remoteStorageUrl}/resources/icons/ball.svg`;
const beLogo = `${remoteStorageUrl}/resources/icons/be.svg`;
const mediumLogo = `${remoteStorageUrl}/resources/icons/medium.svg`;
const linkedinLogo = `${remoteStorageUrl}/resources/icons/linkedin.svg`;

const Footer = ({ isDarkTheme }) => {
  return (
    <div className="footer-wrapper">
      <div className="footer-content_box">
        <div className="logoBox">
          <Logo
            color={isDarkTheme ? 'white-char' : 'black-char'}
            colorB="blue-char"
          />
          <div className="logo_text">
            <span
              dangerouslySetInnerHTML={{
                __html: text.homePage_v2_0.footer.subtitle,
              }}
            ></span>
          </div>
          <div className="badges ">
            <div className="badges_group">
              {text.homePage_v2_0.footer.badges.map((badge, index) => (
                <a
                  href={badge.href}
                  target="_blank"
                  rel="noopener noreferrer"
                  className={badge.className}
                  key={badge.href + index}
                >
                  <LazyImage
                    imageLink={badge.img}
                    path={badge.path}
                    alt={badge.alt}
                  />
                </a>
              ))}
            </div>
          </div>
        </div>
        <div className="sectionBox">
          <div className="sectionBox_title">
            <span>{text.homePage_v2_0.footer.linkTitle.item2}</span>
          </div>
          <div className={'navLinksBox'}>
            {text.homePage_v2_0.footer.companyLinks.map((item, index) => (
              <div key={item.name} className="navLinksBoxItem">
                {item.link.match(/http/g) ? (
                  <a
                    key={item.link + index}
                    href={item.link}
                    className="navLink nav-link"
                    target={item.link.match(/perpet.io/g) ? '_self' : '_blank'}
                    rel="noopener noreferrer"
                  >
                    {item.name}
                  </a>
                ) : (
                  <NavLink
                    key={item.link + index}
                    tag={RRNavLink}
                    to={item.link}
                    className="navLink"
                  >
                    {item.name}
                  </NavLink>
                )}
                {index !==
                  text.homePage_v2_0.footer.companyLinks.length - 1 && (
                  <div className="policyLine"></div>
                )}
              </div>
            ))}
          </div>
        </div>
        <div className="sectionBox">
          <div className="sectionBox_title">
            <span>{text.homePage_v2_0.footer.linkTitle.item4}</span>
          </div>
          <div className={'navLinksBox'}>
            {text.homePage_v2_0.footer.solutions.map((item, index) => (
              <div key={item.name} className="navLinksBoxItem">
                <NavLink
                  key={item.link + index}
                  tag={item.link ? 'a' : 'div'}
                  href={item.link}
                  to={item.link}
                  className={item.link ? 'navLink' : 'navLink disabled'}
                >
                  {item.name}
                </NavLink>
                {index !== text.homePage_v2_0.footer.solutions.length - 1 && (
                  <div className="policyLine"></div>
                )}
              </div>
            ))}
          </div>
        </div>

        <div className="sectionBox">
          <div className="sectionBox_title">
            <span>{text.homePage_v2_0.footer.linkTitle.item1}</span>
          </div>
          <div className="navLinksBox">
            {text.homePage_v2_0.footer.servicesLinks.map((item, index) => (
              <div key={item.name} className="navLinksBoxItem">
                <NavLink
                  key={item.link + index}
                  tag={item.link ? RRNavLink : 'div'}
                  to={item.link}
                  className={item.link ? 'navLink' : 'navLink disabled'}
                >
                  {item.abKey
                    ? useABGetVariant(item.abKey, 'footerTitle')
                    : item.name}
                </NavLink>
                {index !==
                  text.homePage_v2_0.footer.servicesLinks.length - 1 && (
                  <div className="policyLine"></div>
                )}
              </div>
            ))}
          </div>
        </div>
        <div className="sectionBox">
          <div className="sectionBox_title">
            <span>{text.homePage_v2_0.footer.linkTitle.item5}</span>
          </div>
          <div className={'navLinksBox'}>
            {' '}
            {text.homePage_v2_0.footer.industries.map((item, index) => (
              <div key={item.name} className="navLinksBoxItem">
                <NavLink
                  key={item.link + index}
                  tag={item.link ? RRNavLink : 'div'}
                  to={item.link}
                  className={item.link ? 'navLink' : 'navLink disabled'}
                >
                  {item.name}
                </NavLink>
                {index !== text.homePage_v2_0.footer.industries.length - 1 && (
                  <div className="policyLine"></div>
                )}
              </div>
            ))}
          </div>
        </div>
      </div>

      <FadeUp className="footer-socialBox">
        <div className="allRights">
          <span>{`©${moment().format('YYYY')} ${
            text.homePage_v2_0.footer.allRights
          }`}</span>
        </div>
        <FooterSocialLinks />
      </FadeUp>

      <div className="footer-policyLinks">
        {text.homePage_v2_0.footer.policyLinks.map((policy, index) => (
          <div key={policy.name} className="privatePolicyWrapper">
            <div className="privatePolicyBox">
              <NavLink tag={RRNavLink} to={policy.link}>
                {policy.name}
              </NavLink>
            </div>
            {index !== text.homePage_v2_0.footer.policyLinks.length - 1 && (
              <div className="policyLine"></div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export const FooterSocialLinks = () => {
  return (
    <div className="socialLinks">
      <div className="linkBox">
        <a
          href="https://www.facebook.com/perpetio"
          target="_blank"
          rel="nofollow noopener noreferrer"
        >
          <img src={facebookLogo} alt="facebook-logo" />
        </a>
      </div>

      <div className="linkBox">
        <a
          href="https://www.instagram.com/perpetio"
          target="_blank"
          rel="nofollow noopener noreferrer"
        >
          <img src={instagramLogo} alt="instagram-logo" />
        </a>
      </div>

      <div className="linkBox">
        <a
          href="https://dribbble.com/perpetio"
          target="_blank"
          rel="nofollow noopener noreferrer"
        >
          <img src={ballLogo} alt="ball-logo" />
        </a>
      </div>

      <div className="linkBox">
        <a
          href="https://www.behance.net/perpetio_design"
          target="_blank"
          rel="nofollow noopener noreferrer"
        >
          <img src={beLogo} alt="be-logo" />
        </a>
      </div>

      <div className="linkBox">
        <a
          href="https://medium.com/perpetio"
          target="_blank"
          rel="nofollow noopener noreferrer"
        >
          <img src={mediumLogo} alt="medium-logo" />
        </a>
      </div>
      <div className="linkBox">
        <a
          href=" https://www.linkedin.com/company/perpetio/"
          target="_blank"
          rel="nofollow noopener noreferrer"
        >
          <img src={linkedinLogo} alt="medium-logo" />
        </a>
      </div>
    </div>
  );
};

export default Footer;
