import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import './commitDescription.scss';
import { remoteStorageUrl } from '../../../../config';

export default ({ text }) => {
  // const mediaMatch = window.matchMedia('(max-width: 575px)');
  // const [matches, setMatches] = useState(mediaMatch.matches);

  // useEffect(() => {
  //   const handler = e => setMatches(e.matches);
  //   mediaMatch.addListener(handler);
  // }, []);
  return (
    <Container className="commit-description" fluid>
      {text.product_description.map((description, index) => (
        <Row key={index} className="commit-description-row">
          <Col sm={9} md={7} className="p-0">
            <Row className="m-0 flex-column flex-md-row">
              <img
                className="commit-description-icon"
                src={
                  `${remoteStorageUrl}/resources/images/commit/` +
                  description.icon
                }
                alt="icon"
              />
              <Col className="commit-description-col p-0">
                <h3 className="commit-description-title">
                  {description.title}
                </h3>
                <p className="commit-description-text">{description.text}</p>
              </Col>
            </Row>
          </Col>
        </Row>
      ))}
    </Container>
  );
};
