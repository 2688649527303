import React from 'react';
import { NavLink } from 'react-router-dom';
import { DropdownItem, DropdownMenu as RDropdownMenu } from 'reactstrap';

import withRouter from '../../../utils/withRouter';
import '../header.scss';
import useABGetVariant from '../../../helpers/useABGetVariant';

const DropdownInner = ({ blocks, cdnUrl, asDropdown, closeMenu }) => {
  const ItemWrapper = asDropdown ? DropdownItem : NavLink;
  return (
    <>
      {blocks.map((block) => (
        <ItemWrapper
          className="customDropDownItem"
          tag={asDropdown ? NavLink : undefined}
          to={block.link}
          key={block.title}
          onClick={() => closeMenu()}
        >
          <div className="customDropDownItem__Container">
            <div className="Container__head">
              <div
                style={{
                  width: 24,
                  height: 24,
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <img
                  src={`${cdnUrl}/${block.icon}`}
                  alt={block.icon}
                  className="iconBox"
                />
              </div>
              <div className="textBlock__title">
                {block.abKey
                  ? useABGetVariant(block.abKey, 'linkTitle') || block.title
                  : block.title}
              </div>
            </div>
          </div>
        </ItemWrapper>
      ))}
    </>
  );
};

const NavBarDropdownMenu = ({
  blocks,
  rows,
  cdnUrl,
  asDropdown = true,
  closeMenu = () => {},
  className = '',
}) => {
  const Wrapper = asDropdown ? RDropdownMenu : 'div';

  return (
    <Wrapper
      className={`customDropDown${blocks ? ' one-col-dropdown' : ''}${
        className ? ` ${className}` : ''
      }`}
    >
      {blocks ? (
        <div
          className="one-col-dropdown-content"
          style={{
            display: 'flex',
            flexDirection: 'column',
            flexWrap: 'wrap',
            width: '100%',
          }}
        >
          <DropdownInner
            blocks={blocks}
            cdnUrl={cdnUrl}
            asDropdown={asDropdown}
            closeMenu={closeMenu}
          />
        </div>
      ) : null}
      {rows ? (
        <div className="few-col-dropdown">
          {rows.map((blocks, index) => (
            <div key={index} className="few-col-dropdown-item">
              <DropdownInner
                blocks={blocks}
                cdnUrl={cdnUrl}
                asDropdown={asDropdown}
                closeMenu={closeMenu}
              />
            </div>
          ))}
        </div>
      ) : null}
    </Wrapper>
  );
};
export default withRouter(NavBarDropdownMenu);
