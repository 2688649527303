import React, { useState, useEffect } from 'react';
import withRouter from '../../utils/withRouter';
import { Dropdown, DropdownToggle } from 'reactstrap';
import '../Header/header.scss';
import text from '../../translation.json';
import { whiteNav } from '../Header/Header';
import { useWindowDimensions } from '../../helpers/resizeHandler';
import SvgArrowUp from '../icons/arrow_up';

const activeStyles = {
  dark: {
    color: '#FFF',
    fontFamily: 'Avenir-Heavy-05, sans-serif',
  },
  light: {
    color: '#000',
    fontFamily: 'Avenir-Heavy-05, sans-serif',
  },
};

const categorySubPages = {
  services: [{ link: '/services' }],
  solutions: text.services.solutions.blocks,
  industries: text.services.industries.blocks,
  company: text.services.company.blocks,
};

const CustomDropDown = (props) => {
  const {
    link,
    linkTitle,
    pathname,
    setOpenHamburger,
    openHamburger,
    direction = 'down',
    isDarkTheme = false,
    _isOpen = false,
    setActiveHamburgerElement = () => {},
    isActiveInHamburger = false,
    isActiveForTablet = false,
    name,
  } = props;

  const { width } = useWindowDimensions();

  const [dropdownOpen, setDropdownOpen] = useState(_isOpen);
  const [scrollY, setScrollY] = useState(0);
  const isTabletAndLower = width <= 1024;
  const isMobile = width <= 768;

  const getStyleFromPath = (style1, style2) =>
    whiteNav.includes(pathname) && !openHamburger && (!scrollY || scrollY < 0)
      ? style1
      : style2;

  useEffect(() => {
    const handleScroll = () => setScrollY(window.scrollY);
    window.addEventListener('scroll', handleScroll);
  }, [scrollY]);

  const onMouseEnter = () => {
    !isTabletAndLower && setDropdownOpen(true);
  };
  const onMouseLeave = () => {
    if (_isOpen) {
      return;
    }
    !isTabletAndLower && setDropdownOpen(false);
  };

  const toggle = () => {
    setDropdownOpen((prev) => !prev);
  };

  const toggleActiveHamburgerElement = () => {
    if (isMobile && isActiveInHamburger) {
      setActiveHamburgerElement(null);
      return;
    }
    setActiveHamburgerElement(true);
  };

  const isActiveLink =
    link === pathname ||
    categorySubPages[name]?.some(
      (a) => a.link === pathname || pathname.includes(a.link),
    );

  if (isTabletAndLower) {
    if (link) {
      return (
        <div
          className="hamburger-item"
          onClick={() => {
            setOpenHamburger(false);
          }}
        >
          <a href={link} className="black nav-link">
            {linkTitle}
          </a>
        </div>
      );
    }

    return (
      <div
        className={`hamburger-item${
          isActiveInHamburger || (isActiveForTablet && !isMobile)
            ? ' hamburger-active'
            : ''
        }${isActiveLink ? ' hamburger-current-page' : ''}`}
      >
        <button
          className="black nav-link"
          onClick={toggleActiveHamburgerElement}
        >
          {linkTitle}
          <SvgArrowUp className="arrow-up" />
        </button>
        {props.children ? (
          <div className="hamburger-submenu-mobile">{props.children}</div>
        ) : null}
      </div>
    );
  }

  return (
    <Dropdown
      nav
      onMouseOver={onMouseEnter}
      onMouseLeave={onMouseLeave}
      isOpen={dropdownOpen}
      toggle={toggle}
      direction={direction}
    >
      <DropdownToggle
        tag={link ? 'a' : 'button'}
        href={link}
        style={isActiveLink ? activeStyles[isDarkTheme ? 'dark' : 'light'] : {}}
        onClick={(e) => {
          e.preventDefault();
          if (/(http(s?)):\/\//i.test(link)) {
            window.location.href = link;
            return;
          }
          if (link) {
            setOpenHamburger(false);
            props.history.navigate(link);
          }
        }}
        nav
        className={getStyleFromPath('white', 'black')}
      >
        {linkTitle}
      </DropdownToggle>
      {props.children}
    </Dropdown>
  );
};
export default withRouter(CustomDropDown);
