import React from "react";
import { Container, Row, Col } from "reactstrap";

import "./textImage.scss";
import ImageSrcSet from "../../../ImageSrcSet";
import FadeUp from "../../../Animations/FadeUp";

const TextImageBox = ({ data }) => {
  return (
    <Container fluid className="textImageContainer">
      <FadeUp className={`textImageBoxFade`}>
        <Row className="contentBox">
          <Row className="textContainer">
            <Col className="textBox" style={{}}>
              <div>{data.title}</div>
              <span dangerouslySetInnerHTML={{ __html: data.text }} />
            </Col>
          </Row>
          <Row className={`imageContainer`}>
            <Col className="imageBox">
              <ImageSrcSet pathObj={data.imagePaths} />
            </Col>
          </Row>
        </Row>
      </FadeUp>
    </Container>
  );
};

export default TextImageBox;
