import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import './commitFeatures.scss';

export default ({text}) => {
  const setColor = (color) => ({
    backgroundColor: "#" + color
  })
  return (
    <Container className="commit-features" fluid>
        <h2 className="commit-features-title">{text.title}</h2>
        <Row className="commit-features-row">
          <Col md={5}>
            <h4 className="commit-features-font">{text.font}</h4>
            {
              text.weights.map( (weight, index) => (
                <div className={`commit-features-font-${weight.toLowerCase()}`} key={index}>
                  <p>{weight}</p>
                  <p>{text.text_1}</p>
                  <p>{text.text_2}</p>
                </div>
              ))
            }
          </Col>
          <Col md={6}>
            <Row className="commit-features-color-row">
              {
                text.colors.map((color, index) => (
                  <Col className="commit-features-color" xs={5} style={setColor(color)} key={index}>
                    <span>{color}</span>
                  </Col>
                ))
              }
            </Row>
          </Col>
        </Row>        
    </Container>
    );
}
