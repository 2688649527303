import React, { useRef, useEffect, useState } from 'react';
import './workProduct.scss';
import FadeUp from '../../../components/Animations/FadeUp';
import { remoteStorageUrl } from '../../../config';
import PortfolioLoading from '../../../components/PortfolioLoading/PortfolioLoading';

const WorkProduct = ({ work, history, setIsOpen, setPhotoIndex }) => {
  const imageRef = useRef();
  const [isLoading, setIsLoading] = useState(true);
  const imageName = work.app.split('.')[0];
  const imageExt = work.app.split('.')[1];
  const image2x = `${remoteStorageUrl}/resources/images/portfolio/${imageName}@2x.${imageExt}`;
  const image3x = `${remoteStorageUrl}/resources/images/portfolio/${imageName}@3x.${imageExt}`;
  const image4x = `${remoteStorageUrl}/resources/images/portfolio/${imageName}@4x.${imageExt}`;
  let start;
  let end;
  useEffect(() => {
    const img = imageRef.current;
    if (img && img.complete) {
      handleImageLoaded();
    }
  }, []);
  const handleImageLoaded = () => {
    setIsLoading(false);
  };

  const handleEventMouse = (event) => {
    if (event.type === 'mousedown') {
      start = +new Date();
    } else {
      end = +new Date();
      const diff = end - start;
      if (diff < 150) {
        if (work.details) {
          setIsOpen(true);
          setPhotoIndex(work.id);
        } else {
          work.isExternal
            ? window.open(`${work.link}`, '_blank')
            : history.navigate(`/use-cases/${work.link}`);
        }
      }
    }
  };

  return isLoading ? (
    <div className="portfolio-column">
      {work.appImgOneSize ? (
        <img
          src={`${remoteStorageUrl}/resources/images/portfolio/${work.app}`}
          alt={work.name}
          onLoad={handleImageLoaded}
          ref={imageRef}
          style={{ zIndex: '-1', display: 'none' }}
        />
      ) : (
        <img
          style={{ zIndex: '-1', display: 'none' }}
          ref={imageRef}
          srcSet={`${image2x} 1x, ${image2x} 2x, ${image3x} 3x, ${image4x} 4x,`}
          alt={work.name || 'screen'}
          onLoad={handleImageLoaded}
        />
      )}
      <PortfolioLoading />
    </div>
  ) : (
    <FadeUp className={`workProductCase  ${work.id}`}>
      <FadeUp className={`workProductCase ${work.id}`}>
        <div
          onMouseDown={handleEventMouse}
          onMouseUp={handleEventMouse}
          style={{ background: work.bg }}
          className="work_projects__project"
        >
          <div className="work_projects__project__head">
            <FadeUp className="project_name">{work.name}</FadeUp>
            <FadeUp className="project_text">
              {work.text}

              <div className="project_tags">
                {work.text_type.map((tag) => (
                  <span key={tag}> {tag} </span>
                ))}
              </div>
            </FadeUp>
          </div>

          <div className="project_imageBox">
            {work.appImgOneSize ? (
              <img
                src={`${remoteStorageUrl}/resources/images/portfolio/${work.app}`}
                alt={work.name}
              />
            ) : (
              <img
                srcSet={`${image2x} 1x, ${image2x} 2x, ${image3x} 3x, ${image4x} 4x,`}
                alt={work.name || 'screen'}
              />
            )}
          </div>
        </div>
      </FadeUp>
    </FadeUp>
  );
};
export default WorkProduct;
