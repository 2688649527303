import React from 'react';
import { Container, Row, Col } from 'reactstrap';
// import AppStore from '../../../../resources/images/btn-download-apple.svg';
// import GooglePlay from '../../../../resources/images/btn-download-android.svg';
import './productResult.scss';

const productResult = ({text}) => (
  <Container className="product-result" fluid>
    <Row className="margin product-result-row">
      <Col sm={10} lg={7}>
        <h3 className="product-result-title my-3">{text.product_result.title}</h3>
        <p className="product-result-description" dangerouslySetInnerHTML={{__html: text.product_result.text}}></p>
      </Col>
    </Row>
  </Container>
);

export default productResult;