import React from "react";
import "./text.scss";

const Medium16px = ({ children, ...rest }) => {
  return (
    <p {...rest} className="medium16px">
      {children}
    </p>
  );
};

export default Medium16px;
