import React from "react";
import Select, { components } from "react-select";
import { phoneCodes } from "../../../../helpers/phoneCodes";
import "./phoneInput.scss";
import SvgBlueArrowsRight from "../../../icons/blueArrow_right";

const PhoneCodeInput = (props) => {
  const { field, form } = props;

  const options = phoneCodes.map((item) => {
    return {
      label: `${item.emoji} ${item.dial_code}`,
      value: item.dial_code,
      ...item,
    };
  });

  const defaultValue = (options, value) => {
    return options.find((option) => option.code === value.code);
  };
  const customFilter = (option, searchText) => {
    if (
      option.data.label.toLowerCase().includes(searchText.toLowerCase()) ||
      option.data.value.toLowerCase().includes(searchText.toLowerCase()) ||
      option.data.name.toLowerCase().includes(searchText.toLowerCase()) ||
      option.data.code.toLowerCase().includes(searchText.toLowerCase())
    ) {
      return true;
    } else {
      return false;
    }
  };

  return (
        <Select
          components={{ Option, Control, DropdownIndicator }}
          options={options}
          className="react-select-container"
          classNamePrefix="react-select"
          isSearchable={true}
          filterOption={customFilter}
          value={defaultValue(options, field.value)}
          onChange={(option) => {
            form.setFieldValue(field.name, {
              code: option.code,
              dial_code: option.dial_code,
            });
          }}
        />
  );
};

const Option = (props) => {
  const { innerProps, innerRef, data } = props;
  return (
    <div className="phone_option" ref={innerRef} {...innerProps}>
      <div className="phone_option_name_box">
        <div className="emoji_box">{data.emoji}</div>
        <div className="name_box">{data.name}</div>
      </div>
      <div className="code_box">{data.dial_code}</div>
    </div>
  );
};

const Control = ({ children, ...props }) => (
  <components.Control {...props}>{children}</components.Control>
);

const DropdownIndicator = ({ ...props }) => {
  return (
    <components.DropdownIndicator {...props} className="arrow_box">
      <SvgBlueArrowsRight />
    </components.DropdownIndicator>
  );
};

export default PhoneCodeInput;
