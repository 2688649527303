import React from 'react';
import { Container, Row, Col } from 'reactstrap';

import SvgEargym from '../../../icons/eargym/eargymIcon';
import SvgAppStore from '../../../icons/eargym/appStore';
import SvgGooglePlay from '../../../icons/eargym/googlePlay';
import FadeUp from '../../../Animations/FadeUp';
import { useWindowDimensions } from '../../../../helpers/resizeHandler';

import './pageHead.scss';

const PageHead = ({ text }) => {
  const { width } = useWindowDimensions();
  return (
    <Container fluid className="headContainer">
      <FadeUp className={'headFadeUp'}>
        <Row>
          <Col>
            <SvgEargym
              width={width > 750 ? '' : 209}
              height={width > 750 ? '' : 50}
            />
          </Col>
        </Row>
        <Row className="headlineBox">
          <Col>
            <span dangerouslySetInnerHTML={{ __html: text.headline }} />
          </Col>
        </Row>
        <Row xs={2} className="playStoreBox">
          <Col>
            <a
              href="https://apps.apple.com/us/app/eargym/id1557905391"
              target="__blank"
              style={{ display: 'block' }}
            >
              <SvgAppStore />
            </a>
          </Col>
          <Col>
            <a
              href="https://play.google.com/store/apps/details?id=com.app.eargym&hl=US&gl=US"
              target="__blank"
              style={{ display: 'block' }}
            >
              <SvgGooglePlay />
            </a>
          </Col>
        </Row>
      </FadeUp>
      <FadeUp className={'fadeImagesBox'}>
        <Row className="imagesBox">
          <Col
            style={{
              backgroundImage:
                'url(https://perpetioStorage1.b-cdn.net/resources/images/eargym/headPage/Wave%20background1.svg)',
              height: width > 1200 ? '786px' : 'auto',
              width: width > 1200 ? '1170px' : '100%',
            }}
          >
            <div xs={1} className="imagesBox__images">
              {text.images.map((pathObj, index) => {
                return (
                  <div key={index}>
                    <img
                      srcSet={`${pathObj.path2x} 1x, ${pathObj.path2x} 2x, ${pathObj.path3x} 3x, ${pathObj.path4x} 4x,`}
                      alt={'eargym screen'}
                    />
                  </div>
                );
              })}
            </div>
          </Col>
        </Row>
      </FadeUp>
      <div className="aboutProjContainer">
        <div className="aboutProjContainer_content">
          <FadeUp className={'aboutProjFade '}>
            <Row xs={1} className="aboutProjFade_center">
              <Col className="theClientBox">
                <span>{text.theClient.title}</span>
                <Row>
                  <div style={{ paddingLeft: '15px', width: 'auto' }}>
                    <img src={text.theClient.iconPath} alt="app icon" />
                  </div>
                  <Col className="appDescBox">
                    <span>{text.theClient.appName}</span>
                    <div>{text.theClient.appDescription}</div>
                  </Col>
                </Row>
              </Col>
              <Col className="projectTeam">
                {text.teamIcons.map((elem) => (
                  <div key={elem.title} className="projectTeam__nameBox">
                    <img src={elem.iconUrl} alt={elem.title} />
                    <span>{elem.title}</span>
                  </div>
                ))}
              </Col>
            </Row>
          </FadeUp>
          <FadeUp className={'aboutProjFade '}>
            <Row xs={2} className="aboutProjFade_center aboutProjFadeSeconBox">
              <Col className="overviewBox">
                <span>{text.overview.title}</span>
                <div className="overviewBox_list">
                  {text.overview.description.map((item) => {
                    return (
                      <span
                        key={item}
                        dangerouslySetInnerHTML={{ __html: item }}
                      />
                    );
                  })}
                </div>
              </Col>

              <Col className="servicesBox">
                <span>{text.services.title}</span>
                <div className="servicesBox_list">
                  <span
                    dangerouslySetInnerHTML={{ __html: text.services.items }}
                  />
                </div>
              </Col>
            </Row>
          </FadeUp>
        </div>
      </div>
    </Container>
  );
};
export default PageHead;
