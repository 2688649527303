import React from 'react';
import LazyImage from '../../UI/LazyImage';
import { remoteStorageUrl } from '../../../config';

const TheChallenge = ({ title, text, imageSrc, reverseOrder = false }) => {
  const imagePath = imageSrc.split('/');
  return (
    <div className="case-challenge">
      <div className="case-challenge-content">
        <h4>{title}</h4>
        <p
          dangerouslySetInnerHTML={{
            __html: text,
          }}
          className="case-challenge-text"
        />
      </div>
      {imageSrc.match('webp') ? (
        <div
          className={
            reverseOrder
              ? 'case-challenge-image reverse-order'
              : 'case-challenge-image'
          }
        >
          <LazyImage
            imageLink={imagePath[imagePath.length - 1]}
            path={imagePath.slice(0, imagePath.length - 1).join('/')}
            alt={title}
          />
        </div>
      ) : (
        <img
          className={
            reverseOrder
              ? 'case-challenge-image reverse-order'
              : 'case-challenge-image'
          }
          src={`${remoteStorageUrl}/${imageSrc}`}
          alt={title}
        />
      )}
    </div>
  );
};

export default TheChallenge;
