import React from 'react';
import AnimateIn from './AnimationIn';

const FadeUp = ({ children, transition, threshold, ...rest }) => (
  <AnimateIn
    from={{
      opacity: 0,
      transform: 'translateY(3rem)',
    }}
    to={{
      opacity: 1,
      transform: 'translateY(0px)',
      ...(transition ? { transition } : {}),
    }}
    threshold={threshold}
    {...rest}
  >
    {children}
  </AnimateIn>
);
export default FadeUp;
