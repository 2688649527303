import React, { useEffect } from 'react';
import { Container, Row, Col } from 'reactstrap';
import './productMain.scss';
import { Image } from '../../../Image/Image';
import { remoteStorageUrl } from '../../../../config';

const ProductMain = ({ text }) => {
  useEffect(() => {
    document.documentElement.style.setProperty(
      '--dark-theme-bg-color',
      'linear-gradient(60.37deg, #0d5472 11.3%, #29a3c1 96.34%)',
    );
    return () => {
      document.documentElement.style.setProperty(
        '--dark-theme-bg-color',
        '#020220',
      );
    };
  }, []);

  return (
    <Container className="product-main-aob" fluid>
      <div
        className="product-main-wrapper-aob"
        style={{
          background: `url(${remoteStorageUrl}/public/aob/main/${text.product_main.bg}) no-repeat`,
        }}
      >
        <div className="product-main-row-aob">
          <p className="product-main-title-aob mobile">
            {text.product_main.title}
          </p>
          <p className="product-main-text-aob mobile">
            {text.product_main.text}
          </p>
        </div>
        <div
          className="mobile-wrapper"
          style={{
            background: `url(${remoteStorageUrl}/public/aob/main/${text.product_main.mBg}) no-repeat`,
          }}
        >
          <Row className="product-main-row-aob">
            <Col xs={6} md={8} xl={8} className="product-main-col-desktop">
              <h1 className="product-main-title-aob desktop">
                {text.product_main.title}
              </h1>
              <p className="product-main-text-aob desktop">
                {text.product_main.text}
              </p>
            </Col>
            <Col sm={8} md={4} xl={4} className="product-main-col-aob">
              <Image
                className="product-main-img-aob"
                src={`aob/main/${text.product_main.image}`}
              />
            </Col>
          </Row>
        </div>
      </div>
    </Container>
  );
};

export default ProductMain;
