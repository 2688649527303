import React from "react";
import "./text.scss";

const Medium24px = ({ children, ...rest }) => {
  return (
    <h3 {...rest} className="medium24px">
      {children}
    </h3>
  );
};

export default Medium24px;
