/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import './privacyPolicyMain.scss';
import ResponsiveBoxHead from '../UI/ResponsiveBoxHead';
import NewMain from '../NewMain/NewMain';
import ResponsiveBox from '../UI/ResponsiveBox';

const AntiBriberyPolicy = () => (
  <div className="privacy-policy">
    <ResponsiveBoxHead style={{ paddingBottom: '2.5rem' }}>
      <div className="privacy-policy_header">
        <NewMain
          title="Anti-Bribery <span>Policy</span>"
          text="Last updated: October 2023"
        />
      </div>
    </ResponsiveBoxHead>
    <ResponsiveBox topGap={false}>
      <div className="description">
        <h3>PREAMBLE</h3>
        <p>
          Perpetio OÜ (hereinafter "the Company", "we" or "our") is committed to
          responsible corporate behaviour and compliance with all laws,
          regulations and other requirements that govern our business. Our
          unwavering commitment is to foster a strong anti-corruption culture
          and to ensure strict compliance with local anti-bribery and
          anti-corruption laws and regulations. We are committed to prohibiting
          any form of giving, offering, seeking or receiving bribes, corrupt
          payments, inducements or similar conduct by ourselves or anyone acting
          on our behalf. Our senior management will consistently implement
          effective measures to prevent, control and eliminate bribery. It is
          important to note that this Anti-Bribery Policy (hereinafter - "the
          Policy") is not considered to be part of the employment contract, and
          we reserve the right to amend or cancel it at any time.
        </p>
        <h3>BRIBERY</h3>
        <p>
          Bribery is described as the act of giving or promising to give a
          financial or other benefit to another party with the intention of
          persuading them to perform improperly or as a reward for improper
          behaviour. Bribery is also considered to be when a party requests or
          agrees to receive a financial or other benefit from another party with
          the intent to induce improper behaviour or to engage in improper
          behaviour in expectation of the benefit. In the context of bribery of
          a foreign official, this involves the offer or pledge of a financial
          or other benefit to influence a foreign official to secure business or
          gain an advantage in business transactions, where such influence is
          not required or permitted by law. Our Company has a categorically
          negative attitude to any of the above actions and will report them to
          the relevant government agency if they are detected.
        </p>
        <h3>OUR PROHIBITIONS</h3>
        <p>
          The team and/or their related parties are prohibited from offering,
          promising, giving, requesting or receiving any bribe in the form of:
          cash, gift, as an incentive or additional remuneration, or otherwise
          inducing any action in favor of any person, regardless of geography
          and regardless of whom. We reserve the right to test our team and/or
          customers and/or vendors for bribery to ensure that they are
          improperly fulfilling the obligations of this Policy (if these parties
          take advantage of the offer, we will immediately terminate our
          cooperation with them).
        </p>
        <h3>BRIBERY CONSEQUENCES</h3>
        <p>
          Under applicable laws and international guidelines, a party found
          guilty of bribery may face fines and/or imprisonment. Based on this,
          we will apply the following sanctions internally for violations of the
          basic terms of this Policy: disciplinary action, which may include
          dismissal; notification of the relevant government agency and transfer
          of the offending party's data to such agencies. For us, any breach of
          this Policy by any team member could result in: us possibly being
          deemed to have violated the law; fines being imposed on us by a
          governmental authority; and us losing our reputation in the global
          marketplace (that is categorically negative for us).
        </p>
        <h3>APPLICATION SCOPE</h3>
        <p>
          This Policy applies to all team members, contractors, subcontractors,
          consultants, business partners and any other parties (including
          individuals, partnerships and corporate organizations) associated with
          us or any of its subsidiaries. Therefore, the parties are prohibited
          from: (1) provide or promise any financial or other benefit to another
          party (or use a third party to do the same) on behalf of the Company
          if such benefit is intended to induce the other party to perform a
          function improperly, to reward the other party for doing the same, or
          if acceptance of such benefit would itself be considered improper
          conduct (2) demand or agree to receive any financial or other benefit
          from the other party if such benefit is intended to induce improper
          performance of a certain function, where acceptance of such benefit
          would itself be considered improper behavior, or where the recipient
          intends to act improperly in anticipation of such benefit. In
          addition, parties must: (1) be aware of and remain alert to all
          bribery risks at all times; (2) exercise due diligence at all times
          when dealing with third parties on behalf of the Company; and (3)
          report any bribery-related concerns to the Company's CEO or, in the
          case of non-employees, their usual contact at the Company.
        </p>
        <h3>REGISTER KEEPING</h3>
        <p>
          The Company pays special attention to team members and related persons
          who maintain all records relating to any contracts or business
          activities, including financial accounts and all payment transactions
          with customers, suppliers and partners, as such team members and
          related persons must conduct due diligence before entering into any
          contractual arrangements or relationship with a potential service
          provider, agent, consultant, or customer representative. In addition,
          we keep records of all financial transactions of our company and make
          sure that each transaction is properly recorded.
        </p>
        <h3>MANDATORY NOTICE</h3>
        <p>
          A facilitation payment is defined as a small payment given to an
          official to secure or expedite the performance of a normal or
          necessary function, and is a bribe. Gifts and hospitality may, if
          excessive, constitute a bribe and/or a conflict of interest.
          Charitable donations are allowed only to registered (non-profit)
          charitable organizations, i.e. no charitable donations may be made to
          any organization that is not a registered charitable organization
          (under no circumstances may charitable donations be made in cash). The
          Company does not make political donations and is not affiliated with
          any political party, independent candidate or any other organization
          whose activities are primarily political (the team and other related
          parties are free to make personal donations, provided that such
          payments are not made on behalf of the Company and are not made to
          obtain any form of advantage in any business transaction). Any of the
          above should be reported to the Company's CEO as soon as possible.
        </p>
        <h3>OBLIGATORY CHECK</h3>
        <p>
          In any transactions, agreements with officials and other business
          matters involving third parties, we should carefully consider the
          following issues: (1) territorial risks, in particular the prevalence
          of bribery and corruption in a particular country; (2) cross-border
          payment risks; (3) requests for cash, intermediary payments or other
          unusual payment methods; (4) activities requiring the Company and/or
          any related party to obtain permits or other forms of official
          authorization; (5) import or export transactions.
        </p>
        <h3>NOTICE OF SUSPICION OF BRIBERY</h3>
        <p>
          We depend on our team and those associated with them to ensure that
          the highest standards of ethical behavior are maintained in all of its
          business dealings, so we ask them to assist us and remain vigilant in
          preventing, detecting and reporting bribery. Our team and those
          associated with them are encouraged to report any concerns they may
          have to the Company's CEO as soon as possible. Report any: suspected
          or actual bribery attempts; concerns that other team members or
          associates may be bribed; or concerns that other team members or
          associates may be bribing third parties. Any reports of bribery or
          suspected bribery will be investigated thoroughly and promptly by us,
          with the involvement of our team with knowledge of the matter. Our
          team or those associated with them who report bribery in good faith
          will receive our support as a result of their report. Any mistreatment
          by a colleague because a team member has made a report will be treated
          as disciplinary action. An instruction with the intent to conceal
          wrongdoing is itself a disciplinary offense. Even if a person in a
          position of authority, such as a supervisor, tells you not to raise or
          report any concerns, the team member and those associated with them
          should not agree to remain silent. They should report it to the
          Company's CEO.
        </p>
        <h3>STORAGE OF COLLECTED INFORMATION</h3>
        <p>
          In the event that a team member or a person associated with a team
          member reports suspected bribery, we will treat any personal data
          collected in accordance with our Privacy Policy. The data collected
          from the moment a team member or a related person submits a report
          will be kept in a secure state and accessed and disclosed only for the
          purpose of processing the bribery report.
        </p>
        <h3>OUR ATTITUDE</h3>
        <p>
          We will fully investigate any incidents of alleged or suspected
          bribery that come to our attention or are reported. Team members who
          are suspected of bribery may be suspended from their duties while the
          investigation is conducted, subject to disciplinary procedures, and
          proven allegations may result in a finding of gross misconduct and
          immediate dismissal. We may at any time terminate the cooperation
          agreement with anyone who breaches the terms of this Policy, notifying
          the relevant governmental authority of the breach and providing them
          with all the details.
        </p>
        <h3>OTHER</h3>
        <p>
          This Policy has been approved by the CEO of Perpetio OÜ, if you would
          like to receive further information regarding this Policy, please
          contact the CEO of Perpetio OÜ.
        </p>

        <h3>PREAMBLE</h3>
        <p>
          Perpetio OÜ (hereinafter "the Company", "we" or "our") is committed to
          ethical corporate behaviour and to comply with all applicable laws,
          regulations and other provisions governing our business. Our
          unwavering commitment is to foster a strong anti-corruption culture
          and to ensure strict compliance with local anti-bribery and
          anti-corruption laws and regulations. We are committed to prohibiting
          any form of giving, offering, seeking or receiving bribes, corrupt
          payments, inducements or similar conduct by ourselves or anyone acting
          on our behalf. Our senior management will consistently implement
          effective measures to prevent, control and eliminate bribery. It is
          important to note that this Corporate Social Responsibility Policy
          (hereinafter - "the Policy") is not considered to be part of the
          employment contract, and we reserve the right to amend or cancel it at
          any time.
        </p>
        <h3>OUR SERVICES</h3>
        <p>
          We are a one-stop partner for full-cycle mobile application
          development. We are always ready for the next challenge: iOS
          Development Services, Android App Development Services, Flutter App
          Development Services, React Native Development, Web Development, UI/UX
          Design and other customised service packages (collectively, the
          "Services"). Our purpose is to improve performance by delivering
          unrivalled value, insight, and success to our customers.
        </p>
        <h3>OUR TEAM</h3>
        <p>
          We are committed to ensuring the well-being and sustainable
          development of our team, where each member clearly understands their
          role and contribution to the business. We ensure that each member of
          the team has the opportunity to develop according to their interests
          and talents through a clearly defined personal growth plan that
          includes appropriate training and systematic assessment points where
          tangible progress goals are met. We expect our team to treat each
          other fairly and to meet the highest standards of business ethics and
          excellence. We support diversity, equity, and equal opportunities for
          everyone, supporting them in their new learning endeavours.
        </p>
        <h3>OUR CUSTOMERS</h3>
        <p>
          We intend to develop long-lasting and meaningful relationships with
          our customers and stakeholders, ensuring that we fully understand
          their objectives, meet all requirements and exceed their expectations
          for our Services. Our goal is always to provide fair value, consistent
          quality and reliability. We are fully committed to the highest
          professional and ethical standards, and are committed to being honest,
          open, and transparent in all our interactions with customers.
        </p>
        <h3>OUR VENDORS</h3>
        <p>
          We strive to create and maintain strong relationships with key
          suppliers and contractors who share our ethics in terms of employment,
          quality and environmental controls. We have rigorous vetting processes
          in place to ensure that we work with companies and/or individuals who
          are committed to maintaining best practice and the performance of our
          Services.
        </p>
        <h3>OUR ENVIRONMENT</h3>
        <p>
          We recognise our environmental impact as an integrated, progressive
          business and continue to take appropriate measures to mitigate this
          impact, both by us and by our teams and customers, such as an
          environmental policy, which is reviewed annually and updated as
          necessary, and through which we set environmental goals and targets,
          use procedures and provide training to ensure that our teams and
          contractors understand their environmental responsibilities and can
          strive to improve our environmental performance, and ensure that we
          are committed to the environment. In addition, we are committed to
          providing a safe and healthy working environment for all our
          activities and to promoting a health and safety culture that focuses
          on maintaining the highest standards while complying with health and
          safety requirements. We make sure our team is happy and healthy at
          work, so they can enjoy time with their loved ones. This commitment
          extends to our relationships with customers and vendors.
        </p>
        <h3>PEOPLE AROUND US</h3>
        <p>
          We recognise and understand the importance of the local communities in
          which we operate, which is why we strive to increase our contribution
          to society by being aware of the needs of local people and groups. We
          are committed to the following actions: making monetary and time
          donations to local volunteer charities, and supporting the surrounding
          community by employing local people and providing opportunities for
          young people from our local communities through training and work
          experience.
        </p>
        <h3>OTHER</h3>
        <p>
          This Policy has been approved by the CEO of Perpetio OÜ, if you would
          like to receive further information regarding this Policy, please
          contact the CEO of Perpetio OÜ.
        </p>
      </div>
    </ResponsiveBox>
  </div>
);

export default AntiBriberyPolicy;
