import React from 'react';
import './main.scss';
import Breadcrumbs from '../Breadcrumbs/breadcrumbs';

const Main = ({ title, text, hideBreadcrumbs }) => {
  return (
    <div className="main">
      {hideBreadcrumbs ? null : <Breadcrumbs />}
      <div className="main_content_box">
        <h1 dangerouslySetInnerHTML={{ __html: title }} />
        <p dangerouslySetInnerHTML={{ __html: text }} />
      </div>
    </div>
  );
};

export default Main;
