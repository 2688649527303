import React from 'react';
import { remoteStorageUrl } from '../../../config';
import LazyImage from '../../UI/LazyImage';

const AppStore = `${remoteStorageUrl}/resources/images/btn-download-apple.svg`;
const GooglePlay = `${remoteStorageUrl}/resources/images/btn-download-android.svg`;
const GoogleComingSoon = `${remoteStorageUrl}/resources/images/google_coming.svg`;
const AppStoreComingSoon = `${remoteStorageUrl}/resources/images/appstore_coming.svg`;

const Hero2023 = ({ translation }) => {
  const {
    appstore,
    logoPath,
    googleplay,
    heroText,
    heroTextMobile,
    heroImagePath,
  } = translation;

  const heroImagePaths = heroImagePath.split('/');

  return (
    <div className="projects-hero">
      <div className="projects-hero__container">
        <div className="projects-hero__container__logo">
          <img src={`${remoteStorageUrl}/${logoPath}`} alt="logo" />
        </div>
        <div className="projects-hero__container__text">
          <p className="description-desktop">{heroText}</p>
          <p className="description-mobile">{heroTextMobile || heroText}</p>

          <div className="projects-hero__container__text__buttons">
            {appstore ? (
              <a href={appstore} target="_blank" rel="noopener noreferrer">
                <img src={AppStore} alt="app-store" />
              </a>
            ) : appstore !== null ? (
              <img src={AppStoreComingSoon} />
            ) : null}
            {googleplay ? (
              <a href={googleplay} target="_blank" rel="noopener noreferrer">
                <img src={GooglePlay} alt="google-play" />
              </a>
            ) : googleplay !== null ? (
              <img src={GoogleComingSoon} />
            ) : null}
          </div>
        </div>
        <div className="projects-hero__container__image">
          {heroImagePath.match('webp') ? (
            <LazyImage
              imageLink={heroImagePaths[heroImagePaths.length - 1]}
              path={heroImagePaths
                .slice(0, heroImagePaths.length - 1)
                .join('/')}
              alt=""
            />
          ) : (
            <img src={`${remoteStorageUrl}/${heroImagePath}`} alt="hero" />
          )}
        </div>
      </div>
    </div>
  );
};

export default Hero2023;
