import React from 'react';

import './numberedList.scss';
import FadeUp from '../../Animations/FadeUp';
import ResponsiveBox from '../../UI/ResponsiveBox';
import Medium16px from '../../UI/texts/16pxMedium';
import BlockHeader from '../../UI/texts/BlockHeader';

const NumberedList = ({ title, subtitle, items }) => {
  return (
    <ResponsiveBox>
      <div className="list-wrapper">
        <FadeUp className="list-titleBox">
          <BlockHeader
            dangerouslySetInnerHTML={{
              __html: title,
            }}
          />
          {subtitle ? (
            <Medium16px
              style={{
                textAlign: 'center',
                color: 'var(--p-text-color)',
                paddingTop: '8px',
              }}
              dangerouslySetInnerHTML={{
                __html: subtitle,
              }}
            />
          ) : null}
        </FadeUp>
        <div className="list-container">
          {items.map((item, index) => {
            return (
              <FadeUp key={index} className="list-item">
                <div className="list-number">0{index + 1}</div>

                <div className="list-item-content">
                  <div>
                    <BlockHeader
                      dangerouslySetInnerHTML={{
                        __html: item.title,
                      }}
                    />
                  </div>

                  <div>
                    {item.text ? <Medium16px>{item.text}</Medium16px> : <div />}
                  </div>
                </div>
              </FadeUp>
            );
          })}
        </div>
      </div>
    </ResponsiveBox>
  );
};

export default NumberedList;
