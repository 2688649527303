import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import './productMain.scss';
import { Image } from '../../../Image/Image';
import { remoteStorageUrl } from '../../../../config';

const ProductMain = ({text}) => {
  return (
    <Container className="product-main-big-ticket" fluid>
      <div className="product-main-wrapper-big-ticket" style={{
        background: `url(${remoteStorageUrl}/public/big-ticket/main/${text.product_main.bg}) no-repeat`
      }}>
        <div className="mobile-wrapper" style={{
            background: `linear-gradient(201.17deg, rgba(82, 44, 0, 0.6) 11.33%, rgba(35, 35, 35, 0.13) 87.88%), url(/big-ticket/main/${text.product_main.mBg}) no-repeat`
          }}>
          <Row className="product-main-row-big-ticket">
            <Col xs={6} md={7} xl={7} className="product-main-col-desktop align-self-center">
              <h1 className="product-main-title-big-ticket desktop">{text.product_main.title}</h1>
              <p className="product-main-text-big-ticket desktop">{text.product_main.text}</p>
            </Col>
            <Col sm={9} md={5} xl={5} className="product-main-col-big-ticket">
              <Image className="product-main-img-big-ticket" src={`big-ticket/main/${text.product_main.image}`} />
            </Col>
          </Row>
        </div>
        <div className="mobile-product-main-row-big-ticket">
          <p className="product-main-title-big-ticket mobile">{text.product_main.title}</p>
          <p className="product-main-text-big-ticket mobile">{text.product_main.text}</p>
        </div>
      </div>
    </Container>
  );
}

export default ProductMain;