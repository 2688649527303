import React from 'react';

import './checkBox.scss';
import Medium16px from '../../texts/16pxMedium';

const CheckBoxInput = (props) => {
  const { field, form, text } = props;
  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="15"
        height="15"
        viewBox="0 0 15 15"
        // fill="#fff"
        style={{ display: 'none' }}
      >
        <symbol id="checkmark">
          <path
            d="M8.72558 17.891C9.31355 17.891 9.77183 17.6662 10.0831 17.1907L18.1418 4.86918C18.3666 4.52332 18.4617 4.20339 18.4617 3.90076C18.4617 3.08797 17.8564 2.5 17.0264 2.5C16.4557 2.5 16.1012 2.70752 15.7554 3.25226L8.68235 14.4064L5.09399 9.95341C4.77406 9.57295 4.4282 9.40003 3.94399 9.40003C3.10526 9.40003 2.5 9.99664 2.5 10.8094C2.5 11.1726 2.61241 11.4925 2.92369 11.847L7.38535 17.2512C7.75716 17.6921 8.1722 17.891 8.72558 17.891Z"
            // fill="none"
          />
        </symbol>
      </svg>

      <div className="promoted-checkbox">
        <input
          id="tmp"
          name={field.name}
          checked={field.value}
          className="promoted-input-checkbox"
          onChange={(event) => {
            form.setFieldValue(field.name, event.target.checked);
          }}
          type="checkbox"
        />
        <label htmlFor="tmp">
          <svg>
            <use xlinkHref="#checkmark" />
          </svg>
        </label>
        <Medium16px>{text}</Medium16px>
      </div>
    </>
  );
};

export default CheckBoxInput;
