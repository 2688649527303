import React from "react";

import "./reverseBox.scss";
import { Container, Row, Col } from "reactstrap";
import ImageSrcSet from "../../../ImageSrcSet";
import FadeUp from "../../../Animations/FadeUp";

const ReverseBox = ({ data, reverse }) => {
  return (
    <Container fluid className="reverseContainer">
      <FadeUp className={`reverseBoxFade`}>
        <Row sx={2} className={`contentBox ${reverse ? "reverse" : ""}`}>
          <Col
            className="imageBox"
            style={{
              paddingLeft: !reverse ? 0 : "",
            }}
          >
            <ImageSrcSet pathObj={data.imagePaths} />
          </Col>
          <Col
            className="textBox"
            style={{
              paddingLeft: reverse ? 0 : "",
            }}
          >
            <div>{data.title}</div>
            <span dangerouslySetInnerHTML={{ __html: data.text }} />
          </Col>
        </Row>
      </FadeUp>
    </Container>
  );
};

export default ReverseBox;
