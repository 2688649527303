import * as React from 'react';

const SvgArrowUp = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.55066 14.8246C6.40066 14.6746 6.32149 14.4996 6.31316 14.2996C6.30482 14.0996 6.37566 13.9246 6.52566 13.7746L11.4757 8.82461C11.559 8.74128 11.6423 8.68294 11.7257 8.64961C11.809 8.61628 11.9007 8.59961 12.0007 8.59961C12.1007 8.59961 12.1923 8.61628 12.2757 8.64961C12.359 8.68294 12.4423 8.74128 12.5257 8.82461L17.4757 13.7496C17.6257 13.8829 17.7007 14.0538 17.7007 14.2621C17.7007 14.4704 17.6257 14.6496 17.4757 14.7996C17.3257 14.9496 17.1465 15.0246 16.9382 15.0246C16.7298 15.0246 16.5507 14.9496 16.4007 14.7996L12.0007 10.4246L7.60066 14.8496C7.46732 14.9996 7.29649 15.0704 7.08816 15.0621C6.87982 15.0538 6.70066 14.9746 6.55066 14.8246Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default SvgArrowUp;
