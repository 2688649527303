import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import './ourScope.scss';
import text from '../../../../translation.json';
import ResponsiveBox from '../../../UI/ResponsiveBox';
import ShowMoreText from '../../../ShowMoreText/ShowMoreText';
import Heavy24px from '../../../UI/texts/24pxHeavy';
import FadeUp from '../../../Animations/FadeUp';
import PlusIcon from '../../../icons/plusIcon';
import BlockHeader from '../../../UI/texts/BlockHeader';

const OurScope = ({ title, items, linkText = '', cdnUrl, topGap = true }) => {
  const mediaMatch =
    typeof window === 'undefined'
      ? null
      : window.matchMedia('(max-width: 768px)');

  const [matches, setMatches] = useState(mediaMatch && mediaMatch.matches);

  useEffect(() => {
    const handler = (e) => setMatches(e.matches);
    mediaMatch && mediaMatch.addEventListener('change', handler);
    return () =>
      mediaMatch && mediaMatch.removeEventListener('change', handler);
  }, [mediaMatch]);

  return (
    <ResponsiveBox topGap={topGap}>
      <div className="our-scope-container">
        <FadeUp className={'our-scope__title-box'}>
          <BlockHeader
            dangerouslySetInnerHTML={{
              __html: title,
            }}
          />
        </FadeUp>

        <div className="our-scope">
          {items.map((item, index) => {
            const imageName = item.img.split('.')[0];
            const imageExt = item.img.split('.')[1];
            const image2x = `${cdnUrl}/${imageName}@2x.${imageExt}`;
            const image3x = `${cdnUrl}/${imageName}@3x.${imageExt}`;
            const image4x = `${cdnUrl}/${imageName}@4x.${imageExt}`;
            return (
              <FadeUp key={index} className="our-scope-item">
                <div className="our-scope-image">
                  <img
                    srcSet={`${image2x} 1x, ${image2x} 2x, ${image3x} 3x, ${image4x} 4x,`}
                    alt={imageName}
                  />
                </div>
                <ShowMoreText
                  id={'our-scope-' + index}
                  text={item.text}
                  enabled={matches}
                  wordsCount={29}
                />
              </FadeUp>
            );
          })}
        </div>
        <FadeUp className="all-cases_box">
          <Heavy24px>
            <Link
              style={{ display: 'flex', alignItems: 'center', gap: '1rem' }}
              className="all-cases-btn"
              to={text.products_new.link.url}
            >
              {linkText || text.products_new.link.text} <PlusIcon />
            </Link>
          </Heavy24px>
        </FadeUp>
      </div>
    </ResponsiveBox>
  );
};

export default OurScope;
