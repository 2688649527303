import * as React from "react";

function SvgArrowsRight() {
  return (
    <svg
      width="5"
      height="10"
      viewBox="0 0 5 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.0347881 9.71711C0.118323 9.90463 0.254652 9.99856 0.445327 9.99997C0.534389 10.0006 0.589089 9.98929 0.650755 9.95739C0.715894 9.92369 1.20078 9.39857 2.80775 7.62137C3.94788 6.36048 4.9034 5.29397 4.93113 5.25137C5.02296 5.11027 5.02296 4.8912 4.93113 4.7501C4.9034 4.70749 3.94788 3.64099 2.80775 2.38009C1.20078 0.602899 0.715895 0.077776 0.650755 0.044076C0.536948 -0.0148096 0.355013 -0.0146732 0.247462 0.0443697C0.0205577 0.168933 -0.0683927 0.474401 0.057292 0.697421C0.0814765 0.740338 0.95815 1.72334 2.00542 2.88189C3.05271 4.04041 3.90959 4.9939 3.90959 5.00073C3.90959 5.00756 3.05271 5.96105 2.00542 7.11958C0.95815 8.27812 0.0810468 9.26189 0.0563394 9.30575C-0.00807168 9.42003 -0.017484 9.59975 0.0347881 9.71711Z"
        fill="#7C7C87"
      />
    </svg>
  );
}

export default SvgArrowsRight;
