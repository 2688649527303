import React from 'react';
import './text.scss';

const Medium52px = ({ children, ...rest }) => {
  return (
    <h1 {...rest} className="medium52px">
      {children}
    </h1>
  );
};

export default Medium52px;
