import React from 'react';
import withRouter from '../../utils/withRouter';
import Footer from '../../components/Footer/Footer';
import './wrapper.scss';

const STYLES = {
  dark: {
    background: 'none',
  },
  light: {
    background:
      'linear-gradient(165deg, rgb(243, 242, 255) 7.04%, rgb(232, 255, 254) 38.65%, rgb(255, 255, 255) 64.28%)',
  },
  default: {
    background: 'none',
  },
};

const Wrapper = (props) => {
  const url = props.history.location.pathname;
  let showMainBackground = false;
  let showDarkBackground = false;

  switch (url) {
    case '/use-cases': {
      showMainBackground = true;
      break;
    }
    case '/services': {
      showMainBackground = true;
      break;
    }
    case '/about': {
      showMainBackground = true;
      break;
    }
    case '/services/android': {
      showMainBackground = true;
      break;
    }
    case '/services/ios': {
      showMainBackground = true;
      break;
    }
    case '/services/flutter': {
      showMainBackground = true;
      break;
    }
    case '/services/ui-ux': {
      showDarkBackground = true;
      break;
    }
    case '/location-based': {
      showMainBackground = true;
      break;
    }
    case '/fitness-wellness': {
      showMainBackground = true;
      break;
    }
    case '/wearable-app': {
      showMainBackground = true;
      break;
    }
    case '/ar-vr': {
      showMainBackground = true;
      break;
    }
    case '/iot': {
      showMainBackground = true;
      break;
    }
    case '/healthcare': {
      showMainBackground = true;
      break;
    }
    case '/social-networks': {
      showMainBackground = true;
      break;
    }
    case '/services/team-augmentation': {
      showMainBackground = true;
      break;
    }
    case '/services/digitalization': {
      showMainBackground = true;
      break;
    }
    case '/ecommerce': {
      showMainBackground = true;
      break;
    }
    case '/services/startups-mobile-development': {
      showMainBackground = true;
      break;
    }
    case '/sports': {
      showMainBackground = true;
      break;
    }
    case '/services/full-cycle': {
      showMainBackground = true;
      break;
    }
    case '/privacy-policy': {
      showMainBackground = true;
      break;
    }
    case '/equality-policy': {
      showMainBackground = true;
      break;
    }
    case '/antislavery-policy': {
      showMainBackground = true;
      break;
    }
    case '/environmental-policy': {
      showMainBackground = true;
      break;
    }
    case '/anti-bribery-policy': {
      showMainBackground = true;
      break;
    }
    case '/corporate-policy': {
      showMainBackground = true;
      break;
    }
    default: {
      showMainBackground = false;
    }
  }

  if (['/careers'].some((el) => url.includes(el))) {
    showMainBackground = true;
  }

  let styles = STYLES.default;
  if (showDarkBackground) {
    styles = STYLES.dark;
    if (typeof window !== 'undefined') {
      document.querySelector('body').style.background = '#020220';
    }
  } else {
    if (showMainBackground) {
      styles = STYLES.light;
    }
    if (typeof window !== 'undefined') {
      document.querySelector('body').style.background = 'unset';
    }
  }

  return (
    <div className={showDarkBackground ? 'wrapper wrapper-dark' : 'wrapper'}>
      {showDarkBackground ? <div className="main-bg-dark" /> : null}
      <div
        className="main-bg"
        style={{
          height: url === '/contact' ? '100%' : '100vh',
          ...styles,
        }}
      />
      {props.children}
      <div
        className="footer-bg"
        style={{ display: props.notFound ? 'none' : 'block' }}
      >
        <footer className="footer">
          <div className="max-width-container ">
            <div className="responsive-container responsive-page-top_bottom-gap">
              <Footer isDarkTheme={showDarkBackground} />
            </div>
          </div>
        </footer>
      </div>
    </div>
  );
};

export default withRouter(Wrapper);
