import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import { Image } from '../../../Image/Image';
import './productDescription.scss';

const ProductDescription = ({ text }) => {
  return (
    <Container className="product-description" fluid>
      <Row className="product-description-row">
        <Col sm={6}>
          <h3 className="title mb-2 mb-md-4">
            {text.product_description.title}
          </h3>
          <p
            className="description"
            dangerouslySetInnerHTML={{ __html: text.product_description.text }}
          />
        </Col>
        <Col sm={5} align="right">
          <Image
            className="product-description-img-aob mt-5"
            src={`aob/design/${text.product_description.image}`}
          />
        </Col>
      </Row>
    </Container>
  );
};

export default ProductDescription;
