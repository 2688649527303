import React from 'react';
import { Container } from 'reactstrap';
import './commitSlider.scss';
import Slider from "react-slick";
import LazyLoad from 'react-lazyload';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { remoteStorageUrl } from '../../../../config';

const CommitSlider = ({text}) => {
  const product = (text.folder === "product");
  const titleColor = () => product ? { color: "#B6EAB0" } : null;
  const setHeight = (phone) => {
    if(product) {
      return ({
        height: phone ? "40vw" : "32vw"
      });
    }

    return ({
      height: phone ? "35vw" : "30vw"
    });
  }
  const settings = {
    className: "center",
    centerMode: true,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 2000,
    speed: 500,
    centerPadding: "60px",
    slidesToShow: 4,
    initialSlide: 2,
    swipeToSlide: true,
    touchThreshold: 50,
    buttons: false,
    draggable: true,
    variableHeight: true,
    responsive: [
      {
        breakpoint: 575,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 0,
          centerPadding: "60px"
        }
      }
    ]
    };

    return (
        <Container className="commit-onboarding-slider my-3 p-0" fluid>
          <h2 style={titleColor()} className="commit-onboarding-title">{text.title}</h2>
          <div className="commit-onboarding-margin">
            <Slider {...settings}>
                {text.images.map((slide,i) => (
                    <div key={i}>
                      <LazyLoad once offset={1000}>
                        <img style={setHeight(slide === "phone.png")} className="commit-onboarding-slider-img" src={`${remoteStorageUrl}/resources/images/commit/${text.folder}/${slide}`} alt="slide" />
                      </LazyLoad>
                    </div>
                ))}
            </Slider>
          </div>
        </Container>
    );
};

export default CommitSlider;
