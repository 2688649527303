import React from 'react';

import './workProcess.scss';
import FadeUp from '../../../Animations/FadeUp';
import ResponsiveBox from '../../../UI/ResponsiveBox';
import Heavy20px from '../../../UI/texts/20pxHeavy';
import BlockHeader from '../../../UI/texts/BlockHeader';

const WorkProcess = ({ title, items, cdnUrl }) => {
  return (
    <ResponsiveBox topGap={false}>
      <div className="work-process">
        <FadeUp>
          <BlockHeader
            dangerouslySetInnerHTML={{
              __html: title,
            }}
          />
        </FadeUp>
        <div className="work-process-wrapper">
          <div className="work-process-container">
            {items.slice(0, 3).map((item, index) => {
              return (
                <FadeUp
                  key={index}
                  className="work-process-item"
                  transition={`all 700ms ease-in-out 0.${index}s`}
                >
                  <img src={`${cdnUrl}${item.img}`} alt={item.title} />
                  <div className="work-process-line"></div>
                  <span>{index + 1}</span>
                  <Heavy20px>{item.title}</Heavy20px>
                </FadeUp>
              );
            })}
          </div>
          <div className="work-process-container">
            {items.slice(3, 6).map((item, index) => {
              return (
                <FadeUp
                  key={index}
                  className="work-process-item"
                  transition={`all 700ms ease-in-out 0.${index + 4}s`}
                >
                  <img src={`${cdnUrl}${item.img}`} alt={item.title} />
                  <div className="work-process-line"></div>
                  <span>{index + 4}</span>
                  <Heavy20px>{item.title}</Heavy20px>
                </FadeUp>
              );
            })}
          </div>
        </div>
      </div>
    </ResponsiveBox>
  );
};

export default WorkProcess;
