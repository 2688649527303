import React from "react";
import { Col } from "reactstrap";
import "./description.scss";
import FadeUp from "../../../Animations/FadeUp";
import { useParams } from "react-router";

const Description = ({ text }) => {
  const { career } = useParams();

  return (
    <Col lg={6} className="careers-description-wrapper">
      <FadeUp className="careers-description-block">
        <h4 className="title">{text.looking.title}</h4>
        <p
          className="description"
          dangerouslySetInnerHTML={{ __html: text.looking.text }}
        />
      </FadeUp>
      <FadeUp className="careers-description-block">
        <h4 className="title">{text.requirements.title}</h4>
        <ul className="list">
          {text.requirements.items.map((item, index) => (
            <li key={index}>{item}</li>
          ))}
        </ul>
      </FadeUp>
      {text.perfect ? (
        <FadeUp className="careers-description-block">
          <h4 className="title">{text.perfect.title}</h4>
          <ul className="list">
            {text.perfect.items.map((item, index) => (
              <li key={index} dangerouslySetInnerHTML={{ __html: item }} />
            ))}
          </ul>
        </FadeUp>
      ) : null}
      <FadeUp className="careers-description-block">
        <h4 className="title">{text.additional.title}</h4>
        <ul className="list">
          {text.additional.items.map((item, index) => (
            <li key={index}>{item}</li>
          ))}
        </ul>
      </FadeUp>
      {text.responsibilities ? (
        <FadeUp className="careers-description-block">
          <h4 className="title">{text.responsibilities.title}</h4>
          <ul className="list">
            {text.responsibilities.items.map((item, index) => (
              <li key={index} dangerouslySetInnerHTML={{ __html: item }} />
            ))}
          </ul>
        </FadeUp>
      ) : null}
      <FadeUp className="careers-description-block">
        <h4 className="title">{text.offer.title}</h4>
        <ul className="list">
          {text.offer.items.map((item, index) => (
            <li key={index} dangerouslySetInnerHTML={{ __html: item }} />
          ))}
        </ul>
      </FadeUp>
    </Col>
  );
};

export default Description;
