import React from 'react';
import { Helmet } from 'react-helmet-async';
import { useLocation } from 'react-router-dom';

import { remoteStorageUrl } from '../../config';
import text from '../../translation.json';
import Steps from '../Steps/Steps';
import Form from '../../components/Shared/Form';
import OurServices from '../../components/OurServices/OurServices';
import ServiceHeadWithImage from '../../components/Services/HeadWithImage';
import ProjectsCompletedX3 from '../../components/Services/Shared/ProjectCompletedx3';

const ReferralPage = ({ data }) => {
  const location = useLocation();
  const formRef = React.useRef(null);

  const cdnUrl = `${remoteStorageUrl}/resources/images/referral/`;
  const heroImagePath = `${cdnUrl}/hero.svg`;

  const executeScroll = () => {
    formRef.current.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    });
  };

  return (
    <>
      <Helmet>
        <title>{text.metaTags.referral.title}</title>
        <meta name="description" content={text.metaTags.referral.description} />
        <link
          rel="canonical"
          href={`${process.env.REACT_APP_SITE_URL}${location.pathname}`}
        />
      </Helmet>
      <ServiceHeadWithImage
        data={data.head}
        imageUrl={heroImagePath}
        hideBreadcrumbs
        buttonHref={null}
        onButtonClick={executeScroll}
      />
      <ProjectsCompletedX3 data={data.projects_completed} cdnUrl={cdnUrl} />
      <Steps data={data.steps} />
      <OurServices
        data={data.our_services}
        cdnUrl={`${remoteStorageUrl}/resources/images/new-services/`}
      />

      <Form text={data.form} forwardRef={formRef} />
    </>
  );
};

export default ReferralPage;
