import React from 'react';
import './portfolioLoading.scss';

const PortfolioLoading = () => {
  return (
    <div className="loading-wrapper">
        <div className="column one">
            <div className="text">
                <div className="text-line"></div>
                <div className="text-line"></div>
                <div className="text-line"></div>
                <div className="text-line"></div>
            </div>
        </div>
        <div className="column two">
            <div className="image"></div>
        </div>
    </div>
  );
};

export default PortfolioLoading;