import * as React from "react";

function PlusIcon({...rest}) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <rect
        x="10.2856"
        width="3.42857"
        height="24"
        rx="1.71429"
        fill="#3095F2"
      />
      <rect
        x="24"
        y="10.2857"
        width="3.42857"
        height="24"
        rx="1.71429"
        transform="rotate(90 24 10.2857)"
        fill="#3095F2"
      />
    </svg>
  );
}

export default PlusIcon;
