import React from 'react';

const TechnicalDetails = ({ technical_details }) => {
  return (
    <div className="case-technical-details">
      <h4>Technical Details</h4>
      <div className="case-technical-details-grid">
        {technical_details.map((columns, index) => (
          <div key={index} className="case-technical-details-col">
            {columns.map((item) => (
              <div key={item.title}>
                {item.title ? (
                  <>
                    <strong>{item.title}:</strong>
                    <p
                      dangerouslySetInnerHTML={{
                        __html: item.description,
                      }}
                    />
                  </>
                ) : null}
              </div>
            ))}
          </div>
        ))}
      </div>
    </div>
  );
};

export default TechnicalDetails;
