import { useLocation } from 'react-router-dom';
import React from 'react';
import { Helmet } from 'react-helmet-async';
import text from '../../../translation.json';
import ServiceHead from '../../../components/Services/Head';
import ProjectsCompleted from '../../../components/Services/Shared/ProjectCompleted';
import { remoteStorageUrl } from '../../../config';
import Benefit from '../../../components/Services/LocationBased/benefit';
import GeolocationApp from '../../../components/Services/Shared/ImageSlider';
import DeliverTopNotch from '../../../components/Services/Shared/ClientLoves';
import Cases from '../../../components/Services/Shared/Cases';
import CompanyServices from '../../../components/Shared/CompanyServices';
import Team from '../../../components/Services/Shared/Team';
import WorkProcess from '../../../components/Services/Shared/WorkProcess/WorkProcess';
import Form from '../../../components/Shared/Form';
import FAQ from '../../../components/Home/FAQ';

const LocationBasedApp = ({ data }) => {
  const location = useLocation();
  return (
    <>
      <Helmet>
        <title>{text.metaTags.locationBased.title}</title>
        <meta
          name="description"
          content={text.metaTags.locationBased.description}
        />
        <link
          rel="canonical"
          href={`${process.env.REACT_APP_SITE_URL}${location.pathname}`}
        />
      </Helmet>
      <ServiceHead data={data.head} />
      <ProjectsCompleted
        data={data.projects_completed}
        cdnUrl={`${remoteStorageUrl}/resources/images/services_page/location-based/`}
      />
      <Benefit
        data={data.benefits}
        cdnUrl={`${remoteStorageUrl}/resources/images/services_page/location-based/`}
      />
      <GeolocationApp
        data={data.geolocation}
        cdnUrl={`${remoteStorageUrl}/resources/images/services_page/location-based/`}
        path={'resources/images/services_page/location-based'}
        style={{
          background:
            'linear-gradient(145deg, #F3F2FF 0%, #E8FFFE 55.21%, #F5F6FF 100%)',
          transform: 'rotate(180deg)',
        }}
      />
      <DeliverTopNotch
        title={data.pickTheTop.title}
        items={data.pickTheTop.items}
        cdnUrl={`${remoteStorageUrl}/resources/images/services_page/location-based/pickTheTop/`}
      />
      <Cases text={data.cases} threeBlockLayout={true} />
      <CompanyServices
        text={data.company_services}
        style={{ background: '#3095F2' }}
        textColor={'white'}
      />
      <Team
        title={data.team.title}
        items={data.team.items}
        cdnUrl={`${remoteStorageUrl}/resources/images/services_page/location-based/team/`}
      />
      <WorkProcess
        title={data.work_process.title}
        items={data.work_process.items}
        cdnUrl={`${remoteStorageUrl}/resources/images/services_page/cross-platform/`}
      />
      <Form text={data.form} />

      <FAQ text={data.faq} />
    </>
  );
};

export default LocationBasedApp;
