import React from 'react';

import './Clover.scss';
import FadeUp from '../../../Animations/FadeUp';
import LazyLoad from 'react-lazyload';
import ResponsiveBox from '../../../UI/ResponsiveBox';
import BlockHeader from '../../../UI/texts/BlockHeader';
import Medium16px from '../../../UI/texts/16pxMedium';
import Medium24px from '../../../UI/texts/24pxMedium';

const Clover = ({ title, subtitle, items, Icon, cdnUrl }) => {
  return (
    <ResponsiveBox>
      <div className="clover-wrapper">
        <FadeUp className="clover-titleBox">
          <BlockHeader
            dangerouslySetInnerHTML={{
              __html: title,
            }}
          />
          {subtitle ? (
            <Medium24px
              style={{
                textAlign: 'center',
                color: 'var(--p-text-color)',
                paddingTop: '8px',
              }}
              dangerouslySetInnerHTML={{
                __html: subtitle,
              }}
            />
          ) : null}
        </FadeUp>
        <div
          className={`clover-container ${
            items.length === 3 ? 'three-leaf-clover' : ''
          }`}
        >
          {items.map((item, index) => {
            return (
              <FadeUp
                key={index}
                className={`clover-leaf${!item.text ? ' leaf-centered' : ''}`}
              >
                {item.img ? (
                  <LazyLoad once offset={100}>
                    <img src={`${cdnUrl}${item.img}`} alt={item.title} />
                  </LazyLoad>
                ) : null}
                <div>
                  <BlockHeader
                    dangerouslySetInnerHTML={{
                      __html: item.title,
                    }}
                    variant="h4"
                  />

                  {item.text ? (
                    <Medium16px style={{ color: '#4B4B4B' }}>
                      {item.text}
                    </Medium16px>
                  ) : null}
                </div>
              </FadeUp>
            );
          })}
          <LazyLoad once offset={100} className="clover-image">
            {Icon}
          </LazyLoad>
        </div>
      </div>
    </ResponsiveBox>
  );
};

export default Clover;
