import React from "react";
import Select, { components } from "react-select";
import SvgBlueArrowsRight from "../../../icons/blueArrow_right";

import "./selectInput.scss";

const SelectInput = (props) => {
  const { field, form, options, placeholder, meta } = props;

  const defaultValue = (options, value) => {
    return options.find((option) => option.value === value);
  };

  const borderStyle = {
    control: (base) => ({
      ...base,
      border: !!meta.error && !!meta.touched ? "1px solid red" : "none",
      boxShadow: "none",
    }),
  };

  return (
    <div className="select_form_group">
      <Select
        components={{ DropdownIndicator }}
        options={options}
        className="react-custom-select-container "
        classNamePrefix="react-custom-select"
        isSearchable={true}
        placeholder={placeholder}
        value={defaultValue(options, field.value)}
        onChange={(option) => {
          form.setFieldValue(field.name, option.value);
        }}
        styles={borderStyle}
      />
      {!!meta.error && !!meta.touched ? (
        <div className="error_box">{meta.error}</div>
      ) : null}
    </div>
  );
};

const DropdownIndicator = ({ ...props }) => {
  return (
    <components.DropdownIndicator {...props} className="arrow_box">
      <SvgBlueArrowsRight />
    </components.DropdownIndicator>
  );
};

export default SelectInput;
