import React from "react";

const Logo = ({ color, colorB }) => (
  <svg
    width="106"
    height="36"
    viewBox="0 0 106 36"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0)">
      <path
        className={color}
        d="M11.5318 1.94467C10.8379 1.21954 10.0449 0.72513 9.18579 0.428486C8.32668 0.131842 7.33541 0 6.17893 0H0V27.6538H4.1303V16.8428H6.24501C7.79801 16.8428 9.02057 16.5791 9.9788 16.0847C10.937 15.5903 11.664 14.8652 12.2587 13.9752C12.7544 13.2171 13.0517 12.4261 13.217 11.5362C13.3491 10.6792 13.4483 9.62445 13.4483 8.40492C13.4483 6.75689 13.3161 5.43848 13.0517 4.44966C12.7213 3.52677 12.2257 2.6698 11.5318 1.94467ZM9.45012 10.3825C9.41708 10.9429 9.28491 11.4373 9.05362 11.8328C8.82232 12.2283 8.4919 12.5579 8.0293 12.7887C7.56671 13.0194 6.9389 13.1512 6.0798 13.1512H4.09726V3.72453H6.27806C7.07107 3.72453 7.69888 3.85637 8.12843 4.0871C8.55798 4.31782 8.8884 4.68039 9.08666 5.10887C9.28491 5.53736 9.41708 6.03176 9.45012 6.62505C9.48317 7.21834 9.51621 7.81163 9.51621 8.47084C9.51621 9.16301 9.48317 9.78926 9.45012 10.3825Z"
        fill="white"
      />
      <path
        className={color}
        d="M26.0374 9.49251C25.5087 8.93218 24.8478 8.5037 24.0879 8.20705C23.3279 7.91041 22.4688 7.74561 21.5106 7.74561C20.255 7.74561 19.1646 8.00929 18.2394 8.53666C17.3142 9.06402 16.6203 9.75619 16.1247 10.5802C15.8273 11.1076 15.629 11.7009 15.5299 12.3601C15.4308 13.0193 15.3647 13.7774 15.3647 14.5684V21.0616C15.3647 21.8527 15.4308 22.6108 15.5299 23.27C15.629 23.9292 15.8273 24.5225 16.1247 25.0498C16.6203 25.9068 17.3142 26.599 18.2394 27.0934C19.1646 27.6208 20.255 27.8844 21.5106 27.8844C22.4688 27.8844 23.2949 27.7196 24.0548 27.423C24.8148 27.1264 25.4426 26.6979 26.0043 26.1375C26.533 25.6102 26.9626 24.9839 27.2269 24.2588C27.5243 23.5337 27.6565 22.7426 27.6565 21.9186H23.5592C23.4931 22.3471 23.3609 22.8085 23.0966 23.237C22.8323 23.6655 22.3366 23.8962 21.5436 23.8962C20.9158 23.8962 20.4202 23.7314 20.0567 23.4018C19.6932 23.0722 19.495 22.5778 19.495 21.9516V19.2818H27.6565V13.8762C27.6565 13.0193 27.5243 12.2282 27.2269 11.4701C26.9626 10.712 26.5661 10.0528 26.0374 9.49251ZM23.5592 16.1835H19.495V13.9422C19.495 13.1841 19.6932 12.6238 20.0567 12.2612C20.4202 11.8986 20.9158 11.7009 21.5436 11.7009C22.1714 11.7009 22.6671 11.8986 23.0305 12.2612C23.394 12.6238 23.5922 13.1841 23.5922 13.9422V16.1835H23.5592Z"
        fill="white"
      />
      <path
        className={color}
        d="M68.9925 9.49251C68.4638 8.93218 67.803 8.5037 67.043 8.20705C66.283 7.91041 65.4239 7.74561 64.4657 7.74561C63.2101 7.74561 62.1197 8.00929 61.1945 8.53666C60.2693 9.06402 59.5754 9.75619 59.0798 10.5802C58.7824 11.1076 58.5842 11.7009 58.485 12.3601C58.3859 13.0193 58.3198 13.7774 58.3198 14.5684V21.0616C58.3198 21.8527 58.3859 22.6108 58.485 23.27C58.5842 23.9292 58.7824 24.5225 59.0798 25.0498C59.5754 25.9068 60.2693 26.599 61.1945 27.0934C62.1197 27.6208 63.2101 27.8844 64.4657 27.8844C65.4239 27.8844 66.25 27.7196 67.01 27.423C67.77 27.1264 68.3978 26.6979 68.9595 26.1375C69.4882 25.6102 69.9177 24.9839 70.182 24.2588C70.4794 23.5337 70.6116 22.7426 70.6116 21.9186H66.4813C66.4152 22.3471 66.283 22.8085 66.0187 23.237C65.7544 23.6655 65.2587 23.8962 64.4657 23.8962C63.8379 23.8962 63.3423 23.7314 62.9788 23.4018C62.6153 23.0722 62.4171 22.5778 62.4171 21.9516V19.2818H70.5786V13.8762C70.5786 13.0193 70.4464 12.2282 70.149 11.4701C69.9177 10.712 69.5212 10.0528 68.9925 9.49251ZM66.5143 16.1835H62.4501V13.9422C62.4501 13.1841 62.6484 12.6238 63.0118 12.2612C63.3753 11.8986 63.8709 11.7009 64.4988 11.7009C65.1266 11.7009 65.6222 11.8986 65.9857 12.2612C66.3491 12.6238 66.5474 13.1841 66.5474 13.9422V16.1835H66.5143Z"
        fill="white"
      />
      <path
        className={color}
        d="M79.8965 11.9978V8.89947H77.3522V2.07666L73.1558 3.72468L73.2219 7.97658V11.0749V23.0395C73.2219 23.5669 73.321 24.0942 73.4862 24.6546C73.6515 25.2149 73.9488 25.7093 74.3123 26.1378C74.6758 26.5663 75.2045 26.9288 75.8323 27.2255C76.4601 27.5221 77.2531 27.654 78.2113 27.654H79.8965V23.9294C79.4339 23.9294 79.0374 23.9294 78.7069 23.8965C78.3765 23.8635 78.1122 23.7976 77.9139 23.6658C77.7157 23.5339 77.5505 23.3362 77.4844 23.0725C77.3852 22.8088 77.3522 22.4462 77.3522 21.9848V12.9866L79.8965 11.9978Z"
        fill="white"
      />
      <path
        className={color}
        d="M34.8267 10.0528V7.94337H30.6964V27.6208H34.8267V15.9528C34.8267 15.1617 34.9588 14.4695 35.2232 13.9422C35.4875 13.4148 35.851 12.9534 36.2475 12.6238C36.677 12.2941 37.1396 12.0634 37.6353 11.8986C38.1309 11.7668 38.5935 11.7009 39.023 11.7009C39.4526 11.7009 39.8821 11.7668 40.2786 11.9316V7.74561C39.0891 7.74561 38.0978 7.94337 37.2718 8.37185C36.4457 8.80034 35.6527 9.32771 34.8267 10.0528Z"
        fill="white"
      />
      <path
        className={color}
        d="M54.6191 9.59164C53.9252 8.33914 52.7026 7.71289 51.0175 7.71289C49.9932 7.71289 49.2002 7.91065 48.6715 8.30618C48.1428 8.7017 47.6141 9.16315 47.0854 9.75644V7.97657H42.9551V35.6304H47.0854V25.8741H47.1515C47.6141 26.5333 48.1428 27.0277 48.8036 27.3573C49.4314 27.7199 50.1584 27.8847 50.9844 27.8847C51.9757 27.8847 52.7687 27.7199 53.2974 27.3903C53.8591 27.0607 54.2887 26.6322 54.6521 26.1048C54.9165 25.6763 55.0817 25.149 55.1808 24.4898C55.2469 23.8306 55.313 22.7758 55.313 21.3585V13.8435C55.313 12.7558 55.2469 11.8989 55.1478 11.2397C55.0487 10.5805 54.8504 10.0201 54.6191 9.59164ZM51.1827 21.5892C51.1827 22.2155 51.0505 22.7429 50.7531 23.2043C50.4558 23.6658 49.9271 23.8965 49.1671 23.8965C48.5723 23.8965 48.0767 23.6987 47.6802 23.3032C47.2837 22.9077 47.0854 22.3803 47.0854 21.754V14.1402C47.0854 13.4151 47.2837 12.8218 47.6472 12.3603C48.0106 11.8989 48.5393 11.6681 49.1341 11.6681C49.828 11.6681 50.3566 11.8989 50.6871 12.3933C51.0175 12.8877 51.1827 13.5469 51.1827 14.3379V21.5892Z"
        fill="white"
      />
      <path
        className={colorB}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M83.6964 27.3573H88.1902L88.2232 9.229L83.7294 10.8441L83.6964 27.3573Z"
        fill="white"
      />
      <path
        className={colorB}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M104.05 10.3165C102.729 8.63553 100.746 7.81152 98.0698 7.81152C95.5916 7.81152 93.6422 8.63553 92.2544 10.2836C90.8666 11.9316 90.1727 14.4366 90.1727 17.8315C90.1727 24.5225 92.8161 27.8515 98.1029 27.8515C100.614 27.8515 102.564 27.0275 103.984 25.3794C105.372 23.7314 106.066 21.2264 106.066 17.8315C106 14.4695 105.339 11.9975 104.05 10.3165ZM100.317 22.446C99.8211 23.3359 99.0611 23.7973 98.0368 23.7973C96.9794 23.7973 96.1864 23.27 95.7569 22.2482C95.2943 21.1935 95.063 19.7432 95.063 17.8315C95.063 15.6561 95.3273 14.107 95.856 13.217C96.3847 12.3271 97.1116 11.8657 98.0368 11.8657C99.1272 11.8657 99.9202 12.3601 100.383 13.3818C100.845 14.4036 101.077 15.8868 101.077 17.8315C101.077 20.0069 100.812 21.5231 100.317 22.446Z"
        fill="white"
      />
      <path
        className={colorB}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M88.1571 2.24121L83.6964 4.15292V8.89922L88.1571 6.98752V2.24121Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0">
        <rect width="106" height="35.6303" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default Logo;
