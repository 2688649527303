import React, { useState } from 'react';

import ResponsiveBox from '../../UI/ResponsiveBox';
import Heavy24px from '../../UI/texts/24pxHeavy';
import Medium16px from '../../UI/texts/16pxMedium';

import './faq.scss';
import FadeUp from '../../Animations/FadeUp';
import BlockHeader from '../../UI/texts/BlockHeader';

const FAQ = ({ text, darkTheme }) => {
  const [openedFAQ, setOpenedFAQ] = useState([]);

  const handleFAQState = (index) => {
    const found = openedFAQ.find((element) => element === index);
    if (!found) {
      setOpenedFAQ([...openedFAQ, index]);
    } else {
      setOpenedFAQ(openedFAQ.filter((element) => element !== index));
    }
  };

  return (
    <ResponsiveBox className={darkTheme ? 'faq_container-dark' : ''}>
      <div className="faq_container">
        <FadeUp>
          <BlockHeader
            style={{ textAlign: 'center' }}
            dangerouslySetInnerHTML={{
              __html: text.title,
            }}
          />
        </FadeUp>
        <div className="faqs_box">
          {text.items.map((item) => {
            const active = openedFAQ.find((element) => element === item.id);
            return (
              <FadeUp className="faq_box" key={item.title}>
                <div
                  className="faq_title_box"
                  onClick={() => handleFAQState(item.id)}
                >
                  <Heavy24px
                    style={{
                      color: active ? '#3095F2' : 'var(--title-text-color)',
                    }}
                  >
                    {item.title}
                  </Heavy24px>
                  <div
                    className={`plusminus ${
                      active ? 'plusminus_active' : 'plusminus_inactive'
                    }`}
                  />
                </div>
                <div
                  className={`faq_text_box ${active ? 'showText' : 'hideText'}`}
                >
                  <Medium16px
                    dangerouslySetInnerHTML={{
                      __html: item.text,
                    }}
                  />
                </div>
              </FadeUp>
            );
          })}
        </div>
      </div>
    </ResponsiveBox>
  );
};

export default FAQ;
