/* eslint-disable import/no-anonymous-default-export */
import React from 'react';
import ProductMain from '../../components/Projects/BigTicket/ProductMain/ProductMain';
import ProductOverview from '../../components/Projects/BigTicket/ProductOverview/ProductOverview';
import ProductCollaboration from '../../components/Projects/BigTicket/ProductCollaboration/ProductCollaboration';
import ProductFeatureBlock from '../../components/Projects/BigTicket/ProductFeatureBlock/ProductFeatureBlock';
import ProductDescription from '../../components/Projects/BigTicket/ProductDescription/ProductDescription';
import ProductLayout from '../../components/Projects/BigTicket/ProductLayout/ProductLayout';
import ProductResult from '../../components/Projects/BigTicket/ProductResult/ProductResult';
import { Helmet } from "react-helmet-async";
import { useLocation } from "react-router-dom";

export default ({data}) => {  
  const location = useLocation();
  return (
    <div className="big-ticket-wrapper">
      <Helmet>
        <title>Big Ticket | Perpetio</title>
        <meta name="description" content="We are the reliable mobile app development company you were looking for! Boost your business with a slick mobile app from Perpetio." />
        <link rel="canonical" href={`${process.env.REACT_APP_SITE_URL}${location.pathname}`} />
      </Helmet>
      <ProductMain text={data} />
      <ProductOverview text={data} />
      <ProductFeatureBlock text={data} />
      <ProductDescription text={data} />
      <ProductLayout />
      <ProductResult text={data} />
      <ProductCollaboration text={data} />
    </div>
  )
};
