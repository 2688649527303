/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import './privacyPolicyMain.scss';
import ResponsiveBoxHead from '../../components/UI/ResponsiveBoxHead';
import NewMain from '../../components/NewMain/NewMain';
import ResponsiveBox from '../UI/ResponsiveBox';

const EqualityDiversityInclusionPolicy = () => (
  <div className="privacy-policy">
    <ResponsiveBoxHead style={{ paddingBottom: '2.5rem' }}>
      <div className="privacy-policy_header">
        <NewMain
          title="Equality, Diversity & Inclusion <span>Policy</span>"
          text="Last updated: October 2023"
        />
      </div>
    </ResponsiveBoxHead>
    <ResponsiveBox topGap={false}>
      <div className="description">
        <h3>PREAMBLE</h3>
        <p>
          Perpetio OÜ (hereinafter "the Company", "we" or "our"), through this
          Equality, Diversity & Inclusion Policy (the "Policy"), we: (1) strive
          to provide equal opportunities to all people without discrimination on
          the basis of gender, marital status, sexual orientation, race, color,
          nationality, religion, age, disability, trade union activity or
          political beliefs, or any other grounds; (2) value diversity because
          each of us is unique in terms of our backgrounds, personal
          characteristics, experiences, skills, or motivations; and (3) strive
          to foster an inclusive culture because it creates a happier and more
          productive work environment for all of us and helps everyone to expand
          their range of problem-solving capabilities through these different
          perspectives, experiences, and skills.
        </p>
        <h3>SCOPE OF APPLICATION</h3>
        <p>
          This Policy applies to everyone who works for us and covers all stages
          of employment, including the recruitment of candidates for vacant
          positions.
        </p>
        <h3>OUR OBLIGATIONS</h3>
        <p>
          We believe that a culture of equality, diversity, and inclusion not
          only benefits us, but also contributes to the well-being and enables
          our team to perform better because they can be themselves and feel
          they belong. We strive to create a work environment based on dignity,
          trust and respect, free from discrimination, harassment, bullying, and
          victimization. We guarantee and ensure that our recruitment, promotion
          and working conditions are fair and acceptable in accordance with
          internal company policies to all those who wish to work with us.
        </p>
        <h3>OUR EXPECTATIONS</h3>
        <p>
          We expect you and each member of our team to take personal
          responsibility for adhering to, supporting, promoting and putting this
          Policy into practice. Our culture is shaped by our daily interactions
          in the workplace, so creating the right environment is a shared
          responsibility of all of us. We expect you to treat your colleagues
          and third parties (including customers, vendors, contractors, clients,
          and partners) fairly, with dignity, trust, and respect. Any of your
          relationships with colleagues or third parties must be free from any
          form of discrimination, harassment, victimization, or bullying. In the
          event that any member of our team is found to have committed,
          authorized or condoned acts of discrimination, harassment,
          victimization or bullying, we will take action against them, including
          (for victims) in accordance with our Disciplinary Procedure. You
          should be aware that you may be personally liable for discrimination
          and harassment.
        </p>
        <h3>DEFINITION OF ACTIONS THAT CONSTITUTE A BREACH</h3>

        <ol className="numbered-list">
          <li>
            Discrimination is the process of making unfair or prejudicial
            distinctions between people based on groups, classes, or other
            categories to which they belong or are perceived to belong, such as
            race, gender, age, religion, or sexual orientation. Discrimination
            typically results in unfair treatment of groups based on perceived
            statuses based on ethnicity, race, gender, or religion. It involves
            depriving members of one group of opportunities or privileges that
            are available to members of another group.
          </li>
          <li>
            Harassment is unwanted behavior related to a protected
            characteristic that has the purpose or effect of: degrading another
            person; or creating an intimidating, hostile, degrading,
            humiliating, offensive or abusive environment for someone else.
            Sexual harassment is conduct of a sexual nature that has the purpose
            or effect of degrading a person's dignity or creating an
            intimidating, hostile, humiliating, offensive or offensive
            environment; and less favorable treatment related to gender or
            gender reassignment that results from rejection of or submission to
            sexual conduct.
          </li>
          <li>
            Victimisation is treating another person detrimentally either
            because that person has made a complaint of discrimination or
            harassment, or because they have supported someone else who has made
            such a complaint, for example by giving a witness statement that
            supports the allegations.
          </li>
          <li>
            Bullying is behavior that is hurtful, intimidating, malicious,
            offensive, abusive, or an abuse of power or authority, and usually
            persistent, that is intended to undermine, humiliate, or injure the
            recipient. Bullying can be physical, verbal, or non-verbal. It does
            not necessarily take place face-to-face and can be carried out via
            email, phone calls, online or social media. Bullying can occur at
            work or outside of work. Any member of our team will be disciplined
            for committing the above actions up to and including dismissal, with
            the submission of documents on this fact to the relevant state
            authority.
          </li>
        </ol>
        <h3>EQUALITY</h3>
        <p>
          Fair treatment in the workplace is applied to all our team members
          throughout their working lives, from recruitment and selection to any
          relationship with us. We take reasonable and appropriate steps to
          encourage as many diverse people as possible to apply for jobs. To do
          this, we: (1) clearly describe job duties and requirements for
          candidates for positions, and communicate job openings in a manner
          that does not limit the audience on the basis of gender, race, marital
          status, disability, age, part-time or fixed-term status, sexual
          orientation or religion; (2) our recruitment team must not
          discriminate in any way and must receive appropriate diversity and
          inclusion training; (3) each decision maker must examine themselves
          and other members of the recruitment team to ensure that no
          stereotypes, unconscious bias or prejudice play a role in recruitment
          decisions. All team members will be provided with appropriate training
          regardless of gender, race, marital status, disability, age, part-time
          or fixed-term contract status, sexual orientation or religion. We
          expect all of our team members to actively support our equality,
          diversity, and inclusion initiatives by attending any proposed or
          required events and workshops to learn about the issues that others
          face and how to help them address those issues in the workplace.
        </p>
        <h3>DISABILITY INCLUSION</h3>
        <p>
          Recruiting people with disabilities involves a proactive recruitment
          team, meaning that various elements such as advertising, application
          forms, assessments, interviewing, job descriptions, employee
          characteristics and selection criteria should be designed to be as
          inclusive as possible. To ensure such inclusivity, candidates are
          interviewed at the outset about any necessary reasonable adjustments
          that may be required throughout the selection process: providing easy
          access to the interview room, modifying or replacing psychometric
          tests, providing alternatives to telephone interviews for deaf
          candidates, providing appropriate seating for candidates with back
          problems, and other accommodations. It is important to note that those
          involved in the interview process are clearly instructed not to ask
          about the health or disability of job applicants without prior
          authorization from the CEO. Such authorization is granted only in
          exceptional circumstances and when there are specific legal grounds
          for such inquiries.
        </p>
        <p>
          Discussing disability can be challenging for some people, especially
          as disability can be hidden. To promote the inclusion of people with
          disabilities, it is crucial to create a psychological safety net where
          people feel comfortable sharing their experiences without fear of
          negative repercussions. This can only be achieved by treating people
          with dignity, trust and respect, and we expect everyone to uphold
          these values. We have a zero-tolerance policy on ageist language,
          which includes negative, inappropriate or offensive language towards
          people with disabilities, whether in the form of jokes or banter. Any
          use of such language may result in disciplinary action as outlined in
          our Disciplinary Procedure. If you have a disability, you are not
          required to disclose it, but we encourage you to let us know for
          potential support, i.e. adapting our facilities, changing aspects of
          your role or adapting our working practices. If your disability
          affects your work, please contact the CEO to discuss possible
          reasonable adjustments. We may need to consult with you and your
          medical advisor to provide appropriate support.
        </p>
        <h3>OTHER</h3>
        <p>
          We continuously analyze diversity and inclusion data (in accordance
          with our data protection obligations) to assess the impact of this
          Policy and our equality, diversity, and inclusion strategy. The policy
          will be continually reviewed to reflect changes in legislation,
          demographics, and internal business requirements. This Policy has been
          approved by the CEO of Perpetio OÜ, if you would like to receive
          further information regarding this Policy, please contact the CEO of
          Perpetio OÜ.
        </p>
      </div>
    </ResponsiveBox>
  </div>
);

export default EqualityDiversityInclusionPolicy;
