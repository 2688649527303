import React from 'react';
import './item.scss';
import { Col } from 'reactstrap';
import { Link } from 'react-router-dom';
import { remoteStorageUrl } from '../../../config';
import FadeUp from '../../Animations/FadeUp';
import BlockHeader from '../../UI/texts/BlockHeader';

export const Item = (props) => {
  const { content, path } = props;

  if (content.link) {
    return (
      <FadeUp className={`item ${content.id}`}>
        <Link to={content.link}>
          <div className="item-img">
            <img
              src={`${remoteStorageUrl}/resources/${path}/${content.img}`}
              alt={content.id}
            />
          </div>
          <BlockHeader
            variant="h3"
            dangerouslySetInnerHTML={{ __html: content.title }}
          />
          <p>{content.def}</p>
        </Link>
      </FadeUp>
    );
  } else {
    return (
      path && (
        <Col className="item" key={content.id}>
          <div className={`item-img ${content.id}`}>
            <img
              src={`${remoteStorageUrl}/resources/${path}/${content.img}`}
              alt={content.id}
            />
          </div>
          <div className={`item-def ${content.id}`}>
            <h4 dangerouslySetInnerHTML={{ __html: content.title }} />
            <p>{content.def}</p>
          </div>
        </Col>
      )
    );
  }
};
