import React from 'react';
import { Link } from 'react-router-dom';
import ResponsiveBox from '../../UI/ResponsiveBox';
import Heavy24px from '../../UI/texts/24pxHeavy';
import Heavy16px from '../../UI/texts/16pxHeavy';
import Medium12px from '../../UI/texts/12pxMedium';
import { useWindowDimensions } from '../../../helpers/resizeHandler';
import { remoteStorageUrl } from '../../../config';

import './cases.scss';
import PlusIcon from '../../icons/plusIcon';
import FadeUp from '../../Animations/FadeUp';
import BlockHeader from '../../UI/texts/BlockHeader';

const Cases = ({ text, threeBlockLayout = false }) => {
  const { width } = useWindowDimensions();

  return (
    <div
      className={`cases_container${
        threeBlockLayout ? ' three-cases_container' : ''
      }`}
    >
      <ResponsiveBox>
        <FadeUp className="cases_title_box">
          <BlockHeader
            dangerouslySetInnerHTML={{
              __html: text.title,
            }}
          />
        </FadeUp>
        <div className="study_cases_box">
          {text.items
            .slice(0, threeBlockLayout ? 3 : text.items.length)
            .map((item) => {
              return (
                <FadeUp
                  key={item.title}
                  className={
                    item.className
                      ? `study_cases_box_fadeup ${item.className}`
                      : 'study_cases_box_fadeup'
                  }
                >
                  <Link
                    to={item.link}
                    className="case_box"
                    style={{ background: item.color }}
                    rel="canonical"
                  >
                    <div className="logo_box">
                      {item.backgroundImg && (
                        <img
                          src={`${remoteStorageUrl}/${item.path}/${item.backgroundImg}`}
                          alt={item.title}
                        />
                      )}
                    </div>
                    <Heavy24px
                      style={{
                        color: '#FFFFFF',
                        textAlign: 'center',
                        width: '100%',
                      }}
                    >
                      {item.title}
                    </Heavy24px>
                    <Heavy16px
                      dangerouslySetInnerHTML={{ __html: item.subtitle }}
                      style={{ color: '#FFFFFF' }}
                    />
                    <Medium12px style={{ color: '#FFFFFF', opacity: 0.7 }}>
                      {item.tech.join(' / ')}
                    </Medium12px>

                    {/* <LazyImage
                      imageLink={
                        item.mobileImg && width < 1100
                          ? item.mobileImg
                          : item.img
                      }
                      path={item.path}
                      alt={item.title}
                    /> */}
                    <div className="lazyload-wrapper">
                      <img
                        src={`${remoteStorageUrl}/${item.path}/${item.img}`}
                        alt={item.title}
                      />
                    </div>
                  </Link>
                </FadeUp>
              );
            })}
        </div>
        <Link
          to={'/use-cases'}
          className="allCaseBtn"
          style={{ display: 'flex', justifyContent: 'center' }}
          rel="canonical"
        >
          <div className="allCaseBtn_flex">
            <Heavy24px style={{ color: 'var(--title-text-color)' }}>
              All case studies
            </Heavy24px>
            <div>
              <PlusIcon
                width={width < 1100 ? '14' : '24'}
                height={width < 1100 ? '14' : '24'}
              />
            </div>
          </div>
        </Link>
      </ResponsiveBox>
    </div>
  );
};

export default Cases;
