import React from 'react';
import Slider from 'react-slick';
import LazyLoad from 'react-lazyload';

import './reviews.scss';
import { remoteStorageUrl } from '../../../../config';
import SvgCircleArrow from '../../../icons/circle_arrow';
import BlockHeader from '../../../UI/texts/BlockHeader';

const Reviews = ({ data, title, withBg = false }) => {
  const [page, setPage] = React.useState(1);

  const sliderRef = React.useRef(null);

  return (
    <div
      className={withBg ? 'reviews-block reviews-block-bg' : 'reviews-block'}
    >
      <BlockHeader
        dangerouslySetInnerHTML={{
          __html: title || data.title,
        }}
      />
      <Slider
        dots={false}
        slidesToShow={data.items.length > 4 ? 4 : 3}
        slidesToScroll={1}
        responsive={[
          {
            breakpoint: 1440,
            settings: {
              slidesToShow: 3,
            },
          },
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 2,
            },
          },
          {
            breakpoint: 768,
            settings: {
              slidesToShow: 1,
            },
          },
        ]}
        arrows={false}
        autoplay={false}
        autoplaySpeed={5000}
        infinite={true}
        afterChange={(current) => {
          setPage(Math.round(current + 1));
        }}
        ref={sliderRef}
      >
        {data.items.map((item) => (
          <div className="review-wrapper" key={`review-${item.id}`}>
            <div className="review">
              <span className="review-quotes">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="56"
                  height="48"
                  viewBox="0 0 56 48"
                  fill="none"
                >
                  <path
                    d="M24.6864 0.644531V23.6278C24.6864 29.7567 22.5405 34.9208 18.2488 39.1202C13.957 43.3196 8.19699 46.2138 0.96875 47.8028V33.1616C3.67935 32.2536 5.4864 31.1186 6.38993 29.7567C7.29346 28.3947 7.85817 26.749 8.08405 24.8195H0.96875V0.644531H24.6864ZM55.1806 0.644531V23.6278C55.1806 29.7567 52.9782 34.9208 48.5735 39.1202C44.2817 43.3196 38.5782 46.2138 31.4629 47.8028V33.1616C34.1735 32.2536 35.9806 31.1186 36.8841 29.7567C37.7876 28.3947 38.2959 26.749 38.4088 24.8195H31.4629V0.644531H55.1806Z"
                    fill="#D9ECFF"
                  />
                </svg>
              </span>
              <div className="review-author">
                {item.author_img ? (
                  <div>
                    <LazyLoad height={100} once>
                      <img
                        src={`${remoteStorageUrl}/resources/images/services_page/reviews/${item.author_img}`}
                        alt={item.author}
                        className="review-author-img"
                      />
                    </LazyLoad>
                  </div>
                ) : (
                  <div style={{ height: '100px' }} />
                )}
                <div className="review-author-text">
                  <div className="review-author-name">{item.author},</div>
                  <div className="review-author-position">{item.position}</div>
                </div>
              </div>
              <div className="review-text">{item.text}</div>
              {item.logo ? (
                <div className="review-logo">
                  <LazyLoad height={100} once>
                    <img
                      src={`${remoteStorageUrl}/resources/images/services_page/reviews/${item.logo}`}
                      alt=""
                    />
                  </LazyLoad>
                </div>
              ) : (
                <div
                  className="review-logo"
                  style={{
                    height: '3rem',
                  }}
                />
              )}
            </div>
          </div>
        ))}
      </Slider>
      <div className="reviews-block-buttons">
        <button
          className="reviews-block-button"
          onClick={() => {
            sliderRef.current?.slickPrev();
          }}
        >
          <SvgCircleArrow />
        </button>
        <span className="reviews-block-page">
          <span>{page}</span>/{data.items.length}
        </span>
        <button
          className="reviews-block-button"
          style={{ transform: 'rotate(180deg)' }}
          onClick={() => {
            sliderRef.current?.slickNext();
          }}
        >
          <SvgCircleArrow />
        </button>
      </div>
    </div>
  );
};

export default Reviews;
