import React from "react";
import Title from "../PerpetioTitle";
import text from "../../translation.json";
import "./achievements.scss";
import FadeUp from "../Animations/FadeUp";
import withRouter from "../../utils/withRouter";
import { remoteStorageUrl } from "../../config";
import ResponsiveBox from "../../components/UI/ResponsiveBox";

const Achievements = (props) => {
  return (
    <ResponsiveBox topGap={false}>
      <div className="achievements">
        <Title
          title={text.about_us.sections.achievements.title}
          subtitle={text.about_us.sections.achievements.subtitle}
        ></Title>
        <FadeUp className="achievements-block-container">
          {text.about_us.sections.achievements.images.map((image) => {
            return (
              <a
                key={image.image}
                href={image.link}
                target="_blank"
                rel="noopener noreferrer"
                className="achievements-block"
              >
                <img
                  src={`${remoteStorageUrl}/resources/images/about/${image.image}`}
                  alt={image.image}
                />
              </a>
            );
          })}
        </FadeUp>
      </div>
    </ResponsiveBox>
  );
};
export default withRouter(Achievements);
