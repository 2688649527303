import React from 'react';
import './text.scss';

const Medium18px = ({ children, ...rest }) => {
  return (
    <p {...rest} className="medium18px">
      {children}
    </p>
  );
};

export default Medium18px;
