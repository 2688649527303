import React from 'react';
import ProductMain from '../../components/Projects/Burn/ProductMain/ProductMain';
import ProductOverview from '../../components/Projects/Burn/ProductOverview/ProductOverview';
import ProductDescription from '../../components/Projects/Burn/ProductDescription/ProductDescription';
import ProductLayout from '../../components/Projects/Burn/ProductLayout/ProductLayout';
import ProductScreens from '../../components/Projects/Burn/ProductScreens/ProductScreens';
import ProductCollaboration from '../../components/Projects/Burn/ProductCollaboration/ProductCollaboration';
import ProductResult from '../../components/Projects/Burn/ProductResult/ProductResult';
import ProductFeatureBlock from '../../components/Projects/Burn/ProductFeatureBlock/ProductFeatureBlock';
import ProductSlider from '../../components/Projects/Burn/ProductSlider/ProductSlider';
import Compare from '../../components/Projects/Burn/Compare/Compare';
import { Helmet } from 'react-helmet-async';
import { useLocation } from 'react-router-dom';

// eslint-disable-next-line import/no-anonymous-default-export
export default ({ data }) => {
  const location = useLocation();
  return (
    <div className="burn-wrapper">
      <Helmet>
        <title>BURN | Perpetio</title>
        <meta
          name="description"
          content="We are the reliable mobile app development company you were looking for! Boost your business with a slick mobile app from Perpetio."
        />
        <link
          rel="canonical"
          href={`${process.env.REACT_APP_SITE_URL}${location.pathname}`}
        />
      </Helmet>
      <ProductMain text={data} />
      <ProductOverview text={data} />
      <ProductScreens text={data} />
      <ProductDescription text={data} />
      <ProductLayout text={data} />
      <Compare text={data} />
      <ProductFeatureBlock text={data} />
      <ProductSlider text={data.product_slides} />
      <ProductResult text={data} />
      <ProductCollaboration text={data} />
    </div>
  );
};
