import React from 'react';

import './case2023.scss';

const CaseWrapper2023 = ({ className, children }) => {
  return (
    <div
      className={
        className ? `project-case-wrapper ${className}` : 'project-case-wrapper'
      }
    >
      {children}
    </div>
  );
};

export default CaseWrapper2023;
