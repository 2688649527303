import React from "react";
const ImageSrcSet = ({ pathObj }) => {
  return (
    <img
      srcSet={`${pathObj.path2x} 1x, ${pathObj.path2x} 2x, ${pathObj.path3x} 3x, ${pathObj.path4x} 4x,`}
      alt={pathObj.alt}
    />
  );
};

export default ImageSrcSet;
