/* eslint-disable import/no-anonymous-default-export */
import React, { Fragment, useState, useEffect } from "react";
import { Helmet } from "react-helmet-async";
import { useLocation } from "react-router-dom";
import NewMain from "../../components/NewMain/NewMain";
import NewDevelopment from "../../components/NewDevelopment/NewDevelopment";
import OurProjects from "../../components/OurProjects/OurProjects";
import text from "../../translation.json";
import ResponsiveBoxHead from "../../components/UI/ResponsiveBoxHead";

export default () => {
  const location = useLocation();
  const [domLoaded, setDomLoaded] = useState(false);

  useEffect(() => {
    setDomLoaded(true);
  }, []);
  return (
    <Fragment>
      <Helmet>
        <title>Services | Perpetio</title>
        <meta
          name="description"
          content="Achieve new business goals with custom mobile design 
          and development solutions! Best mobile application development service by Perpetio team is a click away."
        />
        <link
          rel="canonical"
          href={`${process.env.REACT_APP_SITE_URL}${location.pathname}`}
        />
      </Helmet>
      {domLoaded && (
        <div>
          <ResponsiveBoxHead
            // style={{
            //   background:
            //     "linear-gradient(134.07deg, #F3F2FF 9.9%, #E8FFFE 68.31%, #FFFFFF 100.53%)",
            // }}
          >
            <div className="portfolio_header">
              <NewMain title={text.services.title} text={text.services.text} />
            </div>
          </ResponsiveBoxHead>
          <NewDevelopment />
          <OurProjects topGap={false}/>
        </div>
      )}
    </Fragment>
  );
};
