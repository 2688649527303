import React, { useState, useEffect } from "react";
import { Container, Row, Col } from "reactstrap";
import Parallax from "../../../Parallax/Parallax";
import { Image } from "../../../Image/Image";
import "./productOverview.scss";
import { remoteStorageUrl } from "../../../../config";

const ProductOverview = ({ text }) => {
  const mediaMatch =
    typeof window === "undefined"
      ? null
      : window.matchMedia("(max-width: 575px)");
  const [matches, setMatches] = useState(mediaMatch && mediaMatch.matches);

  useEffect(() => {
    const handler = (e) => setMatches(e.matches);
    mediaMatch && mediaMatch.addListener(handler);
  }, [mediaMatch]);

  return (
    <Container className="product-overview" fluid>
      <Row className="product-overview-row margin">
        <Col className="product-overview-column" sm={6}>
          <h3 className="product-overview-title">
            {text.product_main.client.title}
          </h3>
          <Row className="m-0">
            <Parallax>
              <Image
                className="product-main-img-aob"
                src={`aob/logo/${text.product_main.logo}`}
                alt="product-logo"
              />
            </Parallax>
            <Col className="ml-4 p-0 flex-column d-flex justify-content-center">
              <h5 className="product-overview-name">
                {text.product_main.client.name}
              </h5>
              <p className="product-overview-text">
                {text.product_main.client.description}
              </p>
            </Col>
          </Row>
        </Col>
        {!matches ? (
          <Col className="product-overview-column" sm={5}>
            <h3 className="product-overview-title">
              {text.product_main.overview.title}
            </h3>
            {
              <ul className="list mb-5 mb-sm-0">
                {text.product_main.overview.description.map((item, index) => (
                  <li key={index} dangerouslySetInnerHTML={{ __html: item }} />
                ))}
              </ul>
            }
          </Col>
        ) : null}
      </Row>
      <Row className="product-overview-row align-items-center margin">
        <Col className="product-overview-column p-sm-0" sm={6}>
          <Row className="product-overview-row2">
            {text.product_main.services.icons.map((service, index) => (
              <Row className="product-feature-row" align="center" key={index}>
                <Col>
                  <img
                    className="product-feature"
                    src={`${remoteStorageUrl}/resources/images/product-services/${service.image}`}
                    alt="feature"
                  />
                </Col>
                <Col className="product-feature-col-2">
                  <p className="product-feature-caption">{service.text}</p>
                </Col>
              </Row>
            ))}
          </Row>
        </Col>
        <Col className="product-overview-column mt-5" sm={5}>
          <h3 className="product-overview-title">
            {text.product_main.services.title}
          </h3>
          <p className="product-main-row2-services-description">
            {text.product_main.services.name}
          </p>
        </Col>
      </Row>
    </Container>
  );
};

export default ProductOverview;
