import React, { useState, useEffect } from 'react';
import { Container, Row, Col } from 'reactstrap';
import './commitMain.scss';
import { remoteStorageUrl } from '../../../../config';
const title = `${remoteStorageUrl}/resources/images/commit/commit-title.svg`;
const mTitle = `${remoteStorageUrl}/resources/images/commit/mobile-commit-title.svg`;
const phone = `${remoteStorageUrl}/resources/images/commit/commit-main-phone.png`;
const mPhone = `${remoteStorageUrl}/resources/images/commit/mobile-commit-main-phone.png`;

const CommitMain = ({ text }) => {
  const mediaMatch =
    typeof window === 'undefined'
      ? null
      : window.matchMedia('(max-width: 575px)');
  const [matches, setMatches] = useState(mediaMatch && mediaMatch.matches);
  const [load, setLoad] = useState(true);

  useEffect(() => {
    const handler = (e) => setMatches(e.matches);
    mediaMatch && mediaMatch.addListener(handler);
    if (matches) {
      const imgMTitle = new Image();
      const imgMPhone = new Image();
      imgMTitle.src = mTitle;
      imgMPhone.src = mPhone;
      imgMTitle.onload = () => setLoad(false);
    } else {
      const imgTitle = new Image();
      const imgPhone = new Image();
      imgTitle.src = title;
      imgPhone.src = phone;
      imgTitle.onload = () => setLoad(false);
    }
  }, [mediaMatch, matches]);

  return load ? null : (
    <Container className="commit-main" fluid>
      <Row className="commit-main-row">
        <Col className="commit-main-col">
          <img
            className="commit-main-title"
            src={matches ? mTitle : title}
            alt="title"
          />
          <h1 className="commit-main-text">{text.main.text}</h1>
          <img
            className="commit-main-phone"
            src={matches ? mPhone : phone}
            alt="phone"
          />
          <p
            className="commit-main-description"
            dangerouslySetInnerHTML={{ __html: text.main.description }}
          />
        </Col>
      </Row>
    </Container>
  );
};

export default CommitMain;
