import React from 'react';
import { Button } from 'reactstrap';
import { Link } from 'react-router-dom';

import ResponsiveBoxHead from '../../UI/ResponsiveBoxHead';
import NewMain from '../../../components/NewMain/NewMain';
import './head.scss';

const ServiceHead = ({ data, limitContentWidth, ...rest }) => {
  return (
    <ResponsiveBoxHead {...rest}>
      <div
        className={
          limitContentWidth
            ? 'service-head-container limit-content-width'
            : 'service-head-container'
        }
      >
        <NewMain title={data.title} text={data.text} />
        <Link className="getInTouchBtn" to={'/contact'}>
          <Button color="primary">{data.button}</Button>
        </Link>
      </div>
    </ResponsiveBoxHead>
  );
};

export default ServiceHead;
