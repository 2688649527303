import React, { useState, useEffect } from "react";
import "./productLayout.scss";
import { remoteStorageUrl } from "../../../../config";

export default ({ text }) => {
  const mediaMatch =
    typeof window === "undefined"
      ? null
      : window.matchMedia("(max-width: 575px)");
  const [matches, setMatches] = useState(mediaMatch && mediaMatch.matches);

  useEffect(() => {
    const handler = (e) => setMatches(e.matches);
    mediaMatch && mediaMatch.addListener(handler);
  }, [mediaMatch]);
  return matches ? (
    <div
      style={{
        backgroundImage: `url(${
          remoteStorageUrl + "/resources/images/" + text.product_layout
        })`,
      }}
      className="product-layout"
    />
  ) : null;
};
