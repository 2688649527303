/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import './privacyPolicyMain.scss';
import ResponsiveBoxHead from '../UI/ResponsiveBoxHead';
import NewMain from '../NewMain/NewMain';
import ResponsiveBox from '../UI/ResponsiveBox';

const CorporatePolicy = () => (
  <div className="privacy-policy">
    <ResponsiveBoxHead style={{ paddingBottom: '2.5rem' }}>
      <div className="privacy-policy_header">
        <NewMain
          title="Corporate Social Responsibility <span>Policy</span>"
          text="Last updated: October 2023"
        />
      </div>
    </ResponsiveBoxHead>
    <ResponsiveBox topGap={false}>
      <div className="description">
        <h3>PREAMBLE</h3>
        <p>
          Perpetio OÜ (hereinafter "the Company", "we" or "our") is committed to
          ethical corporate behaviour and to comply with all applicable laws,
          regulations and other provisions governing our business. Our
          unwavering commitment is to foster a strong anti-corruption culture
          and to ensure strict compliance with local anti-bribery and
          anti-corruption laws and regulations. We are committed to prohibiting
          any form of giving, offering, seeking or receiving bribes, corrupt
          payments, inducements or similar conduct by ourselves or anyone acting
          on our behalf. Our senior management will consistently implement
          effective measures to prevent, control and eliminate bribery. It is
          important to note that this Corporate Social Responsibility Policy
          (hereinafter - "the Policy") is not considered to be part of the
          employment contract, and we reserve the right to amend or cancel it at
          any time.
        </p>
        <h3>OUR SERVICES</h3>
        <p>
          We are a one-stop partner for full-cycle mobile application
          development. We are always ready for the next challenge: iOS
          Development Services, Android App Development Services, Flutter App
          Development Services, React Native Development, Web Development, UI/UX
          Design and other customised service packages (collectively, the
          "Services"). Our purpose is to improve performance by delivering
          unrivalled value, insight, and success to our customers.
        </p>
        <h3>OUR TEAM</h3>
        <p>
          We are committed to ensuring the well-being and sustainable
          development of our team, where each member clearly understands their
          role and contribution to the business. We ensure that each member of
          the team has the opportunity to develop according to their interests
          and talents through a clearly defined personal growth plan that
          includes appropriate training and systematic assessment points where
          tangible progress goals are met. We expect our team to treat each
          other fairly and to meet the highest standards of business ethics and
          excellence. We support diversity, equity, and equal opportunities for
          everyone, supporting them in their new learning endeavours.
        </p>
        <h3>OUR CUSTOMERS</h3>
        <p>
          We intend to develop long-lasting and meaningful relationships with
          our customers and stakeholders, ensuring that we fully understand
          their objectives, meet all requirements and exceed their expectations
          for our Services. Our goal is always to provide fair value, consistent
          quality and reliability. We are fully committed to the highest
          professional and ethical standards, and are committed to being honest,
          open, and transparent in all our interactions with customers.
        </p>
        <h3>OUR VENDORS</h3>
        <p>
          We strive to create and maintain strong relationships with key
          suppliers and contractors who share our ethics in terms of employment,
          quality and environmental controls. We have rigorous vetting processes
          in place to ensure that we work with companies and/or individuals who
          are committed to maintaining best practice and the performance of our
          Services.
        </p>
        <h3>OUR ENVIRONMENT</h3>
        <p>
          We recognise our environmental impact as an integrated, progressive
          business and continue to take appropriate measures to mitigate this
          impact, both by us and by our teams and customers, such as an
          environmental policy, which is reviewed annually and updated as
          necessary, and through which we set environmental goals and targets,
          use procedures and provide training to ensure that our teams and
          contractors understand their environmental responsibilities and can
          strive to improve our environmental performance, and ensure that we
          are committed to the environment. In addition, we are committed to
          providing a safe and healthy working environment for all our
          activities and to promoting a health and safety culture that focuses
          on maintaining the highest standards while complying with health and
          safety requirements. We make sure our team is happy and healthy at
          work, so they can enjoy time with their loved ones. This commitment
          extends to our relationships with customers and vendors.
        </p>
        <h3>PEOPLE AROUND US</h3>
        <p>
          We recognise and understand the importance of the local communities in
          which we operate, which is why we strive to increase our contribution
          to society by being aware of the needs of local people and groups. We
          are committed to the following actions: making monetary and time
          donations to local volunteer charities, and supporting the surrounding
          community by employing local people and providing opportunities for
          young people from our local communities through training and work
          experience.
        </p>
        <h3>OTHER</h3>
        <p>
          This Policy has been approved by the CEO of Perpetio OÜ, if you would
          like to receive further information regarding this Policy, please
          contact the CEO of Perpetio OÜ.
        </p>
      </div>
    </ResponsiveBox>
  </div>
);

export default CorporatePolicy;
