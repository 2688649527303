import React from 'react';
// import text from '../../translation.json';
import { Row, Col } from 'reactstrap';
import './perpetioTitle.scss';
import FadeUp from '../Animations/FadeUp';

const PerpetioTitle = ({ title, subtitle }) => {
  return (
    <FadeUp className={title}>
      <Row>
        <Col>
          <h2 className="perpetio-title">{title}</h2>
          <h3
            className="perpetio-subtitle"
            dangerouslySetInnerHTML={{ __html: subtitle }}
          />
        </Col>
      </Row>
    </FadeUp>
  );
};

export default PerpetioTitle;
