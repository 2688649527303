import { useState, useEffect } from "react";

const getWindowDimensions = () => {
  let data = {};
  typeof window === "undefined"
    ? (data = { width: 1025, height: 1025 })
    : (data = { width: window.innerWidth, height: window.innerHeight });

  return data;
};

export const useWindowDimensions = () => {
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );

  useEffect(() => {
    const handleResize = () => {
      setWindowDimensions(getWindowDimensions());
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return windowDimensions;
};
