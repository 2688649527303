import React from 'react';

import './PinkishGradient.scss';
import FadeUp from '../../../Animations/FadeUp';
import LazyLoad from 'react-lazyload';
import Medium24px from '../../../UI/texts/24pxMedium';
import Medium16px from '../../../UI/texts/16pxMedium';
import ResponsiveBox from '../../../UI/ResponsiveBox';
import { remoteStorageUrl } from '../../../../config';
import BlockHeader from '../../../UI/texts/BlockHeader';

const PinkishGradient = ({
  title,
  subtitle,
  items,
  cdnUrl = `${remoteStorageUrl}/resources/images/devices/`,
  circles = false,
  mobile_image_near_title = false,
  smallSubtitle = false,
  direction = 'vertical',
}) => {
  return (
    <div
      className={
        direction === 'horizontal'
          ? 'pink-gradient-container pink-gradient-container-horizontal'
          : 'pink-gradient-container'
      }
    >
      <ResponsiveBox>
        <FadeUp>
          {title && (
            <BlockHeader
              dangerouslySetInnerHTML={{
                __html: title,
              }}
            />
          )}
          {subtitle && (
            <div className="pink-gradient-subtitle">
              {smallSubtitle ? (
                <Medium16px
                  style={{
                    color: 'var(--p-text-color)',
                    textAlign: 'center',
                  }}
                  dangerouslySetInnerHTML={{
                    __html: subtitle,
                  }}
                />
              ) : (
                <Medium24px
                  style={{
                    color: 'var(--p-text-color)',
                    textAlign: 'center',
                  }}
                  dangerouslySetInnerHTML={{
                    __html: subtitle,
                  }}
                />
              )}
            </div>
          )}
        </FadeUp>
        <div
          className={`pink-gradient-items${
            circles ? ' pink-gradient-items-circles' : ''
          }`}
          style={{ marginTop: !title && !subtitle ? 0 : '' }}
        >
          {items.map((item, index) => {
            return (
              <FadeUp key={index} className="pink-gradient-item">
                {item.img && !mobile_image_near_title ? (
                  <div className="pink-gradient-image">
                    <LazyLoad once offset={100}>
                      <img src={`${cdnUrl}${item.img}`} alt={item.title} />
                    </LazyLoad>
                  </div>
                ) : null}
                {mobile_image_near_title && (
                  <div className="mobile_image_near_title">
                    <div className="mobile_image_near_title__head">
                      <LazyLoad once offset={100}>
                        <img src={`${cdnUrl}${item.img}`} alt={item.title} />
                      </LazyLoad>
                      <BlockHeader
                        dangerouslySetInnerHTML={{
                          __html: item.title,
                        }}
                        variant="h3"
                      />
                    </div>
                    <div className="mobile_image_near_title__text">
                      <Medium16px
                        style={{ color: 'var(--p-text-color)' }}
                        dangerouslySetInnerHTML={{
                          __html: item.text,
                        }}
                      />
                    </div>
                  </div>
                )}
                {!mobile_image_near_title && (
                  <div
                    className="pink-gradient-content"
                    style={{ marginLeft: mobile_image_near_title ? 0 : '' }}
                  >
                    <BlockHeader
                      dangerouslySetInnerHTML={{
                        __html: item.title,
                      }}
                      variant="h3"
                    />
                    <Medium16px
                      style={{ color: '#4B4B4B' }}
                      dangerouslySetInnerHTML={{
                        __html: item.text,
                      }}
                    />
                  </div>
                )}
              </FadeUp>
            );
          })}
        </div>
      </ResponsiveBox>
    </div>
  );
};

export default PinkishGradient;
