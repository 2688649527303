import React, { Fragment } from 'react';

import { remoteStorageUrl } from '../../../config';

const serviceOptions = {
  flutter: {
    title: 'Flutter',
    icon: `${remoteStorageUrl}/resources/images/cases/flutter_service.svg`,
  },
  mobile: {
    title: 'Mobile',
    icon: `${remoteStorageUrl}/resources/images/cases/mobile_service.svg`,
  },
  qa: {
    title: 'QA',
    icon: `${remoteStorageUrl}/resources/images/cases/qa_service.svg`,
  },
  pm: {
    title: 'PM',
    icon: `${remoteStorageUrl}/resources/images/cases/pm_service.svg`,
  },
  uiux: {
    title: 'UI/UX',
    icon: `${remoteStorageUrl}/resources/images/cases/uiux.svg`,
  },
  backend: {
    title: 'Backend',
    icon: `${remoteStorageUrl}/resources/images/cases/backend_service.svg`,
  },
};

const TheClient = ({ translation }) => {
  const {
    smallLogoPath,
    servicesIcons,
    clientText,
    color,
    filter,
    overview,
    services,
    clientTextIsNextToImage = false,
  } = translation;

  const ClientTextWrapper = clientTextIsNextToImage ? 'div' : Fragment;

  return (
    <div className="case-client">
      <div className="case-client-col">
        <h4>The Client</h4>
        <ClientTextWrapper className="case-client-text-wrapper">
          <img
            className="client-logo"
            src={`${remoteStorageUrl}/${smallLogoPath}`}
            alt="logo"
          />
          <p
            className="case-client-text"
            dangerouslySetInnerHTML={{ __html: clientText }}
          />
        </ClientTextWrapper>
        <div className="case-client-services">
          {servicesIcons.map((service) => (
            <div key={service}>
              <img
                src={serviceOptions[service].icon}
                alt={serviceOptions[service].title}
                style={{
                  ...(color ? { color } : {}),
                  ...(filter ? { filter } : {}),
                }}
              />
              <p>{serviceOptions[service].title}</p>
            </div>
          ))}
        </div>
      </div>
      <div className="case-client-col">
        <h4>Overview</h4>
        <div>
          {overview.map((item) => (
            <p key={item} dangerouslySetInnerHTML={{ __html: item }}></p>
          ))}
        </div>
        <div className="case-client-services-block">
          <h4>Services</h4>
          <p>{services}</p>
        </div>
      </div>
    </div>
  );
};

export default TheClient;
