import React from "react";
import { Container, Row, Col } from "reactstrap";

import "./technicalDetails.scss";
import FadeUp from "../../../Animations/FadeUp";

const TechnicalDetails = ({ data }) => {
  return (
    <Container fluid className="technicalDetailsContainer">
      <FadeUp className={`technicalDetails`}>
        <div className="techicalDetails_content">
          <div className="techicalDetailsTitle">{data.title}</div>
          <div xs={3} className="technicalDetailsRow">
            <div className="technicalDetailsCol">
              {data.firstCol.map((colData) => {
                return (
                  <div
                    key={colData}
                    dangerouslySetInnerHTML={{ __html: colData }}
                  />
                );
              })}
            </div>
            <div className="technicalDetailsCol">
              {data.secondCol.map((colData) => {
                return (
                  <div
                    key={colData}
                    dangerouslySetInnerHTML={{ __html: colData }}
                  />
                );
              })}
            </div>
            <div className="technicalDetailsCol">
              {data.thirdCol.map((colData) => {
                return (
                  <div
                    key={colData}
                    dangerouslySetInnerHTML={{ __html: colData }}
                  />
                );
              })}
            </div>
          </div>
        </div>
      </FadeUp>
    </Container>
  );
};

export default TechnicalDetails;
