import React from 'react';
import './text.scss';

const BlockHeader = ({ children, variant = 'h2', ...rest }) => {
  const className = rest.className
    ? rest.className + ' block-header'
    : 'block-header';

  if (variant === 'h3') {
    return (
      <h3 {...rest} className={className}>
        {children}
      </h3>
    );
  }

  if (variant === 'h4') {
    return (
      <h4 {...rest} className={className}>
        {children}
      </h4>
    );
  }

  return (
    <h2 {...rest} className={className}>
      {children}
    </h2>
  );
};

export default BlockHeader;
