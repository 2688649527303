import { trackEvent } from '../utils/analytics';

export const experimentOptions = {
  digitalization: {
    A: {
      group: 'A',
      pageTitle:
        '<span>Business Digitalization</span> </br>Development Services',
      linkTitle: 'Business Digitalization',
      description:
        'Bring your business to a whole new level with Perpetio’s business digitalization development services.',
      footerTitle: 'Business Digitalization',
      metaTitle: 'Business Digitalization Development Services | Perpetio',
      highPerformance: 'For Business Digitalization',
      segments:
        "App development for <span style='color:#3095F2'>business digitalization</span>",
    },
    B: {
      group: 'B',
      pageTitle: '<span>Digital Transformation</span> </br>Services',
      linkTitle: 'Digital Transformation',
      description:
        'Bring your business to a whole new level with Perpetio’s digital transformation services.',
      footerTitle: 'Digital Transformation',
      metaTitle: 'Digital Transformation Services | Perpetio',
      highPerformance: 'For Digital Transformation',
      segments:
        "App development for <span style='color:#3095F2'>digital transformation</span>",
    },
  },
};

const getVariant = (experimentName) => {
  if (!experimentName || !experimentOptions[experimentName]) return null;

  if (typeof localStorage === 'undefined' || !localStorage) return null;

  let group = localStorage.getItem('ab_group') || '';

  if (!group || (group !== 'A' && group !== 'B')) {
    group = Math.random() < 0.5 ? 'A' : 'B';
    localStorage.setItem('ab_group', group);

    // eslint-disable-next-line no-console
    console.log('[Mixpanel] New AB user', group);

    trackEvent('New AB user', {
      experimentName,
      group,
    });
  }

  return experimentOptions[experimentName][group] || null;
};

const useABGetVariant = (experimentName, key) => {
  const variant = getVariant(experimentName);

  if (!variant || (key && !variant[key])) {
    const defaultVariant = experimentOptions[experimentName]?.A || {};
    return key ? defaultVariant[key] : defaultVariant;
  }

  if (key) {
    if (variant && variant[key]) return variant[key];
    return '';
  }

  return variant;
};

export default useABGetVariant;
