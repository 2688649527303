import React from 'react';
import './positions.scss';
import withRouter from '../../../utils/withRouter';
import { remoteStorageUrl } from '../../../config';
import FadeUp from '../../Animations/FadeUp';
import ResponsiveBox from '../../../components/UI/ResponsiveBox';
import BlockHeader from '../../UI/texts/BlockHeader';

const Positions = ({ text, history, block }) => {
  const goToPosition = (link) => history.navigate(`/careers/${link}`);
  return (
    <div
      className="positions"
      style={block ? { margin: 0, padding: '0 15px' } : null}
    >
      <ResponsiveBox>
        <FadeUp className={'title_block'}>
          <div className="positions-category">Vacancies</div>
          <BlockHeader className="positions-title">{text.title}</BlockHeader>
        </FadeUp>

        <div className={block ? 'block-row' : 'positions-row'}>
          {text.items.map(
            (position, index) =>
              position.enabled && (
                <FadeUp className="position-animate" key={index}>
                  <div
                    onClick={() => goToPosition(position.link)}
                    className="position-block"
                    key={index}
                  >
                    <h3 className="position-title">{position.title}</h3>
                    <p className="position-count">{position.count}</p>
                    <div className="position-block__description">
                      {position.description.map((item, index) => (
                        <div key={index} className="position-description">
                          <img
                            className=""
                            src={`${remoteStorageUrl}/resources/icons/${item.icon}`}
                            alt="icon"
                          />
                          <p className="">{item.text}</p>
                        </div>
                      ))}
                    </div>
                  </div>
                </FadeUp>
              ),
          )}
        </div>
      </ResponsiveBox>
    </div>
  );
};

export default withRouter(Positions);
