import React from 'react';
import { Container } from 'reactstrap';
import LazyLoad from 'react-lazyload';
import './commitFlow.scss';
import { remoteStorageUrl } from '../../../../config';
const bg = `${remoteStorageUrl}/resources/images/commit/commit-flow-bg.png`

export default ({text}) => (
  <Container className="commit-flow" fluid>
    <h2 className="commit-flow-title">{text.title}</h2>
    <LazyLoad once offset={1000}>
      <img src={bg} className="commit-flow-img" alt="commit"/>
    </LazyLoad>
  </Container>
);
