import React from 'react';
import ResponsiveBox from '../../UI/ResponsiveBox';
import FadeUp from '../../Animations/FadeUp';
import './benefit.scss';
import Medium24px from '../../UI/texts/24pxMedium';
import Medium16px from '../../UI/texts/16pxMedium';
import LazyLoad from 'react-lazyload';
import Domains from '../../Home/Domains';
import BlockHeader from '../../UI/texts/BlockHeader';

const Benefit = ({ data, cdnUrl, ...rest }) => {
  const { title, subtitle, items } = data;
  return (
    <ResponsiveBox {...rest}>
      <div className="benefit-wrapper">
        <FadeUp className="benefit-titleBox">
          <BlockHeader
            dangerouslySetInnerHTML={{
              __html: title,
            }}
          />
          {subtitle ? (
            <Medium24px
              style={{
                textAlign: 'center',
                color: 'var(--p-text-color)',
                paddingTop: '8px',
              }}
              dangerouslySetInnerHTML={{
                __html: subtitle,
              }}
            />
          ) : null}
        </FadeUp>
        <div className="benefit-cases-box">
          {items.map((item, index) => {
            return (
              <FadeUp className="benefit-case" key={index}>
                <LazyLoad once offset={100}>
                  <img alt={item.alt} src={`${cdnUrl}${item.logo}`} />
                </LazyLoad>
                <BlockHeader
                  variant="h3"
                  style={{ textAlign: 'center' }}
                  dangerouslySetInnerHTML={{ __html: item.title }}
                />
                <Medium16px
                  style={{ textAlign: 'center', color: 'var(--p-text-color)' }}
                  dangerouslySetInnerHTML={{ __html: item.subtitle }}
                />
              </FadeUp>
            );
          })}
        </div>
        <div className="benefit-domain-wrapper">
          <Domains text={data.domains} topGap={false} />
        </div>
      </div>
    </ResponsiveBox>
  );
};

export default Benefit;
